import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

// Types
import { IEventInterface } from '../../../models/events/event.interface'

// Styles
import './ShowsDetailPage.css'

// Constants
import { CONST } from '../../../const/const'
import { MODAL_CONSTS } from '../../../const/modal-const'

// Components
import WrapperContainer from '../../../components/common/wrappers/WrapperContainer'
import ShowsDetailPageTabControlSection from './ShowsDetailPageTabControlSection'
import ShowsDetailPageTabDisplaySection from './ShowsDetailPageTabDisplaySection'

// Redux
import { useDispatch } from 'react-redux'
import { selectedEvent, setEditedEvent } from '../../../store/events/eventsSlice'
import { useAppSelector } from '../../../store/hooks'

// Third party
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import clsx from 'clsx'
import { doc } from 'firebase/firestore'
import { useDocument } from 'react-firebase-hooks/firestore'
import { Link, useParams } from 'react-router-dom'

// Services
import FirebaseApp from '../../../services/firebaseApp'

// Models
import { EventModel } from '../../../models/events/event.model'

// Custom hooks
import useGetEventData from '../../../hooks/users/common/useGetEventData'

// Helpers
import ViewsLoader from '../../../components/loader/ViewsLoader'
import { LOCALSTORAGE_CONST } from '../../../const/local-storage-const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { getConvertedData } from '../../../models/interface.helper'
import { capitalize } from '../../../helpers/helpers'
import { KeyboardBackspace } from '@mui/icons-material'

// Types
type Props = {
  handleClick?: any
  menuToggle?: any
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn?: any
}

// Constants
const TEST_ID = 'MkEq8lqzbpboUVGi4YSL999'
const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
const EVENT_UI_CONST = CONST.UI.EVENTS.CREATE_EDIT.TABS.EVENT_DETAILS

const ShowsDetailPage = (props: Props) => {
  // Hooks and vars
  let mounted = true

  const dispatch = useDispatch()
  const toastFunction = useToasterHelper()

  const eventInRedux = useAppSelector(selectedEvent).Event as IEventInterface

  const eventId = useParams<{ eventId: string }>()?.eventId ?? null

  const { getAllData } = useGetEventData()

  const [eventTab, setEventTab] = useState(EVENT_UI_CONST.VALUE)
  const [nextEventTab, setNextEventTab] = useState('')

  const [eventDoc, loading] = useDocument(
    doc(FirebaseApp.firestore, COLLECTIONS.EVENTS.NAME, !eventId ? TEST_ID : eventId)
  )

  const maxTagsToShow = 4
  const trimTags = eventInRedux?.tags && eventInRedux?.tags?.length > maxTagsToShow

  const eventTitleNodeRef = useRef<HTMLDivElement>(null)

  let elemWidth = `md:max-w-[calc(100vw-${(eventTitleNodeRef?.current?.clientWidth ?? 0) + 10 + 200}px)] md:max-w-[calc(100vw-120px)]`
  let eventTags: string[] = trimTags
    ? [...(eventInRedux?.tags ?? [])]?.splice(0, 3)
    : (eventInRedux?.tags ?? [])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  const event = useMemo(() => {
    if (eventDoc?.exists?.() && !loading) {
      return EventModel.fromFirestoreDoc(eventDoc).cloneDeep()
    } else {
      return null
    }
  }, [eventDoc, loading])

  useEffect(() => {
    getAllData(eventId, ['v01_events', 'v01_event_payments_settings']).then()
  }, [eventId, getAllData])

  useEffect(() => {
    const tabToOpen = localStorage.getItem(LOCALSTORAGE_CONST.EDIT_EVENT_TAB_TO_OPEN)

    if (!!tabToOpen && mounted) {
      setEventTab(tabToOpen)
      localStorage.removeItem(LOCALSTORAGE_CONST.EDIT_EVENT_TAB_TO_OPEN)
    } else if (event?.eventFormState && mounted) {
      setEventTab(event.eventFormState)
    }
  }, [event?.eventFormState])

  useEffect(() => {
    if (!loading && eventDoc) {
      dispatch(setEditedEvent(getConvertedData(EventModel.fromFirestoreDoc(eventDoc).toObject())))
    }
  }, [dispatch, eventDoc, loading])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  // Functions

  /** @info Handles tabs click */
  const handleOnEventTabControlPressed = useCallback((event_tab: any) => {
    const DISABLED_TABS: string[] = []
    const TABS = CONST.UI.EVENTS.CREATE_EDIT.TABS

    let keyOfTab: keyof typeof TABS | null = null

    Object.keys(TABS).forEach((currTabKey: any) => {
      keyOfTab = currTabKey
      if (keyOfTab && TABS?.[keyOfTab] && (TABS?.[keyOfTab] as any)?.DISABLED)
        DISABLED_TABS.push(TABS?.[keyOfTab].VALUE)
    })

    if (DISABLED_TABS.find((currentDisabledTab) => currentDisabledTab === event_tab))
      return toastFunction.info({
        message: MESSAGES_CONST.COMING_SOON,
      })

    if (mounted) setEventTab(event_tab)

    localStorage.setItem(LOCALSTORAGE_CONST.EDIT_EVENT_TAB_TO_OPEN, event_tab)
  }, [])

  /** @returns Returns header */
  const getCustomHeader = (): ReactJSXElement => {
    return (
      <div className="event_comp">
        {event ? (
          <div className="flex w-full md:flex-nowrap flex-wrap items-center">
            <div
              ref={eventTitleNodeRef}
              className="md:mr-4 xl:text-[22px] 2xl:text-2xl min-w-fit pt-0 md:pt-2 lg:pt-2 xl:pt-1 flex"
            >
              <Link to={`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${event.id}`}>
                <KeyboardBackspace className="text-SeabiscuitDark200ThemeColor mr-2" />
              </Link>
              <h1
                title={event?.eventName}
                className="font-extrabold text-[28px] text-SeabiscuitDark200ThemeColor px-0 2xl:text-[30px] ellipsis "
              >
                {capitalize(event?.eventName, { capitalizeAll: false })}
              </h1>
            </div>

            {event?.category ? (
              <div className={clsx('overflow-x-auto flex items-start pt-1 pb-2', elemWidth)}>
                <div className="border border-SeabiscuitDark200ThemeColor capitalize rounded-full py-0.5 px-4 mr-4 inline-block mt-1 text-nr md:text-base">
                  {event?.category}
                </div>

                {(eventTags.includes('All') ? ['All'] : eventTags).map(
                  (currentItem: string, index: number) => {
                    return (
                      <div
                        key={`${currentItem}${index}`}
                        onClick={() =>
                          props.handleModal(true, MODAL_CONSTS.UPDATE_DISCIPLINE, eventInRedux)
                        }
                        className="border border-SeabiscuitDark200ThemeColor capitalize rounded-full py-0.5 px-4 mr-4 inline-block whitespace-nowrap max-w-[150px] overflow-hidden text-ellipsis mt-1 min-w-fit md:min-w-[unset] text-nr md:text-base cursor-pointer"
                      >
                        {currentItem}
                      </div>
                    )
                  }
                )}

                <div className="border border-SeabiscuitDark200ThemeColor capitalize rounded-full py-0.5 px-4 mr-4 inline-block mt-1 text-nr md:text-base">
                  {event?.status}
                </div>
              </div>
            ) : null}

            {event && eventTags?.length < event?.tags?.length ? (
              <div className="h-fit hidden md:block text-[30px] leading-[1]">...</div>
            ) : null}
          </div>
        ) : (
          <ViewsLoader className="h-12 mt-1" size="md" color="#F70763" />
        )}
      </div>
    )
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <WrapperContainer
      noBg={true}
      removeShadow={true}
      customHeader={getCustomHeader()}
      removePadding={true}
    >
      <>
        <ShowsDetailPageTabControlSection
          event={event}
          eventTab={eventTab}
          onSetEventTab={setEventTab}
        />
        <ShowsDetailPageTabDisplaySection
          mainEvent={event}
          loading={loading}
          eventTab={eventTab}
          nextEventTab={nextEventTab}
          handleModal={props.handleModal}
          dataToPassOn={props.dataToPassOn}
          onSetEventTab={handleOnEventTabControlPressed}
          onSetNextEventTab={setNextEventTab}
        />
      </>
    </WrapperContainer>
  )
}

export default ShowsDetailPage
