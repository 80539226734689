import React from 'react'
import { IconTeamMember } from '../../../../icons/IconTeamMember'
import { IconTeamMemberSmall } from '../../../../icons/IconTeamMemberSmall'
import { IconAddedPeople } from '../../../../icons/IconAddedPeople'
import clsx from 'clsx'
import { AddCircleOutline } from '@mui/icons-material'
import { IProfileItem, ProfileItem } from './ProfileItem'
import ViewsLoader from '../../../../loader/ViewsLoader'
import { IconAddHorse } from '../../../../icons/IconAddHorse'
import { IconHorseImg } from '../../../../icons/IconHorseImg'

interface ProfileCardProps {
  title: string
  subTitle: string
  desc: string
  loading: boolean
  teamMembers: IProfileItem[]
  isConnections?: boolean
  addTeamMemberHandler?: () => void
  isHorseCard?: boolean
  removeHandler: ({
    memberId,
    memberRole,
    memberHorseId,
  }: {
    memberId: string
    memberRole: string
    memberHorseId: string | null
  }) => void
}

export const ProfileCard: React.FC<ProfileCardProps> = ({
  title,
  desc,
  isConnections,
  addTeamMemberHandler,
  teamMembers,
  removeHandler,
  loading,
  subTitle,
  isHorseCard,
}) => {
  const teamMembersCount = teamMembers.length - (isConnections || isHorseCard ? 0 : 1)

  return (
    <div
      className={
        'flex-1 flex flex-col gap-5 p-5 border rounded-[8px] border-SeabiscuitGray500ThemeColor relative'
      }
    >
      <div
        className={
          'flex flex-col-reverse sm:flex-row gap-5 pb-5 border-b border-[#E6EAF2] w-full justify-start sm:justify-between items-start sm:items-center'
        }
      >
        <div className={''}>
          <h3
            className={
              'text-SeabiscuitDark200ThemeColor font-bold text-[14px] sm:text-[16px] whitespace-nowrap'
            }
          >
            {title}
          </h3>
          <p className={'text-SeabiscuitDark200ThemeColor/50 text-[12px] sm:text-[14px]'}>{desc}</p>
        </div>
        <div
          className={
            'flex flex-row-reverse sm:flex-row gap-2.5 items-center justify-start sm:justify-end'
          }
        >
          <div className={'text-SeabiscuitDark200ThemeColor/50 sm:text-right'}>
            <h5 className={'text-[12px] whitespace-nowrap'}>{subTitle}</h5>
            <p className={'text-[14px]'}>
              {teamMembersCount} {isHorseCard ? 'horses' : 'people'}
            </p>
          </div>
          <div
            className={
              'bg-SeabiscuitGrayThemeColor w-[45px] h-[45px] rounded-[8px] flex items-center justify-center'
            }
          >
            {isHorseCard ? (
              <IconHorseImg />
            ) : isConnections ? (
              <IconAddedPeople />
            ) : (
              <IconTeamMemberSmall />
            )}
          </div>
        </div>
      </div>
      {teamMembersCount ? (
        <div className={'flex flex-col gap-2.5 justify-center'}>
          {teamMembers.map((member, index) => {
            if (!isConnections && !member.role && !isHorseCard)
              return <React.Fragment key={member.userDesc || '' + index} />

            return (
              <ProfileItem
                isHorse={isHorseCard}
                isConnections={!!isConnections}
                key={index + member.id}
                member={member}
                hideRemoveBtn={isConnections && isHorseCard}
                removeItem={() =>
                  removeHandler({
                    memberId: member.id || '',
                    memberRole: member.role || '',
                    memberHorseId: member.horseId || null,
                  })
                }
              />
            )
          })}
          {!isConnections && (
            <button
              onClick={addTeamMemberHandler}
              className={
                'text-SeabiscuitLightParagraphTextColor/50 underline text-[14px] transition-all hover:no-underline'
              }
            >
              {isHorseCard ? 'Add horse' : 'Add team member'}
            </button>
          )}
        </div>
      ) : (
        <div className={'flex-1 flex justify-center items-center'}>
          <div className={'flex flex-col gap-4 items-center text-center justify-center'}>
            {isHorseCard ? <IconAddHorse /> : <IconTeamMember />}
            {isConnections ? (
              <p className={'text-SeabiscuitLightParagraphTextColor'}>
                {' '}
                {isHorseCard ? 'No horses yet' : 'No team members yet'}
              </p>
            ) : (
              <button
                onClick={addTeamMemberHandler}
                className={
                  'flex items-center gap-2 transition-all text-SeabiscuitMainThemeColor hover:text-SeabiscuitMainThemeColorDark'
                }
              >
                <AddCircleOutline className={clsx('add_icon')} />
                <span>{isHorseCard ? 'Add your first horse' : 'Add your first team member'}</span>
              </button>
            )}
          </div>
        </div>
      )}

      {loading && (
        <div
          className={
            'absolute top-0 left-0 w-full h-full bg-white rounded-[8px] flex items-center justify-center'
          }
        >
          <ViewsLoader />
        </div>
      )}
    </div>
  )
}
