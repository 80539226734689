import { TCustomSchedule } from '../models/event-scheduling/event-scheduling.interface'
import { RegistrationFeesType } from '../models/event-fees/event-fees.interface'

export const convertTime = (classStart: string, increment: string, step: number) => {
  const [classBaseHours, classBaseMinutes, classBasePeriod] = classStart.split(/[:\s]/)

  const [incrementHours, incrementMinutes, incrementSeconds] = increment.split(':').map(Number)

  const startTotalSeconds = Number(classBaseHours) * 3600 + Number(classBaseMinutes) * 60

  const totalIncrementSeconds =
    Number(incrementHours) * 3600 + Number(incrementMinutes) * 60 + incrementSeconds

  // Add the increment seconds
  const totalSeconds = startTotalSeconds + totalIncrementSeconds * step

  let hours = Math.floor(totalSeconds / 3600)
  let minutes = Math.floor((totalSeconds % 3600) / 60)
  let seconds = totalSeconds % 60
  // let period
  // Determine AM/PM
  if (classBasePeriod === 'AM') {
    // period = hours > 12 ? 'PM' : 'AM'
    hours = hours % 12
  } else {
    // period = hours > 12 ? 'AM' : 'PM'
    hours = hours % 12

    hours = hours ? hours : 12 // If hour is 0, set it to 12
  }

  // Format the result
  const formattedHours = hours < 10 ? '0' + hours : hours
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes
  const formattedSeconds = seconds < 10 ? '0' + seconds : seconds
  return `${formattedHours === '00' ? '12' : formattedHours}:${formattedMinutes}:${formattedSeconds} ${classBasePeriod}`
}

export const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export function convertToStandardTime(militaryTime: string) {
  let hours = parseInt(militaryTime.substring(0, 2))
  let minutes = militaryTime.substring(2)
  let suffix = hours >= 12 ? 'PM' : 'AM'

  hours = hours > 12 ? hours - 12 : hours
  hours = hours === 0 ? 12 : hours

  return `${hours}${minutes} ${suffix}`
}

export const sortScheduling = (scheduling: (TCustomSchedule | RegistrationFeesType)[]) => {
  return scheduling.sort((a, b): number => {
    // First, sort by startDate
    const dateA = new Date(a.startDate).getTime()
    const dateB = new Date(b.startDate).getTime()
    if (dateA !== dateB) return dateA - dateB

    // Sort by startTimeFormat (AM/PM)
    const formatA = a.startTimeFormat === 'AM' ? 0 : 1
    const formatB = b.startTimeFormat === 'AM' ? 0 : 1
    if (formatA !== formatB) return formatA - formatB

    // Then sort by startTimeHours
    const hoursA = parseInt(a.startTimeHours, 10)
    const hoursB = parseInt(b.startTimeHours, 10)
    if (hoursA !== hoursB) return hoursA - hoursB

    // Finally, sort by startTimeMinutes
    const minutesA = parseInt(a.startTimeMinutes, 10)
    const minutesB = parseInt(b.startTimeMinutes, 10)
    return minutesA - minutesB
  })
}

export function daysBetweenDates(date1: string | Date, date2: string | Date): number {
  // Convert date strings to Date objects if needed
  const firstDate = new Date(date1)
  const secondDate = new Date(date2)

  // Calculate the difference in milliseconds
  const differenceInMs = Math.abs(secondDate.getTime() - firstDate.getTime())

  // Convert milliseconds to days
  const millisecondsPerDay = 24 * 60 * 60 * 1000

  return Math.ceil(differenceInMs / millisecondsPerDay)
}

export const getFullDate = (
  date: string,
  time?: { format: string; hour: string; minutes: string }
) => {
  const fullDate = new Date(date)

  if (time) {
    let hours = parseInt(time.hour, 10)
    const minutes = parseInt(time.minutes, 10)

    if (time.format === 'PM' && hours !== 12) {
      hours += 12
    } else if (time.format === 'AM' && hours === 12) {
      hours = 0
    }

    fullDate.setHours(hours, minutes, 0, 0)
  }

  return fullDate
}
