import { useContext, useEffect, useState } from 'react'

// Data
import { myEventsDTTabs } from '../data/myEventsDTTabs'

// Component imports
import MyEventsDataTables from '../component/MyEventsDataTables'
import WrapperContainer from '../../../components/common/wrappers/WrapperContainer'
import { PillButtonElement } from '../../../components/elements/buttons/pill/PillButtonElement'

// Types
import {
  getAssignedTicketsCols,
  getBookmarkedDtCols,
  getEventCols,
  getTicketsCols,
  getDraftCols,
} from '../data/eventCols'
import { useAppSelector } from '../../../store/hooks'
import { selectUserId } from '../../../store/user/userSlice'
import { IGetMyEventsFilteredDataItem } from '../../../models/event-registered-users/event-registered-users.interface'
import FirestoreService from '../../../services/firestoreService'
import { CONST } from '../../../const/const'
import { HandleModalContext } from '../../../layout/mainlayout/MainLayout'
import { MODAL_CONSTS } from '../../../const/modal-const'

type Props = { handleModal: (show: boolean, modal_name: string, data?: any) => void }
type ISelectedTabUS = { tabTitle: string; tab: number; subTitle: string }
type ISelectTab = (tabDetails: ISelectedTabUS) => void
type IEventCol = any[]

// Constants

const SELECTED_TAB_INI_VALUES = {
  ...myEventsDTTabs[0],
}
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const MyEvent = (props: Props) => {
  const handleModalContext = useContext(HandleModalContext)

  const [selectedTab, setSelectedTab] = useState<ISelectedTabUS>({ ...SELECTED_TAB_INI_VALUES })
  const [cols, setCols] = useState<IEventCol>([])

  const userId = useAppSelector(selectUserId)

  /** Sets the active tab details */
  const selectTab: ISelectTab = (tabDetails) => {
    setSelectedTab(tabDetails)
  }

  const removeEventHandler = (row: IGetMyEventsFilteredDataItem) => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.CONFIRM_REMOVE_DRAFT_EVENT, {
      cb: async () => {
        try {
          await FirestoreService.deleteItem(
            COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
            `${row.registration?.id}`
          )
        } catch (e) {
          console.log('=>(MyEvent.tsx:69) e', e)
        }
      },
    })
  }

  useEffect(() => {
    const isTicketsTab = selectedTab.tab === 5
    const isAssignedTicketsTab = selectedTab.tab === 6
    const isBookmarkedTab = selectedTab.tab === 4
    const isDraftTab = selectedTab.tab === 3

    if (isTicketsTab) setCols(getTicketsCols({ handleModal: props.handleModal, selectedTab }))
    else if (isAssignedTicketsTab)
      setCols(getAssignedTicketsCols({ handleModal: props.handleModal, selectedTab }))
    else if (isBookmarkedTab) setCols(getBookmarkedDtCols())
    else if (isDraftTab)
      setCols(
        getDraftCols({
          handleModal: props.handleModal,
          selectedTab,
          userId: userId ?? '',
          removeHandler: removeEventHandler,
        })
      )
    else
      setCols(
        getEventCols({
          handleModal: props.handleModal,
          selectedTab,
          userId: userId ?? '',
        })
      )
  }, [selectedTab, props.handleModal, userId])

  return (
    <>
      <WrapperContainer title="My events">
        <>
          <div className="mb-[30px] flex overflow-x-auto gap-x-2 tabScroll w-full">
            {myEventsDTTabs.map((item, itemIndex) => {
              return (
                <PillButtonElement
                  key={`${JSON.stringify(item)}${itemIndex}`}
                  value={item.tab}
                  text={item.tabTitle}
                  Class={`h-[45px] border-none inline-block text-SeabiscuitDark200ThemeColor ${item?.tab === 5 ? 'w-[130px]' : item?.tab === 6 ? 'w-[145px] ' : 'whitespace-nowrap w-[120]'}`}
                  onButtonClicked={() => selectTab(item)}
                  selected={selectedTab.tab === item.tab}
                />
              )
            })}
          </div>

          <MyEventsDataTables cols={cols} selectedTab={selectedTab} />
        </>
      </WrapperContainer>
    </>
  )
}

export default MyEvent
