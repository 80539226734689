import FirestoreService from '../services/firestoreService'

import { IUserInterface } from '../models/users/user.interface'
import { getConvertedData } from '../models/interface.helper'

import { UserModel } from '../models/users/user.model'

import { CONST } from '../const/const'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

export const getAllUsers = async () => {
  try {
    const allUsers: IUserInterface[] = []

    const usersSnaps = await FirestoreService.filterItems(COLLECTIONS.USERS.NAME)

    usersSnaps.forEach((currSnap) => {
      allUsers.push(getConvertedData(UserModel.fromFirestoreDoc(currSnap).toObject()))
    })

    return allUsers
  } catch (error) {
    console.error(error, 'error')
    return []
  }
}
