export const DATA_FIRESTORE_V01_EVENT_DETAILS = {
  NAME: 'v01_event_details',
  FIELDS: {
    VERSION: {
      KEY: 'v',
      DEFAULT: 1,
    },
    ID: {
      KEY: 'id',
      DEFAULT: null,
    },
  },
}
