import React from 'react'
import customImageComponent from '../../../../common/CustomImageComponent'
import { IconDelete2 } from '../../../../icons/IconDelete2'
import { useHistory } from 'react-router-dom'
import { where } from 'firebase/firestore'

import { CONST } from '../../../../../const/const'
import { useAppSelector } from '../../../../../store/hooks'
import { selectUserId } from '../../../../../store/user/userSlice'
import FirestoreService from '../../../../../services/firestoreService'
import { UserHorseMappingModel } from '../../../../../models/user-horse-mapping/userHorseMapping.model'
import { IUserHorseMappingInterface } from '../../../../../models/user-horse-mapping/userHorseMapping.interface'

export interface IProfileItem {
  profilePicture: string
  name: string
  connectedDate?: Date | null | undefined
  userDesc: string
  role?: string
  id: string
  horseId?: string | null
  ownerId?: string | null
}

interface ProfileItemProps {
  member: IProfileItem
  removeItem: () => void
  isConnections: boolean
  hideRemoveBtn?: boolean
  isHorse?: boolean
}

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

export const ProfileItem: React.FC<ProfileItemProps> = ({
  member,
  removeItem,
  hideRemoveBtn,
  isHorse,
  isConnections,
}) => {
  const userId = useAppSelector(selectUserId)
  const history = useHistory()

  const memberHandler = () => history.push(`${CONST.ROUTES.USER_PROFILE.URL}/${member.id}`)

  const horseHandler = async () => {
    const ownerMapping: IUserHorseMappingInterface[] = []

    const mappingSnaps = await FirestoreService.filterItems(COLLECTIONS.USER_HORSE_MAPPING.NAME, [
      where(COLLECTIONS.USER_HORSE_MAPPING.FIELDS.USER_ID.KEY, '==', member.ownerId),
      where(COLLECTIONS.USER_HORSE_MAPPING.FIELDS.HORSE_OWNER_ID.KEY, '==', member.ownerId),
      where(COLLECTIONS.USER_HORSE_MAPPING.FIELDS.HORSE_ID.KEY, '==', member.horseId),
    ])
    mappingSnaps.forEach((currDoc) => {
      const horseMapping = UserHorseMappingModel.fromFirestoreDoc(currDoc).toObject()
      ownerMapping.push(horseMapping)
    })

    if (isConnections || (member.ownerId && member.ownerId !== userId)) {
      return history.push(
        `${CONST.ROUTES.HORSES.SUBMENU.SUB_URL3}/${ownerMapping[0]?.id}/${member.horseId}`
      )
    }
    return history.push(
      `${CONST.ROUTES.HORSES.SUBMENU.SUB_URL}/${ownerMapping[0]?.id}/${member.horseId}`
    )
  }

  return (
    <div
      className={
        'text-center sm:text-left relative flex flex-col sm:flex-row p-4 sm:p-1.5 gap-4 justify-between border border-SeabiscuitGray500ThemeColor rounded-[6px]'
      }
    >
      <div
        className={
          'flex flex-col sm:flex-row items-center gap-4 cursor-pointer transition-all hover:opacity-60'
        }
        onClick={isHorse ? horseHandler : memberHandler}
      >
        <div className={'w-[44px] h-[44px] rounded-[4px] overflow-hidden'}>
          {customImageComponent(member.profilePicture, member.name, 'w-full h-full object-cover')}
        </div>
        <div className={'flex flex-col'}>
          <p>{member.name}</p>
          {member.userDesc && (
            <p className={'text-[14px] text-SeabiscuitLightParagraphTextColor/50'}>
              {member.userDesc}
            </p>
          )}
        </div>
      </div>
      {hideRemoveBtn && isHorse && isConnections && (
        <button
          type={'button'}
          onClick={horseHandler}
          className={
            'text-[14px] text-SeabiscuitLightParagraphTextColor transition-all hover:text-SeabiscuitMainThemeColor active:opacity-10 mr-2'
          }
        >
          View
        </button>
      )}
      {!hideRemoveBtn && (
        <button
          type={'button'}
          onClick={removeItem}
          className={
            'absolute right-4 top-4 sm:right-2 sm:top-0 sm:relative transition-all hover:opacity-50 active:opacity-10'
          }
        >
          <IconDelete2 />
        </button>
      )}
    </div>
  )
}
