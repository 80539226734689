import { IRegisterTab } from '../models/event-registered-users/event-registered-users.interface'
import { IRegistrationByDayInterface } from '../models/registrations-by-day/registrationByDay.interface'

export const getRegistrations = (registerTabData: IRegisterTab[] | null) => {
  const parentRegistrations: IRegistrationByDayInterface[] = []
  const childrenRegistrations: IRegistrationByDayInterface[] = []

  registerTabData?.forEach((register) => {
    register.registrationsByDay.forEach((registrationByDay) => {
      parentRegistrations.push(registrationByDay)
    })
    register.children?.forEach((children) => {
      children.registrationsByDay.forEach((registrationByDay) => {
        childrenRegistrations.push(registrationByDay)
      })
    })
  })

  return {
    allRegistrations: [...parentRegistrations, ...childrenRegistrations],
  }
}
