import React, { useEffect, useState } from 'react'

// Third party
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material'
import { where } from 'firebase/firestore'

// Components
import ViewsLoader from '../../../components/loader/ViewsLoader'
import Accordion from '../../../components/accordion/common/Accordion'
import ExhibitorRidingDetailsAccordion from './accordions/ExhibitorRidingDetailsAccordion'
import ExhibitorCompetitionNumbersAccordion from './accordions/ExhibitorCompetitionNumbersAccordion'
import ExhibitorBiographicalInformation from './accordions/ExhibitorBiographicalInformation'

import { formatDate, getUserFullName } from '../../../helpers/helpers'
import helpers from '../../../commonHelpers/helpers'

import FirestoreService from '../../../services/firestoreService'

import { getConvertedData, getReactPickerDate } from '../../../models/interface.helper'
import { RiderTeamMemberModel } from '../../../models/rider-team-member/riderTeamMember.model'
import { IRiderTeamMemberInterface } from '../../../models/rider-team-member/riderTeamMember.interface'
import { TEventRegisteredUsers } from '../../../models/event-registered-users/event-registered-users.interface'

import { CONST } from '../../../const/const'
import { IUserInterface } from '../../../models/users/user.interface'
import { useAppSelector } from '../../../store/hooks'
import { selectAllUsers } from '../../../store/users/usersSlice'

// Types
type IExhibitorProfileTeamTabProps = {
  exhibitorAllData?: TEventRegisteredUsers | null
  isRecipient?: boolean
}

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

const ExhibitorProfileTeamTab = ({ exhibitorAllData }: IExhibitorProfileTeamTabProps) => {
  const [membersProfilesData, setMembersProfilesData] = useState<
    (IUserInterface & { role: string })[]
  >([])
  const [loading, setLoading] = useState(true)
  const users = useAppSelector(selectAllUsers)
  useEffect(() => {
    const fetchUserProfileData = async () => {
      let riderTeamMembers_: IRiderTeamMemberInterface[] = []
      let memberProfilesData_: (IUserInterface & { role: string })[] = []

      const riderTeamMembersSnaps = await FirestoreService.filterItems(
        COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
        [
          where(COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.EVENT_ID.KEY, '==', exhibitorAllData?.eventId),
          where(COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.USER_ID.KEY, '==', exhibitorAllData?.userId),
          where(
            COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.REGISTRATION_DOC_ID.KEY,
            '==',
            exhibitorAllData?.id
          ),
        ]
      )

      if (riderTeamMembersSnaps.size) {
        riderTeamMembersSnaps.forEach((currDoc) => {
          const riderTeamMember = getConvertedData(
            RiderTeamMemberModel.fromFirestoreDoc(currDoc).toObject()
          )
          const isExist = riderTeamMembers_.find(
            (member) =>
              member.teamMemberId === riderTeamMember.teamMemberId &&
              member.teamMemberRole === riderTeamMember.teamMemberRole
          )
          if (!isExist?.id) riderTeamMembers_.push(riderTeamMember)
        })
      }

      riderTeamMembers_.forEach((riderTeamMember) => {
        const currentUSer = users.find((user) => user.id === riderTeamMember.teamMemberId)
        if (currentUSer?.id)
          memberProfilesData_.push({ ...currentUSer, role: riderTeamMember?.teamMemberRole ?? '' })
      })

      setMembersProfilesData(memberProfilesData_)
    }

    fetchUserProfileData()
      .then(() => null)
      .finally(() => setLoading(false))
  }, [exhibitorAllData?.userId])

  const [isOpen, setIsOpen] = useState(false)

  if (loading) {
    return (
      <>
        <div className="h-[50vh] min-h-[200px] flex justify-center items-center">
          <ViewsLoader size="lg" color="#F70763" />
        </div>
      </>
    )
  }
  if (!membersProfilesData[0]) {
    return <></>
  }

  return (
    <>
      {membersProfilesData.map((memberProfilesData, i) => {
        const data = [
          {
            title: 'Name',
            content: memberProfilesData ? getUserFullName(memberProfilesData) : 'N/A',
          },
          {
            title: 'Nationality',
            content: memberProfilesData?.userNationality || '',
          },
          {
            title: 'Date of Birth',
            content: memberProfilesData
              ? formatDate(getReactPickerDate(memberProfilesData?.userDOB))
              : '',
          },
          {
            title: 'Phone',
            content: memberProfilesData?.userPhoneNumber || '',
          },
          {
            title: 'Email',
            content: memberProfilesData?.userEmail || '',
          },
        ]

        const dataRider = [
          {
            title: 'Discipline',
            content: memberProfilesData?.userDiscipline || '',
          },
          {
            title: 'Zone',
            content: memberProfilesData?.userZone || '',
          },
          {
            title: 'Amateur Pro Status',
            content: memberProfilesData?.userAmateur || '',
          },
          {
            title: 'Judging License',
            content: memberProfilesData?.userJudgingLicense
              ? (memberProfilesData.userJudgingLicense as string)
              : 'No License',
          },
          {
            title: 'Foreign Endorsement Letter',
            content: 'View Document',
          },
          {
            title: 'Background Check',
            content: memberProfilesData.userBackgroundCheckExpiry
              ? formatDate(memberProfilesData.userBackgroundCheckExpiry)
              : '',
          },
          {
            title: 'SafeSport Status',
            content: memberProfilesData.userBackgroundCheckExpiry
              ? formatDate(memberProfilesData.userSafeSupportExpiry)
              : '',
          },
        ]

        const dataNumbers = [
          {
            title: 'USEF number',
            content: memberProfilesData?.userUSEF?.documentNumber || 'No number on file',
            endDate: memberProfilesData?.userUSEF?.endDate
              ? formatDate(memberProfilesData?.userUSEF.endDate)
              : '',
            membershipStatus: memberProfilesData?.userUSEF?.membershipStatus || '',
            image: memberProfilesData?.userUSEF?.image || '',
          },
          {
            title: 'FEI number',
            content: memberProfilesData?.userFEI?.documentNumber || 'No number on file',
            endDate: memberProfilesData?.userFEI?.endDate
              ? formatDate(memberProfilesData?.userFEI.endDate)
              : '',
            membershipStatus: memberProfilesData?.userFEI?.membershipStatus || '',
            image: memberProfilesData?.userFEI?.image || '',
          },
          {
            title: 'USDF number',
            content: memberProfilesData?.userUSDF?.documentNumber || 'No number on file',
            endDate: memberProfilesData?.userUSDF?.endDate
              ? formatDate(memberProfilesData?.userUSDF.endDate)
              : '',
            membershipStatus: memberProfilesData?.userUSDF?.membershipStatus || '',
            image: memberProfilesData?.userUSDF?.image || '',
          },
          {
            title: 'USEA number',
            content: memberProfilesData?.userUSEA?.documentNumber || 'No number on file',
            endDate: memberProfilesData?.userUSEA?.endDate
              ? formatDate(memberProfilesData?.userUSEA.endDate)
              : '',
            membershipStatus: memberProfilesData?.userUSEA?.membershipStatus || '',
            image: memberProfilesData?.userUSEA?.image || '',
          },
          {
            title: 'USHJA number',
            content: memberProfilesData?.userUSHJA?.documentNumber || 'No number on file',
            endDate: memberProfilesData?.userUSHJA?.endDate
              ? formatDate(memberProfilesData?.userUSHJA.endDate)
              : '',
            membershipStatus: memberProfilesData?.userUSHJA?.membershipStatus || '',
            image: memberProfilesData?.userUSHJA?.image || '',
          },
          {
            title: 'AHHS number',
            content: memberProfilesData?.userAHHS?.documentNumber || 'No number on file',
            endDate: memberProfilesData?.userAHHS?.endDate
              ? formatDate(memberProfilesData?.userAHHS.endDate)
              : '',
            membershipStatus: memberProfilesData?.userAHHS?.membershipStatus || '',
            image: memberProfilesData?.userAHHS?.image || '',
          },
          {
            title: 'AMHA number',
            content: memberProfilesData?.userAMHA?.documentNumber || 'No number on file',
            endDate: memberProfilesData?.userAMHA?.endDate
              ? formatDate(memberProfilesData?.userAMHA?.endDate)
              : '',
            membershipStatus: memberProfilesData?.userAMHA?.membershipStatus || '',
            image: memberProfilesData?.userAMHA?.image || '',
          },
          {
            title: 'ARHPA number',
            content: memberProfilesData?.userARHPA?.documentNumber || 'No number on file',
            endDate: memberProfilesData?.userARHPA?.endDate
              ? formatDate(memberProfilesData?.userARHPA?.endDate)
              : '',
            membershipStatus: memberProfilesData?.userARHPA?.membershipStatus || '',
            image: memberProfilesData?.userARHPA?.image || '',
          },
          {
            title: 'ASHA number',
            content: memberProfilesData?.userASHA?.documentNumber || 'No number on file',
            endDate: memberProfilesData?.userASHA?.endDate
              ? formatDate(memberProfilesData?.userASHA.endDate)
              : '',
            membershipStatus: memberProfilesData?.userASHA?.membershipStatus || '',
            image: memberProfilesData?.userASHA?.image || '',
          },
          {
            title: 'UPHA number',
            content: memberProfilesData?.userUPHA?.documentNumber || 'No number on file',
            endDate: memberProfilesData?.userUPHA?.endDate
              ? formatDate(memberProfilesData?.userUPHA.endDate)
              : '',
            membershipStatus: memberProfilesData?.userUPHA?.membershipStatus || '',
            image: memberProfilesData?.userUPHA?.image || '',
          },
          {
            title: 'WDAA number',
            content: memberProfilesData?.userWDAA?.documentNumber || 'No number on file',
            endDate: memberProfilesData?.userWDAA?.endDate
              ? formatDate(memberProfilesData?.userWDAA.endDate)
              : '',
            membershipStatus: memberProfilesData?.userWDAA?.membershipStatus || '',
            image: memberProfilesData?.userWDAA?.image || '',
          },
        ]

        if (
          memberProfilesData?.userExtraDocument &&
          memberProfilesData.userExtraDocument?.length > 0
        ) {
          const extraNumbers = memberProfilesData.userExtraDocument?.map((extraNumber) => ({
            title: extraNumber.documentFullName,
            content: extraNumber?.documentNumber || 'No number on file',
            endDate: extraNumber?.endDate ? formatDate(extraNumber.endDate) : '',
            membershipStatus: extraNumber?.membershipStatus || '',
            image: extraNumber?.image || '',
          }))

          if (extraNumbers && extraNumbers.length > 0) dataNumbers.push(...extraNumbers)
        }

        return (
          <React.Fragment key={i}>
            <Accordion
              icon={<AddCircleOutline className="add_icon text-[red]" />}
              initialState={false}
              className="mb-2"
              header={
                <div className={'flex gap-2'}>
                  <div>
                    <img
                      alt="icons"
                      onError={(e) =>
                        ((e.target as any).src =
                          `https://ui-avatars.com/api/?name=${helpers.generateName(memberProfilesData ? getUserFullName(memberProfilesData) : 'N/A')}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
                      }
                      src={memberProfilesData.userProfilePicture || ''}
                      className="w-10 h-10 mr-2 border border-solid object-cover rounded-md"
                    />
                  </div>
                  <div>
                    <p className={'text-[#122B46] font-normal text-[14px]'}>
                      {getUserFullName(memberProfilesData)}
                    </p>
                    {memberProfilesData?.role && (
                      <p className={'text-[#77809c] font-normal text-[12px]'}>
                        Role • {memberProfilesData.role}
                      </p>
                    )}
                  </div>
                </div>
              }
              headerTextClassName="text-SeabiscuitDark200ThemeColor capitalize w-full pr-2"
              onOpen={() => {
                setIsOpen(true)
              }}
              onClose={() => {
                setIsOpen(false)
              }}
              activeIcon={<RemoveCircleOutline className="add_icon text-[red]" />}
            >
              <>
                <div className={'pt-4 mt-4'}>
                  <div
                    className={`text-SeabiscuitDark200ThemeColor mb-2 flex justify-between items-center ${isOpen ? 'font-normal' : 'font-bold'}`}
                  >
                    Biography
                    <div className="flex items-center details"></div>
                  </div>

                  <ExhibitorBiographicalInformation data={data} />
                </div>
                <div className={'pt-4 mt-4 border-t'}>
                  <div
                    className={`text-SeabiscuitDark200ThemeColor mb-2 flex justify-between items-center ${isOpen ? 'font-normal' : 'font-bold'}`}
                  >
                    Riding Details
                    <div className="flex items-center details"></div>
                  </div>

                  <ExhibitorRidingDetailsAccordion data={dataRider} />
                </div>
                <div className={'pt-4 mt-4 border-t'}>
                  <div
                    className={`text-SeabiscuitDark200ThemeColor mb-2 flex justify-between items-center ${isOpen ? 'font-normal' : 'font-bold'}`}
                  >
                    Competition Numbers
                    <div className="flex items-center details"></div>
                  </div>
                  <ExhibitorCompetitionNumbersAccordion dataNumbers={dataNumbers} />
                </div>
              </>
            </Accordion>
          </React.Fragment>
        )
      })}
    </>
  )
}

export default ExhibitorProfileTeamTab
