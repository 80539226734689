import React from 'react'
import clsx from 'clsx'

interface IProps {
  classes?: string
}

export const IconDuplicate: React.FC<IProps> = ({ classes }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={clsx('fill-white', classes)}
    >
      <path d="M2.25.5C1.01.5 0 1.51 0 2.75v14.5c0 1.24 1.01 2.25 2.25 2.25h2.16a3.24 3.24 0 0 1-.82-1.5H2.25a.75.75 0 0 1-.75-.75V2.75A.75.75 0 0 1 2.25 2h1.34A3.25 3.25 0 0 1 4.41.5zm4.5 0A2.26 2.26 0 0 0 4.5 2.75v14.5a2.26 2.26 0 0 0 2.25 2.25h11A2.26 2.26 0 0 0 20 17.25v-10a.75.75 0 0 0-.22-.53l-6-6a.75.75 0 0 0-.53-.22zm0 1.5h5.75v3.75A2.26 2.26 0 0 0 14.75 8h3.75v9.25c0 .423-.327.75-.75.75h-11a.74.74 0 0 1-.75-.75V2.75c0-.423.327-.75.75-.75M14 3.06l3.44 3.44h-2.69a.74.74 0 0 1-.75-.75zM9.25 10.5a.751.751 0 1 0 0 1.5h6a.751.751 0 1 0 0-1.5zm0 3.5a.751.751 0 1 0 0 1.5h4.5a.751.751 0 1 0 0-1.5z"></path>
    </svg>
  )
}
