import { IEventInterface } from '../../../../models/events/event.interface'

export const DATA_FIRESTORE_V01_EVENTS = {
  NAME: 'v01_events',

  FIELDS: {
    VERSION: {
      KEY: 'v',
      DEFAULT: 1,
    },

    TYPE: {
      KEY: 'type',
      VALUE: {
        REGULAR: 'regular',
        COMPETITION: 'competition',
      },
    },

    EVENT_PRICE_USD: {
      KEY: 'eventPriceUSD',
      VALUE: {
        DEFAULT: 0,
      },
    },

    TICKET_ITEMS_COUNT: {
      KEY: 'ticketItemsCount',
      VALUE: {
        DEFAULT: 0,
      },
    },

    SOLD_TICKET_ITEMS_COUNT: {
      KEY: 'soldTicketItemsCount',
      VALUE: {
        DEFAULT: 0,
      },
    },

    EVENT_IMAGES: {
      KEY: 'eventImages',
      VALUE: {
        EMPTY: [],
      },
    },
    EVENT_BREAKS: {
      KEY: 'breaks',
    },
    SUBTYPE: {
      KEY: 'subType',
      VALUE: {
        SHOW_JUMPING: 'show_jumping',
        EVENTING: 'eventing',
        DRESSAGE: 'dressage',
        EQUITATION: 'equitation',
        HUNTER: 'hunter',
        JUMPER: 'jumper',
        HUNTER_JUMPER: 'hunter_jumper',
      },
    },

    STATUS: {
      KEY: 'status',
      VALUE: {
        PAST: 'past' as IEventInterface['status'],
        DRAFT: 'draft' as IEventInterface['status'],
        CANCEL: 'cancel' as IEventInterface['status'],
        CURRENT: 'current' as IEventInterface['status'],
      },
    },

    CATEGORIES: {
      KEY: 'category',
      VALUES: {
        ALL: 'all',
        LICENSED: 'licensed',
        UNLICENSED: 'unlicensed',
        CLINIC: 'clinic',
        OTHER: 'other',
        FOLLOWING: 'following',
      },
    },
    IS_PUBLISHED: {
      KEY: 'isPublished',
    },
    TAGS: {
      KEY: 'tags',
      VALUES: {
        EVENTING: 'eventing',
        DRESSAGE: 'dressage',
        EQUITATION: 'equitation',
        HUNTER: 'hunter',
        JUMPER: 'jumper',
        HUNTER_JUMPER: 'hunter_jumper',
      },
    },

    EVENT_CITY_STATE_COUNTRY_KEY: {
      KEY: 'eventCityStateCountryKey',
      VALUE: {
        UNKNOWN: 'unknown',
      },
    },

    EVENT_PRICE_USD_RANGE: {
      KEY: 'eventPriceUSDRange',
      VALUE: {
        UNKNOWN: 'unknown',
      },
    },

    EVENT_NAME_N_GRAM: {
      KEY: 'eventNameNGram',
    },

    EVENT_START_DATE_RANGE: {
      KEY: 'eventStartDateRange',
      VALUE: {
        UNKNOWN: 'unknown',
      },
    },

    EVENT_START_DATE_YYYY_MM_DD: {
      KEY: 'eventStartDateYYYYMMDD',
      VALUE: {
        UNKNOWN: 'unknown',
      },
    },

    EVENT_START_DATE: {
      KEY: 'eventStartDate',
      VALUE: {
        UNKNOWN: 'unknown',
      },
    },

    EVENT_END_DATE: {
      KEY: 'eventEndDate',
      VALUE: {
        UNKNOWN: 'unknown',
      },
    },

    REQUIRED_FIELDS: {
      KEY: 'requiredFields',
      VALUE: {
        UNKNOWN: 'unknown',
      },
    },

    REQUIRED_HORSE_FIELDS: {
      KEY: 'requiredHorseFields',
      VALUE: {
        UNKNOWN: 'unknown',
      },
    },

    IS_LICENSED: {
      KEY: 'isLicensed',
    },

    REGISTERED: {
      KEY: 'registeredNumber',
    },

    REGISTRATION_CLOSE_DATE: {
      KEY: 'registrationCloseDate',
    },

    CURRENT_REGISTRATION_REVENUE: {
      KEY: 'currentRegistrationRevenue',
      VALUES: {
        DEFAULT: 0,
      },
    },

    EXPECTED_REGISTRATION_REVENUE: {
      KEY: 'expectedRegistrationRevenue',
      VALUES: {
        DEFAULT: 0,
      },
    },

    CURRENT_TICKET_REVENUE: {
      KEY: 'currentTicketRevenue',
      VALUES: {
        DEFAULT: 0,
      },
    },

    EXPECTED_TICKET_REVENUE: {
      KEY: 'expectedTicketRevenue',
      VALUES: {
        DEFAULT: 0,
      },
    },

    CURRENT_FEES_REVENUE: {
      KEY: 'currentFeesRevenue',
      VALUES: {
        DEFAULT: 0,
      },
    },

    EXPECTED_FEES_REVENUE: {
      KEY: 'expectedFeesRevenue',
      VALUES: {
        DEFAULT: 0,
      },
    },

    TOTAL_STALLS_COUNT: {
      KEY: 'totalStallsCount',
      VALUES: {
        DEFAULT: 0,
      },
    },

    SOLD_STALLS_COUNT: {
      KEY: 'soldStallsCount',
      VALUES: {
        DEFAULT: 0,
      },
    },

    TOTAL_FEED_COUNT: {
      KEY: 'totalFeedCount',
      VALUES: {
        DEFAULT: 0,
      },
    },

    SOLD_FEED_COUNT: {
      KEY: 'soldFeedCount',
      VALUES: {
        DEFAULT: 0,
      },
    },

    TOTAL_BEDDING_COUNT: {
      KEY: 'totalBeddingCount',
      VALUES: {
        DEFAULT: 0,
      },
    },

    SOLD_BEDDING_COUNT: {
      KEY: 'soldBeddingCount',
      VALUES: {
        DEFAULT: 0,
      },
    },

    TOTAL_OTHER_COUNT: {
      KEY: 'totalOtherCount',
      VALUES: {
        DEFAULT: 0,
      },
    },

    SOLD_OTHER_COUNT: {
      KEY: 'soldOtherCount',
      VALUES: {
        DEFAULT: 0,
      },
    },

    REGISTRATION_OPEN_DATE: {
      KEY: 'registrationOpenDate',
    },

    SALES: {
      KEY: 'sales',
    },

    EVENT_NAME: {
      KEY: 'eventName',
    },

    BODY_COMP_ID: {
      KEY: 'bodyCompId',
      VALUE: {
        UNKNOWN: 'unknown',
      },
    },

    CITY: {
      KEY: 'city',
      VALUE: {
        UNKNOWN: 'unknown',
      },
    },

    STATE: {
      KEY: 'state',
      VALUE: {
        UNKNOWN: 'unknown',
      },
    },

    COUNTRY: {
      KEY: 'country',
      VALUE: {
        UNKNOWN: 'unknown',
      },
    },

    LOCATION_HASH: {
      KEY: 'locationHash',
    },

    OWNER: {
      KEY: 'owner',
    },

    CREATED: {
      KEY: 'created',
    },
  },
}
