import { useState } from 'react'
import MainModal from '../../modals/common/MainModal'

// Constants
import { MODAL_CONSTS } from '../../../const/modal-const'
import { useAppSelector } from '../../../store/hooks'
import { RootState } from '../../../store/store'

// Types
type Props = {
  show: boolean
  handleModal: any
}

const UserProfileHorseTabAddHorse = (props: Props) => {
  const userReducer = useAppSelector((state: RootState) => state.user)
  const { userId } = userReducer
  const [opentab, setOpenTab] = useState('')

  // Functions
  const handelOpenTab = () => {
    const removeHorse = opentab === 'remove'

    if (opentab === 'add') {
      props.handleModal(true, MODAL_CONSTS.ADD_NEW_HORSE_MEMBER, {
        onlyHorse: true,
        userId,
      })
      closeModal()
      return
    }

    closeModal()

    props.handleModal(true, MODAL_CONSTS.SEARCH_HORSE_BY_NAME, {
      removeHorse,
    })
  }

  const closeModal = () => {
    props.handleModal(false, MODAL_CONSTS.HORSE)
  }

  return (
    <MainModal
      title="Add horse"
      titleClassName="my-4"
      customTitle={
        <div>
          <h6 className="text-lg leading-[24px] text-SeabiscuitDark200ThemeColor font-normal mt-2">
            Select method to add horse
          </h6>
        </div>
      }
      size="md"
      type="HORSE"
      show={props.show}
      buttons={[
        {
          label: 'NEXT >',
          bgClass: 'bg-transparent',
          textClass: 'text-SeabiscuitMainThemeColor',
          onClick: () => handelOpenTab(),
          borderClass: 'border !border-SeabiscuitMainThemeColor',
          onHoverClass: 'hover:text-white hover:bg-SeabiscuitMainThemeColor',
        },
      ]}
    >
      <div className="w-full min-h-[300px] h-full">
        <div className="w-full grid grid-cols-2 gap-3 ">
          <div
            role="button"
            onClick={() => setOpenTab('search')}
            className={`w-full mx-auto  flex flex-col py-2 px-4 border rounded-xl shadow-sm text-sm font-medium focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor
                                        ${opentab === 'search' ? ' focus:border-SeabiscuitMainThemeColor border-SeabiscuitMainThemeColor bg-SeabiscuitMainThemeColor' : ' border-SeabiscuitGray500ThemeColor text-SeabiscuitLightTextColor'}
                                        `}
          >
            {opentab === 'search' ? (
              <img src="/assets/cp_icons/Search-4.svg" className="w-[30px]" alt="search" />
            ) : (
              <img src="/assets/cp_icons/Search.svg" className="w-[30px]" alt="search" />
            )}
            <span
              className={`text-sm font-normal leading-[25px] italic ${opentab === 'search' ? 'text-[#fff]' : ''}`}
            >
              Search horses
            </span>
            <span
              className={`text-base font-normal leading-[24px] ${opentab === 'search' ? 'text-[#fff]' : ''}`}
            >
              Search horses for Pegasus
            </span>
          </div>

          <div
            role="button"
            onClick={() => setOpenTab('add')}
            className={`w-full mx-auto  flex flex-col py-2 px-4 border  rounded-xl shadow-sm text-sm font-medium focus:ring-2 focus:ring-offset-2 focus:ring-[#D3DAEE]
                                        ${opentab === 'add' ? ' focus:border-SeabiscuitMainThemeColor border-SeabiscuitMainThemeColor bg-SeabiscuitMainThemeColor' : ' border-SeabiscuitGray500ThemeColor text-SeabiscuitLightTextColor'}
                                        `}
          >
            {opentab === 'add' ? (
              <img
                src="/assets/cp_icons/Cancel-4.svg"
                className="w-[30px] rotate-45"
                alt="cancel"
              />
            ) : (
              <img src="/assets/cp_icons/Cancel.svg" className="w-[30px] rotate-45" alt="cancel" />
            )}

            <span
              className={`text-sm font-normal leading-[25px] italic ${opentab === 'add' ? 'text-[#fff]' : ''}`}
            >
              Add new horse
            </span>
            <span
              className={`text-base font-normal leading-[24px] ${opentab === 'add' ? 'text-[#fff]' : ''}`}
            >
              Create a new horse profile
            </span>
          </div>
        </div>
      </div>
    </MainModal>
  )
}

export default UserProfileHorseTabAddHorse
