import { useEffect, useState } from 'react'

// Third party
import { motion } from 'framer-motion'
import { IonItem } from '@ionic/react'
import InfiniteScroll from 'react-infinite-scroll-component'

// Components
import EventPostHorizontalCardComponent from '../../cards/post-horizontal-card/EventPostHorizontalCardComponent'
import CardsLoader from '../../../loader/CardsLoader'

// Types
import { IEventInterface } from '../../../../models/events/event.interface'

// Models
import { EventBookmarkModel } from '../../../../models/event-bookmark/event-bookmark.model'

// Redux
import { getConvertedData, getUtcDate } from '../../../../models/interface.helper'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { selectIsLoggedIn, selectUserId, selectUsername } from '../../../../store/user/userSlice'

// Custom hooks
import useToasterHelper from '../../../../helpers/ToasterHelper'

// Constants
import { CONST } from '../../../../const/const'
import { MESSAGES_CONST } from '../../../../const/messages-const'

// Services
import helpers from '../../../../commonHelpers/helpers'
import FirestoreService from '../../../../services/firestoreService'
import { resetSelectedEventAc } from '../../../../store/events/eventsSlice'
import { IEventBookmarkInterface } from '../../../../models/event-bookmark/event-bookmark.interface'
import { where } from 'firebase/firestore'

interface EventPostsHorizontalStackComponentProps {
  tags?: string[]
  hasMore: boolean
  competitions: IEventInterface[]
  fetchMore: () => void
}

const EVENT_BOOKMARKS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_BOOKMARKS
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const EventPostsHorizontalCardStackComponent: React.FC<EventPostsHorizontalStackComponentProps> = (
  props
) => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const toastMethods = useToasterHelper()
  const userId = useAppSelector(selectUserId)
  const userName = useAppSelector(selectUsername)
  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  const [bookmarkBeingProcessed, setBookmarkBeingProcessed] = useState<null | string>(null)
  const [bookmarkedEvents, setBookmarkedEvents] = useState<IEventBookmarkInterface[]>([])

  // Functions
  /** @info Handles bookmark */
  const bookmarkEvent = async (eventId: string, bookmarkId: null | string): Promise<any> => {
    if (!isLoggedIn) return toastMethods.info({ message: MESSAGES_CONST.LOGIN_TO_BOOKMARK })

    try {
      setBookmarkBeingProcessed(eventId)

      let bookmarkedEventIdsList = new Set([...bookmarkedEvents.map((event) => event.eventId)])
      const eventToBookmark = props.competitions.find(
        (currCompetition) => currCompetition.id === eventId
      )

      if (eventToBookmark) {
        if (userId) {
          if (!bookmarkId) {
            await addBookmark(eventId)
            bookmarkedEventIdsList.add(eventId)
          } else if (bookmarkId) {
            await removeBookmark(bookmarkId)
            bookmarkedEventIdsList.delete(eventId)
          }
        } else throw Error('userId is not set')
      }
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
      toastMethods.success({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    } finally {
      setBookmarkBeingProcessed(null)
    }
  }

  /** @info Bookmarks a event */
  const addBookmark = async (eventId: string) => {
    try {
      let updatedBookmarksList = [...bookmarkedEvents]
      let structuredBookmark = new EventBookmarkModel({
        eventId,
        userId,
        createdAt: getUtcDate(),
        updatedAt: getUtcDate(),
        bookmarkedBy: userName ?? null,
        mailSent: 0,
      })

      const created = await FirestoreService.createItem(
        EVENT_BOOKMARKS.NAME,
        structuredBookmark.toFirestore()
      )

      structuredBookmark.id = created.id

      updatedBookmarksList = [
        ...updatedBookmarksList,
        getConvertedData(structuredBookmark.toObject()),
      ]

      setBookmarkedEvents(updatedBookmarksList)
      toastMethods.success({ message: MESSAGES_CONST.BOOKMARKED_SUCCESSFULLY })
    } catch (error) {
      throw error
    }
  }

  /** @info Removes a bookmark */
  const removeBookmark = async (bookmarkId: string) => {
    try {
      let updatedBookmarksList = [...bookmarkedEvents]
      await FirestoreService.deleteItem(EVENT_BOOKMARKS.NAME, bookmarkId)

      updatedBookmarksList = updatedBookmarksList.filter((bookmark) => bookmark.id !== bookmarkId)

      setBookmarkedEvents(updatedBookmarksList)
      toastMethods.success({
        message: MESSAGES_CONST.BOOKMARk_REMOVED_SUCCESSFULLY,
      })
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
      throw error
    }
  }

  const getBookmarkId = (eventId: string) => {
    const bookmarkFound = bookmarkedEvents.find((currBookmark) => {
      return currBookmark?.eventId === eventId
    })
    if (bookmarkFound) return bookmarkFound?.id ?? null
    else return null
  }

  const resetEventDetailPageData = () => {
    dispatch(resetSelectedEventAc())
  }

  const getBookmarks = async () => {
    const bookmarks: IEventBookmarkInterface[] = []
    const bookmarkedSnaps = await FirestoreService.filterItems(COLLECTIONS.EVENT_BOOKMARKS.NAME, [
      where(COLLECTIONS.EVENT_BOOKMARKS.FIELDS.USER_ID.KEY, '==', userId),
    ])

    bookmarkedSnaps.docs.forEach((currDoc) => {
      const current = getConvertedData(EventBookmarkModel.fromFirestoreDoc(currDoc).toObject())
      if (current) bookmarks.push(current)
    })

    setBookmarkedEvents(bookmarks)
  }

  useEffect(() => {
    getBookmarks().then()
  }, [userId])

  return (
    <IonItem lines="none" color="#F6F7FB" className="mx-1  ">
      <div id="mainscrollDiv" className="transition-all overflow-auto no-scrollbar flex-1">
        <InfiniteScroll
          dataLength={props.competitions.length}
          next={props.fetchMore}
          hasMore={props.hasMore}
          loader={<CardsLoader />}
          scrollableTarget="mainscrollDiv"
        >
          <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
            {props.competitions.map((competition) => {
              return (
                <motion.div
                  key={competition.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.1 }}
                  className="inline-block col-span-1 rounded-lg px-3 h-min-[430px] mb-4 sm:mb-6"
                >
                  <EventPostHorizontalCardComponent
                    event={competition}
                    bookmarkEvent={bookmarkEvent}
                    isLoggedIn={isLoggedIn}
                    bookmarkId={getBookmarkId(competition?.id ?? '')}
                    resetEventDetailPageData={resetEventDetailPageData}
                    bookmarkBeingProcessed={bookmarkBeingProcessed === competition?.id}
                    isBookmarked={bookmarkedEvents
                      .map((event) => event.eventId)
                      .includes(competition?.id ?? '')}
                  />
                </motion.div>
              )
            })}
          </div>
        </InfiniteScroll>
      </div>
    </IonItem>
  )
}

export default EventPostsHorizontalCardStackComponent
