import { IonList } from '@ionic/react'
import NotFoundAlert from '../../components/common/alerts/not-found/NotFoundAlert'
import WrapperContainer from '../../components/common/wrappers/WrapperContainer'
import { useHorsePublicProfile } from './hooks'
import IHorsePublicProfile from './types'
import HorseProfileCard from './components/profile-card'
import HorseCompetetionDetailsCard from './components/competetion-details-card'
import { useParams } from 'react-router'
import { useEffect, useState } from 'react'
import { where } from 'firebase/firestore'
import { getConvertedData } from '../../models/interface.helper'
import { RegistrationByDayModel } from '../../models/registrations-by-day/registrationByDay.model'
import FirestoreService from '../../services/firestoreService'
import { CONST } from '../../const/const'
import { IRegistrationByDayInterface } from '../../models/registrations-by-day/registrationByDay.interface'
import { IEventInterface } from '../../models/events/event.interface'
import { EventModel } from '../../models/events/event.model'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
const HorsePublicProfile = (_: IHorsePublicProfile['IProps']) => {
  const [pastEvents, setPastEvents] = useState<IEventInterface[]>([])
  const [newEvents, setNewEvents] = useState<IEventInterface[]>([])
  const { horseDocId } = useParams<{ horseDocId: string }>()
  const horsePublicProfile = useHorsePublicProfile()

  const handleGetHorse = async (horseDocId: string) => {
    let registrationsByDay_: IRegistrationByDayInterface[] = []
    let events_: IEventInterface[] = []
    let pastEvents_: IEventInterface[] = []
    let newEvents_: IEventInterface[] = []
    let ids: string[] = []
    const registrationByDaySnaps = await FirestoreService.filterItems(
      COLLECTIONS.REGISTRATION_BY_DAY.NAME,
      [where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.HORSE_ID.KEY, '==', horseDocId)]
    )

    registrationByDaySnaps.docs.forEach((currDoc) => {
      registrationsByDay_.push(
        getConvertedData(RegistrationByDayModel.fromFirestoreDoc(currDoc).toObject())
      )
    })

    const eventsSnapshots = await FirestoreService.filterItems(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.NAME
    )

    eventsSnapshots.docs.forEach((currDoc) => {
      events_.push(getConvertedData(EventModel.fromFirestoreDoc(currDoc).toObject()))
    })

    registrationsByDay_.forEach((registrationByDay) => {
      const event = events_.find((event) => event.id === registrationByDay.eventId)

      if (event?.id && event.eventEndDate && !ids.includes(event.id)) {
        if (new Date(event.eventEndDate) < new Date()) {
          pastEvents_.push(event)
          ids.push(event.id)
        }
        if (new Date(event.eventEndDate) >= new Date()) {
          newEvents_.push(event)
          ids.push(event.id)
        }
      }
    })

    setPastEvents(pastEvents_)
    setNewEvents(newEvents_)
  }
  useEffect(() => {
    if (horseDocId) handleGetHorse(horseDocId).then()
  }, [horseDocId])

  return (
    <WrapperContainer
      title="Profile"
      removeShadow={true}
      removePadding={true}
      loading={horsePublicProfile.loading}
      isError={horsePublicProfile.horseNotFound}
      noBg={!horsePublicProfile.loading && !horsePublicProfile.horseNotFound}
    >
      {horsePublicProfile.horseNotFound ? (
        <NotFoundAlert description="Horse not found" />
      ) : (
        <div className="flex flex-col gap-1 w-full mt-2">
          <div className="flex lg:flex-row flex-col gap-6">
            <IonList className="home_root_ion_list lg:max-w-[435px]">
              <HorseProfileCard {...horsePublicProfile} />
            </IonList>
            <IonList className="home_root_ion_list flex flex-col flex-1 overflow-x-auto">
              <HorseCompetetionDetailsCard pastEvents={pastEvents} newEvents={newEvents} />
            </IonList>
          </div>
        </div>
      )}
    </WrapperContainer>
  )
}

export default HorsePublicProfile
