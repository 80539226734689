import React, { useEffect, useRef, useState } from 'react'

// Third party
import { Search } from '@mui/icons-material'
import { cloneDeep } from 'lodash'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'

import customImageComponent from '../../common/CustomImageComponent'
import MainModal from '../../modals/common/MainModal'
import ViewsLoader from '../../loader/ViewsLoader'

// Firestore
import FirestoreService from '../../../services/firestoreService'

import useToasterHelper from '../../../helpers/ToasterHelper'
import { CustomError, getUserFullName } from '../../../helpers/helpers'

// Redux
import { HorseModel } from '../../../models/horse/horse.model'
import { getConvertedData } from '../../../models/interface.helper'
import { IPaperworkTab_ } from '../../../models/event-registered-users/event-registered-users.interface'
import { IHorseTeamInterface } from '../../../models/horse-team/horseTeam.interface'
import { HorseTeamModel } from '../../../models/horse-team/horseTeam.model'
import { IRiderTeamMemberInterface } from '../../../models/rider-team-member/riderTeamMember.interface'
import { IRiderTeamMember, IUserInterface } from '../../../models/users/user.interface'
import { UserModel } from '../../../models/users/user.model'
import { ITeamMember } from '../../../models/users/user.interface'

import {
  selectRegisterEventData,
  setRegisterPaperworkData,
} from '../../../store/events/eventsSlice'
import { useAppSelector } from '../../../store/hooks'
import {
  getSelectedHorse,
  getSelectedHorseMapping,
  selectHorses,
  selectHorseReducer,
  setSelectedHorse,
  setSelectedHorseTeam,
  updateHorse,
} from '../../../store/horses/horseSlice'
import {
  selectHorseTeamMembers,
  selectProfileData,
  setProfileDetails,
} from '../../../store/user/userSlice'
import { selectPaperworkTabData } from '../../../store/registration/registrationSlice'
import { RootState } from '../../../store/store'

import helpers from '../../../commonHelpers/helpers'

// Constants
import { CONST } from '../../../const/const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { MODAL_CONSTS } from '../../../const/modal-const'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

type Props = {
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: {
    modal_type: string
    registrationByDayDocId: string
    saveHandler: (value: IHorseTeamInterface[] | IRiderTeamMember[]) => void
  }
}

type IModalType = {
  HORSE: boolean
  TEAM: boolean
}

type IGetSignatoryFn = (
  registrationByDayDocId: IRiderTeamMemberInterface['registrationByDayDocId']
) => IRiderTeamMemberInterface[]

// Constants
export const ITEAMMEMBER_MODAL_TYPES = {
  HORSE: 'forHorses',
  TEAM: 'forTeam',
}

const CHECKED_ICON = 'assets/cp_icons/Ok-3.svg'

const FILE_NAME = 'UserProfileTeamTabAddTeamMember'

const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

const UserProfileTeamTabAddTeamMember = ({ dataToPassOn, handleModal }: Props) => {
  const toastFunctions = useToasterHelper()
  const dispatch = useDispatch()

  const types: IModalType = {
    TEAM: dataToPassOn?.modal_type === 'forTeam',
    HORSE: dataToPassOn?.modal_type === 'forHorses',
  }

  const horseData = useAppSelector(selectHorses)
  const profileDetails = useAppSelector(selectProfileData)
  const selectedHorse = useAppSelector(getSelectedHorse)
  const horseReducer = useAppSelector(selectHorseReducer)
  const HorseTeamList = useAppSelector(selectHorseTeamMembers) as ITeamMember[]
  const paperworkTabData = useAppSelector(selectPaperworkTabData)
  const registerFormData = useAppSelector(selectRegisterEventData)
  const selectedHorseMapping = useAppSelector(getSelectedHorseMapping)
  const userReducer = useAppSelector((state: RootState) => state.user)

  let registerDataCloned = cloneDeep(registerFormData)

  const searchBoxRef = useRef<HTMLInputElement>(null)

  const [sameTypeUsers, setSameTypeUsers] = useState<ITeamMember[]>()
  const [loading, setLoading] = useState(false)
  const [membersToRender, setMembersToRender] = useState<ITeamMember[]>([])

  const getAllUsers = async () => {
    setLoading(true)

    let teamMembers: ITeamMember[] = []
    let usersDetails: IUserInterface | null = null

    try {
      const usersSnaps = await FirestoreService.filterItems(COLLECTIONS.USERS.NAME)

      usersSnaps.forEach((currDoc) => {
        usersDetails = UserModel.fromFirestoreDoc(currDoc).cloneDeep()
        if (usersDetails.id === profileDetails.id) return
        teamMembers.push({
          memberCountry: '',
          memberId: usersDetails.id,
          memberprofileSynced: false,
          memberEmail: usersDetails.userEmail,
          memberDob: usersDetails.userDOB ?? '',
          memberUserName: usersDetails.userName,
          memberAddress: usersDetails.userAddress,
          defaultRole: usersDetails.userDefaultRole ?? '',
          memberPhoneNumber: usersDetails.userPhoneNumber,
          memberProfilePicture: usersDetails.userProfilePicture,
          memberName: getUserFullName(usersDetails),
        })
      })

      await getSameTypeUsers(teamMembers)
    } catch (error) {
      console.error(error, 'error')
    } finally {
      setLoading(false)
    }
  }

  const getSameTypeUsers = async (allUsers: ITeamMember[]) => {
    const roles = [CONST.UI.TEAM_MEMBERS_ROLES.TEAM_MEMBER]

    let list: ITeamMember[] = []
    let alreadyExists = false
    let user: ITeamMember
    let usersOfSameType: ITeamMember[] = []
    let riderTeamMembers: IRiderTeamMemberInterface[] = getSignatories(
      dataToPassOn?.registrationByDayDocId
    )

    if (types.TEAM) {
      list = profileDetails.userTeamMembers ?? []
    } else if (types.HORSE) {
      list = horseReducer.selected.team.data
    }

    if (riderTeamMembers.length) return

    allUsers.forEach((curr) => {
      alreadyExists = !!list.find((currHorseTeamMember) => {
        return curr?.memberId === currHorseTeamMember.memberId
      })

      if (curr?.memberId && !alreadyExists) {
        user = getConvertedData({
          ...curr,
          selected: false,
          memberStatus: '1',
          memberRole: roles[0],
          memberShipActive: false,
          signedStatus: 'Not sent',
          memberprofileSynced: false,
          memberSafeSupportTraining: false,
        })

        usersOfSameType = [...usersOfSameType, user]
      }
    })

    setSameTypeUsers([...usersOfSameType])
  }

  useEffect(() => {
    getAllUsers().then()
  }, [])

  /** Returns signatories of a subdoc  */
  const getSignatories: IGetSignatoryFn = (registrationByDayDocId) => {
    if (!registrationByDayDocId) return []

    let paperworkRowIndex = -1
    let foundPaperworkRow: IPaperworkTab_ | null = null
    let ridersTeamMembers: IRiderTeamMemberInterface[] = []

    try {
      foundPaperworkRow =
        paperworkTabData.find((currPaperworkRow, index) => {
          if (currPaperworkRow.registrationByDayDocId === registrationByDayDocId) {
            paperworkRowIndex = index
            return true
          }
          return false
        }) ?? null

      const { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
        registrationByDayDocId,
        foundPaperworkRow,
      })

      if (emptyVarName)
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'getSignatories',
          devMessage: `${emptyVarName} is ${emptyVarValue}`,
        })

      ridersTeamMembers = paperworkTabData?.[paperworkRowIndex]?.ridersTeamMembers ?? []
    } catch (error) {
      console.error(error, 'error')
    } finally {
      return ridersTeamMembers
    }
  }

  /** Filters the user in actual */
  const handleUsers = (strToSearch: string) => {
    if (strToSearch === '') return setMembersToRender([])
    const filteredUserList = sameTypeUsers?.filter((curr) => {
      return (
        curr.memberName?.toLowerCase()?.includes(strToSearch?.toLowerCase()) ||
        curr.memberUserName?.toLowerCase()?.includes(strToSearch?.toLowerCase()) ||
        curr.memberEmail?.toLowerCase()?.includes(strToSearch?.toLowerCase())
      )
    })
    const furtherFilter =
      HorseTeamList.length && filteredUserList?.length
        ? filteredUserList.filter((data) =>
            HorseTeamList.find((horse) => horse.memberId !== data.memberId)
          )
        : filteredUserList

    setMembersToRender([...(furtherFilter ?? [])])
  }

  /** Handles click on user */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, team_member: ITeamMember) => {
    const newCheckedUsers = membersToRender.map((currUser) => {
      if (currUser.memberId === team_member?.memberId) {
        return { ...currUser, selected: e.target.checked }
      }
      return currUser
    })
    setMembersToRender([...newCheckedUsers])
  }

  /**  Adds the selected members to redux, but not to db */
  const addTeamMembersToViewOnly = async () => {
    let mappingId = selectedHorseMapping.data?.id
    const selectedMembers = membersToRender?.filter((currUser) => currUser.selected)

    if (selectedMembers.length <= 0) {
      return toastFunctions.info({ message: MESSAGES_CONST.SELECT_TEAM_MEMBERS_TO_SAVE })
    }

    try {
      if (types.HORSE) {
        let horseTeamMembersList: IHorseTeamInterface[] = []
        let horse = new HorseModel(selectedHorse ?? undefined).toObject()
        let horseIndexInRedux = -1

        if (!mappingId || !horse.id)
          return toastFunctions.error({
            message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
          })

        horseTeamMembersList = cloneDeep(horseReducer.selected.team.data)
        selectedMembers.forEach((currTeamMember) => {
          horseTeamMembersList.push(
            getConvertedData(
              new HorseTeamModel({
                isMyHorse: true,
                horseId: horse.id ?? null,
                horseZone: horse.horseZone,
                horseName: horse.horseName ?? null,
                userId: userReducer.userId,
                horseOwnerId: horse.horseOwnerId,
                memberId: currTeamMember.memberId,
                horseNameNGram: horse.horseNameNGram,
                memberName: currTeamMember.memberName,
                userHorseMappingId: mappingId ?? null,
                horseDiscipline: horse.horseDiscipline,
                memberRole: currTeamMember.memberRole ?? null,
                horseProfilePicture: horse.horseProfilePicture,
                memberProfilePicture: currTeamMember.memberProfilePicture,
              }).toObject()
            )
          )
        })

        horse.horseTeamMembers_ = horseTeamMembersList
        horse = getConvertedData(horse)
        horseIndexInRedux = horseData.findIndex((currHorse) => currHorse.horseId === horse.horseId)

        dispatch(setSelectedHorse(horse))
        dispatch(
          updateHorse({
            data: {
              ...horse,
              isMyHorse: userReducer.userId === horse.horseOwnerId,
            },
            index: horseIndexInRedux,
          })
        )
        dispatch(setSelectedHorseTeam(horseTeamMembersList))
        dataToPassOn.saveHandler && dataToPassOn.saveHandler(horseTeamMembersList)
      } else if (types.TEAM) {
        selectedMembers.forEach((member, i) => {
          selectedMembers[i] = {
            ...member,
            connectedDate: new Date().toString(),
          }
        })
        let updatedTeamMembersList = [...(profileDetails.userTeamMembers ?? []), ...selectedMembers]

        let paperworkWithMembers = Object.entries(registerDataCloned.paperwork).reduce(
          (acc: any, current: any) => {
            let key = current[0]
            acc = {
              ...registerDataCloned.paperwork,
              [key]: [...registerDataCloned.paperwork[key], ...selectedMembers],
            }
            return acc
          },
          {}
        )
        dispatch(setRegisterPaperworkData(paperworkWithMembers))
        dispatch(setProfileDetails({ ...profileDetails, userTeamMembers: updatedTeamMembersList }))
        dataToPassOn.saveHandler && dataToPassOn.saveHandler(updatedTeamMembersList)
      }

      handleModal(false, MODAL_CONSTS.TEAM)
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
    }
  }

  const focusInput = () => {
    if (searchBoxRef.current) searchBoxRef.current?.focus()
  }

  return (
    <MainModal
      title="Add team member"
      show={true}
      type="TEAM"
      size="md"
      onTransitionEnd={focusInput}
      titleClassName="!font-normal"
      buttons={[
        {
          label: 'SAVE',
          bgClass: 'bg-SeabiscuitMainThemeColor',
          onClick: addTeamMembersToViewOnly,
          textClass: 'text-white',
          fullWidth: true,
        },
        {
          label: 'CANCEL',
          bgClass: 'bg-SeabiscuitLightThemeColor',
          borderClass: 'border border-transparent',
          textClass: 'text-SeabiscuitLightTextColor',
          onClick: () => handleModal(false, MODAL_CONSTS.TEAM),
          fullWidth: true,
        },
      ]}
    >
      <>
        <div className="inputSearch py-2 flex items-center sticky top-0 bg-white">
          <input
            disabled={loading}
            ref={searchBoxRef}
            type="text"
            onChange={(e) => handleUsers(e.target.value)}
            placeholder="Search..."
            className="rounded-full w-full border focus:border-[#D3DAEE] border-[#D3DAEE] text-SeabiscuitDark200ThemeColor focus:ring-0"
          />
          <Search className="absolute text-SeabiscuitMainThemeColor right-4" />
        </div>
        {loading ? (
          <div className="flex justify-center items-center w-full h-full">
            <ViewsLoader size="xl" color="red" />
          </div>
        ) : (
          <div className="uselListWarp mt-4 flex flex-col">
            {membersToRender && membersToRender.length > 0 ? (
              membersToRender.map((item, index) => (
                <label key={index} className="form-check mb-3 cursor-pointer">
                  <div className="user flex items-center rounded-lg py-1 w-full justify-between">
                    <div className="min-w-fit flex items-center">
                      <div className="w-[40px] h-[40px] min-w-[40px] rounded-full mr-2 overflow-hidden bg-gray-100">
                        {customImageComponent(item.memberProfilePicture, item.memberName)}
                      </div>
                      <div className="hourseDetails w-10/12">
                        <div className="hourseTitle text-SeabiscuitDark200ThemeColor">
                          <p className="text-SeabiscuitDark200ThemeColor text-lg capitalize">
                            {item?.memberName}
                          </p>
                          <p className="text-xs">@{item?.memberUserName}</p>
                        </div>
                      </div>
                    </div>

                    <img
                      src={CHECKED_ICON}
                      className={clsx('relative right-0 top-0', !item.selected && 'hidden')}
                      width={20}
                      height={20}
                      alt="checked"
                    />

                    <input
                      onChange={(e) => {
                        handleChange(e, item)
                      }}
                      checked={item?.selected}
                      className={clsx(
                        'select-none ml-2 form-check-input appearance-none mr-[2px] h-4 w-4 border active:border-SeabiscuitMainThemeColor hover:border-SeabiscuitMainThemeColor focus:border-SeabiscuitMainThemeColor rounded-full focus:ring-0 focus:ring-offset-0 transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer right-0 checked:bg-SeabiscuitMainThemeColor checked:border-SeabiscuitMainThemeColor',
                        item.selected && 'hidden'
                      )}
                      type="checkbox"
                    />
                  </div>
                </label>
              ))
            ) : (
              <div className="w-full mt-2 text-center text-SeabiscuitDark200ThemeColor">
                {searchBoxRef.current?.value && searchBoxRef.current.value.length > 0
                  ? 'No result found.'
                  : 'Please enter name or email in the search'}
              </div>
            )}
          </div>
        )}
      </>
    </MainModal>
  )
}

export default UserProfileTeamTabAddTeamMember
