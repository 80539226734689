import { sortBy } from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { IEventInterface } from '../../../models/events/event.interface'
import { RootState } from '../../store'
import { httpService } from '../../../services/httpService'
import { CONST } from '../../../const/const'

type IPayload = {
  fetchMore: boolean
  isPublished: boolean
  status?: string
}

type IGetEventList = {
  location: {
    lat: number
    lng: number
    radius: number
  } | null
  search: string[]
  price: number | null
  endDate: Date | null
  startDate: Date | null
  disciplines: string[]
  followingCursorId: string | null
  dateFilterType: string[] | null
  // dateFilterType: 'this month' | 'this week' | 'next year' | 'next month' | null
  status: IEventInterface['status'][]
  category: 'licensed' | 'unlicensed' | 'clinic' | 'other' | 'following' | null
  cursorId: string | null
  limit: number
  userId: string | null
  isPublished: boolean | null
}

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

let abortController: AbortController | null = null

const getEventsThunk = createAsyncThunk('getEventsThunk', async (payload: IPayload, thunkApi) => {
  let message: string | null = null
  let events: IEventInterface[] = []
  let state = thunkApi.getState() as RootState
  let filters = state.filters
  let existFilters = state.filters.activeFilters
  let isOrganizerLoggedIn = state.user.userType === 'organizer'
  let userId = state.user.userId
  let fetchMore = payload.fetchMore
  let isPublished = payload.isPublished
  let hasMore = false
  let cursorId: string | null = null
  let followingCursorId = state.events.events.followingCursorId

  try {
    // Reason to add: whenever we change the filters rapidly the request doesn't gets fulfilled sequentially so that sets the events recieved for other request too.

    if (abortController) {
      abortController.abort()
      abortController = null
    }

    let searchFilter = filters.activeFilters.search
    let locationFilter = filters.activeFilters.location
    let getEventListApiData: IGetEventList = {
      limit: 20,
      search: [],
      price: null,
      userId: null,
      endDate: null,
      startDate: null,
      cursorId: null,
      location: null,
      disciplines: [],
      followingCursorId,
      category: null,
      status: [COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.CURRENT],
      dateFilterType: null,
      isPublished: null,
    }

    if (payload.status) {
      getEventListApiData.status = [payload.status as any]
    }

    let ed = existFilters.groupFilters.endDate
    let st = existFilters.groupFilters.startDate
    let cf = existFilters.groupFilters.categoriesFilter
    let df = existFilters.groupFilters.disciplineFilter
    let dcf = existFilters.groupFilters.dateCategoryFilter

    if (fetchMore) {
      getEventListApiData.cursorId = state.events.events.data?.at(-1)?.id ?? null
    }

    if (isOrganizerLoggedIn) {
      const status = existFilters.statusFilter

      getEventListApiData.status = [status] as IGetEventList['status']
    } else {
      if (!isNaN(Number(existFilters.groupFilters.priceRange))) {
        getEventListApiData.price = Number(existFilters.groupFilters.priceRange)
      }
    }

    if (cf?.[0]) {
      if (cf[0] === COLLECTIONS.EVENTS.FIELDS.CATEGORIES.VALUES.ALL) {
        // If "All" is selected, do not filter by category
        getEventListApiData.category = null
      } else {
        // Apply the selected category filter unless the user is an organizer
        getEventListApiData.category = isOrganizerLoggedIn
          ? null
          : (cf[0] as IGetEventList['category'])
      }
      // If the "Following" category is included, set the userId to filter events
      if (cf.includes(COLLECTIONS.EVENTS.FIELDS.CATEGORIES.VALUES.FOLLOWING)) {
        getEventListApiData.userId = userId
      }
    } else if (isOrganizerLoggedIn) {
      // If no category filter is selected and the user is an organizer, do not filter by category
      getEventListApiData.category = null
    }

    getEventListApiData.disciplines = df

    if (ed || st) {
      if (ed) getEventListApiData.endDate = new Date(ed)
      if (st) getEventListApiData.startDate = new Date(st)
    } else {
      getEventListApiData.dateFilterType = dcf.map((value) => value.toLowerCase())
    }

    if (searchFilter.status) {
      const words = searchFilter.searchValue.split(' ')
      getEventListApiData.search = words.map((word: string) => word.toLowerCase())
    }

    if (locationFilter.status) {
      getEventListApiData.location = {
        lat: locationFilter.lat,
        lng: locationFilter.lng,
        radius: locationFilter.radius,
      }
    }

    if (isOrganizerLoggedIn) {
      getEventListApiData.userId = userId
    }
    if (isPublished) {
      getEventListApiData.isPublished = isPublished
    }

    abortController = new AbortController()

    const response = await httpService({
      url: `get_event_list`,
      method: 'POST',
      data: getEventListApiData,
      abortSignal: abortController.signal,
    })

    abortController = null

    events = sortBy(
      response.data.events,
      (event) => new Date(event.eventStartDate as string)
    ).reverse()

    hasMore = response.data.hasMore
    cursorId = response.data.cursorId
    followingCursorId = response.data.followingCursorId ?? null

    return {
      message,
      events,
      fetchMore,
      cursorId,
      hasMore,
      followingCursorId,
    }
  } catch (error: any) {
    return thunkApi.rejectWithValue({
      message: error?.message ?? error,
      events: [],
      code: error.code,
    })
  }
})

export { getEventsThunk }
