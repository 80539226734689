import { useEffect, useState } from 'react'

// Custom components
import CompetitorProfileRootPageProfileTab from '../../../components/user/views/profile/CompetitorProfileRootPageProfileTab'
import ProfileEligibilityTab from '../../../components/user/views/profile/ProfileEligibilityTab'
import ProfileLegalTab from '../../../components/user/views/profile/ProfileLegalTab'
import ProfilePointOfContactTab from '../../../components/user/views/profile/ProfilePointOfContactTab'
import ProfileRootPageAccountTab from '../../../components/user/views/profile/ProfileRootPageAccountTab'
import ProfileRootPageHorseTab from '../../../components/user/views/profile/ProfileRootPageHorseTab '
import ProfileRootPageTeamTab from '../../../components/user/views/profile/ProfileRootPageTeamTab'
import ProfileTeamTabCompetitor from '../../../components/user/views/profile/ProfileTeamTabCompetitor'

// Redux
import { useAppSelector } from '../../../store/hooks'
import { selectUseMode } from '../../../store/system/systemSlice'

// Constants
import { useIonRouter } from '@ionic/react'
import OrganizerProfileRootPageProfileTab from '../../../components/user/views/profile/OrganizerProfileRootPageProfileTab'
import ProfileRootPageFinanceTab from '../../../components/user/views/profile/Tabs/profile-root-page-finance-tab/ProfileRootPageFinanceTab'
import { DATA_FIRESTORE_V01_EVENT_REGISTERED_USERS } from '../../../const/data/v01/event-registered-users/firestore-v01-event-registered-users-const'
import { OrganizerMembershipsTab } from '../../../components/user/views/profile/OrganizerMembershipsTab/OrganizerMembershipsTab'
import { CompetitorMembershipsTab } from '../../../components/user/views/profile/CompetitorMembershipsTab/CompetitorMembershipsTab'
import clsx from 'clsx'

// Types
type Props = {
  activeTab: string
  setActiveTab: React.Dispatch<React.SetStateAction<string>>
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
  isOrganizer: boolean
}

export const USER_TYPES = DATA_FIRESTORE_V01_EVENT_REGISTERED_USERS.FIELDS.USER_TYPE.VALUES

export const ORGANIZER_SETTING_PAGE_TABS = [
  'Account',
  'Finances',
  'Profile',
  'Legal',
  'Eligibility',
  'Team',
  'Point of Contact',
  'Memberships',
]
export const ORGANIZER_SETTINGS_PAGE_TABS_OBJ = {
  ACCOUNT: 'account',
  PROFILE: 'profile',
  TEAM: 'team',
  LEGAL: 'legal',
  ELIGIBILITY: 'eligibility',
  POINT_OF_CONTACT: 'point of contact',
  MEMBERSHIPS: 'memberships',
}

export const SETTINGS_PAGE_TABS = [
  'Account',
  'Profile',
  'Horses',
  'Team',
  'Eligibility',
  'Memberships',
]
export const SETTINGS_TABS_OBJ = {
  ACCOUNT: 'account',
  PROFILE: 'profile',
  FINANCE: 'finances',
  ELIGIBILITY: 'eligibility',
  HORSES: 'horses',
  TEAM: 'team',
  MEMBERSHIPS: 'memberships',
}

const Tabs = (props: Props) => {
  // Hooks and vars
  const activeTab = (props?.activeTab ?? SETTINGS_TABS_OBJ.ACCOUNT)?.toLocaleLowerCase()
  const isOrganizer = props.isOrganizer ?? false

  // Functions

  const getComponentToRender = () => {
    switch (activeTab) {
      case SETTINGS_TABS_OBJ.PROFILE:
        return isOrganizer ? (
          <OrganizerProfileRootPageProfileTab />
        ) : (
          <CompetitorProfileRootPageProfileTab handleModal={props.handleModal} />
        )

      case SETTINGS_TABS_OBJ.HORSES:
        return <ProfileRootPageHorseTab />
      case SETTINGS_TABS_OBJ.ACCOUNT:
        return (
          <ProfileRootPageAccountTab
            handleModal={props.handleModal}
            setActiveTab={props.setActiveTab}
          />
        )
      case SETTINGS_TABS_OBJ.FINANCE:
        return <ProfileRootPageFinanceTab handleModal={props.handleModal} />
      case ORGANIZER_SETTINGS_PAGE_TABS_OBJ.LEGAL:
        return <ProfileLegalTab handleModal={props.handleModal} />
      case ORGANIZER_SETTINGS_PAGE_TABS_OBJ.ELIGIBILITY:
        return (
          <ProfileEligibilityTab
            handleModal={props.handleModal}
            activeTab={props.activeTab}
            setActiveTab={props.setActiveTab}
          />
        )
      case ORGANIZER_SETTINGS_PAGE_TABS_OBJ.POINT_OF_CONTACT:
        return <ProfilePointOfContactTab handleModal={props.handleModal} />

      case SETTINGS_TABS_OBJ.TEAM:
        if (isOrganizer) return <ProfileRootPageTeamTab handleModal={props.handleModal} />
        return <ProfileTeamTabCompetitor handleModal={props.handleModal} />

      case ORGANIZER_SETTINGS_PAGE_TABS_OBJ.MEMBERSHIPS:
        return isOrganizer ? <OrganizerMembershipsTab /> : <CompetitorMembershipsTab />
    }
  }

  return (
    <>
      <div className="flex flex-wrap w-full flex-1">
        <div className="w-full flex flex-col">
          <div className="relative flex flex-col min-w-0 break-words w-full flex-1">
            <div className="flex-auto flex flex-col">
              <div className="flex-1 flex flex-col"> {getComponentToRender()} </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const ProfileRootPageEditProfile = (props: {
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
  className?: string
}) => {
  // Hooks and vars
  let isOrganizer = false

  const router = useIonRouter()

  const type = localStorage.getItem('tab_type')

  const useMode = useAppSelector(selectUseMode)
  // const [activeTab, setActiveTab] = useState<string>("Profile");
  const [activeTab, setActiveTab] = useState<string>('Account')

  useEffect(() => {
    const hash = window.location.hash.replace('#', '')
    if (hash) {
      setTimeout(() => {
        setActiveTab(hash)
      }, 100)
    }
  }, [])

  useEffect(() => {
    if (router.routeInfo.search) {
      const tab = new URLSearchParams(router.routeInfo.search).get('tab')
      if (tab) setActiveTab(tab!)
    } else if (router.routeInfo.pushedByRoute) {
      if (
        router.routeInfo.pushedByRoute?.includes('edit-horse') ||
        router.routeInfo.pushedByRoute?.includes('/add-horse')
      ) {
        setActiveTab('Horses')
      } else {
        // setActiveTab(router?.routeInfo?.routeOptions?.as ?? "Profile")
        setActiveTab(router?.routeInfo?.routeOptions?.as ?? 'Account')
      }
      return
    } else if (type === '2') {
      setActiveTab('Horses')
      localStorage.setItem('tab_type', '')
    }
  }, [type, router.routeInfo])
  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId)
    window.location.hash = tabId
  }

  if (useMode === USER_TYPES.EXHIBITOR.KEY || useMode === 'organizer') isOrganizer = true

  return (
    <div className={clsx('event-tab bg-white flex-1 flex flex-col', props.className)}>
      <div className="flex pt-0 mb-[30px] lg:pb-1.5 pb-4 max-w-full overflow-y-auto">
        {(isOrganizer ? ORGANIZER_SETTING_PAGE_TABS : SETTINGS_PAGE_TABS).map((tab, index) => {
          return (
            <div
              key={tab + `${index}`}
              onClick={() => handleTabClick(tab.toLowerCase())}
              className={`${activeTab.toLowerCase() === tab.toLowerCase() ? 'bg-SeabiscuitDullMainThemeColor text-SeabiscuitMainThemeColor' : ''} text-nr whitespace-nowrap cursor-pointer py-2 px-3 mr-3 rounded-xl min-w-[146px] text-center h-[40px] text-SeabiscuitDark200ThemeColor`}
            >
              {tab}
            </div>
          )
        })}
      </div>

      <Tabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        isOrganizer={isOrganizer}
        handleModal={props.handleModal}
      />
    </div>
  )
}

export default ProfileRootPageEditProfile
