// Constants
import { HELP_PAGE_DATA } from './helpPageData'

import { PillButtonElement } from '../../../components/elements/buttons/pill/PillButtonElement'

type Props = {
  selectedTab?: any
  selectTab?: any
}

const MyEvent = (props: Props) => {
  return (
    <>
      <div className="helpPage">
        <div className="w-full bg-white !rounded-2xl border-0 pt-6 pl-6">
          <ul className="flex">
            {HELP_PAGE_DATA.tabs.map((item, itemIndex) => {
              return (
                <PillButtonElement
                  key={`${JSON.stringify(item)}${itemIndex}`}
                  text={item.tabTitle}
                  Class="h-[45px] w-[120px] border-none text-SeabiscuitDark200ThemeColor"
                  onButtonClicked={() => props.selectTab(item, true)}
                  value={item.tab}
                  selected={props.selectedTab.tab === item.tab}
                />
              )
            })}
          </ul>
        </div>
      </div>
    </>
  )
}

export default MyEvent
