import { FC, useContext } from 'react'

import { useParams } from 'react-router'
import { motion } from 'framer-motion'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { where } from 'firebase/firestore'
import { cloneDeep, differenceWith, isEqual, isNumber } from 'lodash'
import { useDispatch } from 'react-redux'
import { useIonRouter } from '@ionic/react'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'

import helpers from '../../../../commonHelpers/helpers'
import { CustomError } from '../../../../helpers/helpers'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import FirestoreService from '../../../../services/firestoreService'
import { eligibleToSign } from '../../../../helpers/sign'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'

import {
  selectRegisterTabData,
  selectRegistration,
  selectRegistrationAnswers,
  selectRidersTeamMembersInDb,
  selectRidersTeamMembersR,
  setRegistrationLoading,
} from '../../../../store/registration/registrationSlice'
import { selectedEvent } from '../../../../store/events/eventsSlice'
import { useAppSelector } from '../../../../store/hooks'
import { selectIsLoggedIn } from '../../../../store/user/userSlice'

import { IRiderTeamMemberInterface } from '../../../../models/rider-team-member/riderTeamMember.interface'
import { RegistrationByDayModel } from '../../../../models/registrations-by-day/registrationByDay.model'
import ITypes from '../hooks/useEventRegistrationTabs.types'
import { IManageInfo } from '../EventRegistrationTabs'
import { EventRegisteredUsersModel } from '../../../../models/event-registered-users/event-registered-users.model'
import { ICompetitorEventRegisterTypes as ICerhTypes } from '../../competitorEventRegister/competitorEventRegisterHelper'
import { IUserInterface } from '../../../../models/users/user.interface'
import { IDocumentList } from '../../../../fakeData/fakeDocumentList'

import { CONST } from '../../../../const/const'
import { ROUTES_CONST } from '../../../../const/routes-const'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { IRegisterTab } from '../../../../models/event-registered-users/event-registered-users.interface'
import { IRegistrationByDayInterface } from '../../../../models/registrations-by-day/registrationByDay.interface'
import { getRegistrations } from '../../../../helpers/getRegistrations'

interface RegistrationButtonsProps {
  eventTab: { tab: string; step: number }
  setEventTab: (value: { tab: string; step: number }) => void
  saveRidersTeamMembers: (args: {
    ridersTeamMembersToAdd?: IRiderTeamMemberInterface[]
    ridersTeamMembersToDelete?: IRiderTeamMemberInterface[]
    ridersTeamMembersToUpdate?: IRiderTeamMemberInterface[]
  }) => Promise<any>
  tabsArrayToSearch: any
  loading: boolean
  saveTeamTabData: (registrationId: string) => Promise<void>
  createRegistration: () => Promise<any>
  isManage?: boolean
  manageInfo: IManageInfo
  getEventRegisteredUser: () => Promise<EventRegisteredUsersModel | null | undefined>
  saveAllTabs: ICerhTypes['ISaveAllTabsFn']
  registeredUser: IUserInterface | null
  filteredPaperworkDocuments: IDocumentList
  registrationHasErrors: {
    horses: boolean
    users: boolean
  }
  competitonFull?: boolean
}

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

export const RegistrationButtons: FC<RegistrationButtonsProps> = ({
  eventTab,
  setEventTab,
  saveRidersTeamMembers,
  tabsArrayToSearch,
  loading,
  saveTeamTabData,
  createRegistration,
  isManage,
  manageInfo,
  getEventRegisteredUser,
  saveAllTabs,
  registeredUser,
  filteredPaperworkDocuments,
  registrationHasErrors,
  competitonFull,
}) => {
  const { eventId, userId } = useParams<ITypes['IParams']>()
  const registerTabData = useAppSelector(selectRegisterTabData)

  const router = useIonRouter()
  const history = useHistory()

  const dispatch = useDispatch()
  const toastFunctions = useToasterHelper()

  const handleModalContext = useContext(HandleModalContext)
  const handleModal = handleModalContext?.handleModal

  const rootState = useAppSelector((store) => store)
  const event = selectedEvent(rootState)
  const answers = useAppSelector(selectRegistrationAnswers)
  const registration = useAppSelector(selectRegistration)
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const ridersTeamMembers = useAppSelector(selectRidersTeamMembersR)
  const ridersTeamMembersInDb = useAppSelector(selectRidersTeamMembersInDb)

  // Handles the tab change on click of the next button
  const handleStep = async () => {
    dispatch(setRegistrationLoading(true))

    try {
      if (eventTab.tab === CONST.UI.REGISTER.TABS.HORSES) {
        const registrations = getRegistrations(isManage ? manageInfo.register : registerTabData)

        const hasEmptyFields = registrations.allRegistrations.some(
          (registration) =>
            !((registration.horseIds && registration.horseIds[0]) || registration.horseId)
        )

        if (hasEmptyFields) {
          dispatch(setRegistrationLoading(false))
          return toastFunctions.error({
            message:
              'You must select a horse for each entry. If no horse is available, please choose "No Horse."',
            autoClose: 8000,
          })
        }
      } else if (eventTab.tab === CONST.UI.REGISTER.TABS.COMMENTS) {
        const errorMessage = 'Missing required comments for questions'
        if (event?.Event && event?.Event.questions) {
          const requiredFields = event.Event.questions.filter((q) => q.isRequired)
          if (requiredFields[0] && !answers[0]) {
            dispatch(setRegistrationLoading(false))
            return toastFunctions.error({ message: errorMessage })
          }

          const missingComments = requiredFields.filter((question) => {
            const hasValidComment = answers.some(
              (answer) => answer.questionId === question.id && answer.answer.trim() !== ''
            )
            return !hasValidComment
          })

          if (missingComments[0]) {
            dispatch(setRegistrationLoading(false))
            return toastFunctions.error({ message: errorMessage })
          }
        }

        const registrationByDaySnap = await FirestoreService.filterItems(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_BY_DAY.NAME,
          [
            where(
              CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.EVENT_ID.KEY,
              '==',
              eventId
            ),
            where(
              CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.USER_ID.KEY,
              '==',
              userId
            ),
          ]
        )

        let registrationByDay = registrationByDaySnap?.docs.map((doc: any) => {
          return RegistrationByDayModel.fromFirestoreDoc(doc).toObject()
        })

        registrationByDay.forEach((rbd) => {
          const newData = new RegistrationByDayModel({ ...rbd })

          FirestoreService.updateItem(
            CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_BY_DAY.NAME,
            rbd.id,
            newData.toFirestore()
          )
            .then(() => null)
            .catch((e) => {
              console.log(e)
            })
        })
        await addCommentToEventRegisteredUser()
      } else if (eventTab.tab === CONST.UI.REGISTER.TABS.TEAM) {
        if (registrationHasErrors.horses || registrationHasErrors.users) {
          dispatch(setRegistrationLoading(false))
          return toastFunctions.error({ message: 'Profile has errors' })
        }

        const registration = await createRegistration()
        if (registration?.id) await saveTeamTabData(registration.id)
      } else if (eventTab.tab === CONST.UI.REGISTER.TABS.PAPERWORK && !isManage) {
        await saveRidersTeamMembers(getRidersTeamMembers()).catch((error) => {
          helpers.logger({ message: error.message })
          toastFunctions.error({
            message: error.message,
          })
        })
        dispatch(setRegistrationLoading(false))
      } else if (eventTab.tab === CONST.UI.REGISTER.TABS.SIGN) {
        const { notSignedOwner, isMinorRider, isMinorGuardian } = eligibleToSign({
          paperworkDocuments: filteredPaperworkDocuments,
          userId: registration?.userId ?? userId,
          ridersTeamMembers,
          isManage,
          manageInfo,
        })

        if (notSignedOwner) {
          dispatch(setRegistrationLoading(false))
          return toastFunctions.error({ message: MESSAGES_CONST.REGISTRATION_EVENT_NOT_SIGNED })
        } else if (isMinorRider) {
          // without "return" because minor rider can finish registration without "Guardian"
          toastFunctions.info({ message: MESSAGES_CONST.REGISTRATION_EVENT_RIDER_IS_MINOR })
        } else if (isMinorGuardian) {
          dispatch(setRegistrationLoading(false))
          return toastFunctions.error({
            message: MESSAGES_CONST.REGISTRATION_EVENT_GUARDIAN_IS_MINOR,
          })
        }
      } else if (eventTab.tab === CONST.UI.REGISTER.TABS.REVIEWANDPAY) {
        if (competitonFull) {
          dispatch(setRegistrationLoading(false))
          return toastFunctions.info({
            message: 'We are sorry! Event registration full...',
          })
        }
        handleModal?.(true, MODAL_CONSTS.REGISTER_FOR_COMPETITION_PAY_BY_CARD, {
          saveAllTabs,
          registeredUser: registeredUser,
          manageInfo: manageInfo,
        })

        dispatch(setRegistrationLoading(false))
        return
      }

      scrollToTop()

      let currTabIndex
      tabsArrayToSearch.forEach((currTab: any, index: number) => {
        if (eventTab.tab === currTab.tabName) currTabIndex = index
      })

      if (isNumber(currTabIndex) && tabsArrayToSearch.length >= currTabIndex) {
        if (tabsArrayToSearch[currTabIndex].max_steps > eventTab.step + 1) {
          setEventTab({ tab: eventTab.tab, step: eventTab.step + 1 })
        } else {
          setEventTab({
            tab: tabsArrayToSearch[currTabIndex + 1].tabName,
            step: 0,
          })
        }
      }

      dispatch(setRegistrationLoading(false))
    } catch (error: any) {
      dispatch(setRegistrationLoading(false))

      let error_ = CustomError.somethingWentWrong({
        fileName: 'RegistrationButtons.tsx',
        message: error?.message,
        moduleName: 'handleStep',
        devMessage: error?.message,
      })

      helpers.logger({ message: error })
      toastFunctions.error({
        message: error_?.message,
      })
    }
  }

  const addCommentToEventRegisteredUser = async () => {
    let registeredUser = await getEventRegisteredUser()
    if (registeredUser && registeredUser.id) {
      await FirestoreService.updateItem(
        COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
        registeredUser.id,
        {
          answers,
        }
      )
    }
  }

  const getRidersTeamMembers = () => {
    let ridersTeamMembersToAdd: IRiderTeamMemberInterface[] = []
    let ridersTeamMembersToDelete: IRiderTeamMemberInterface[] = []
    let ridersTeamMembersToUpdate: IRiderTeamMemberInterface[] = []
    const ridersTeamMembers_ = cloneDeep(ridersTeamMembers)
    const ridersTeamMembersInDb_ = cloneDeep(ridersTeamMembersInDb)
    const ridersTeamMembersIds = ridersTeamMembers_.map((ridersTeamMember) => ridersTeamMember.id)

    ridersTeamMembers_.forEach((ridersTeamMember) => {
      if (!ridersTeamMember.id) {
        ridersTeamMembersToAdd.push(ridersTeamMember)
      }
    })

    ridersTeamMembersInDb_.forEach((ridersTeamMemberInDb_) => {
      if (ridersTeamMemberInDb_.id && !ridersTeamMembersIds.includes(ridersTeamMemberInDb_.id)) {
        ridersTeamMembersToDelete.push(ridersTeamMemberInDb_)
      }
    })

    const ridersTeamMembersDifference = differenceWith(
      ridersTeamMembers_,
      ridersTeamMembersInDb_,
      isEqual
    ).filter((difference) => difference.id)[0]

    if (ridersTeamMembersDifference) ridersTeamMembersToUpdate.push(ridersTeamMembersDifference)

    return { ridersTeamMembersToAdd, ridersTeamMembersToDelete, ridersTeamMembersToUpdate }
  }

  const scrollToTop = () => {
    let main = document.querySelector('.event_comp')

    if (main) main?.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        delay: 0.1,
      }}
      onClick={() => {
        localStorage.setItem('continueForm', JSON.stringify(eventTab))
      }}
      className="lg:absolute z-10 flex-wrap flex gap-3 items-center justify-center lg:bottom-auto lg:top-5 p-5 lg:p-0 lg:right-5 text-base"
    >
      {eventTab.tab !== CONST.UI.REGISTER.TABS.TEAM && (
        <button
          onClick={() => {
            let currTabIndex = 0
            tabsArrayToSearch.forEach((currTab: any, index: number) => {
              if (eventTab.tab === currTab.tabName) currTabIndex = index
            })

            if (!currTabIndex) return null
            setEventTab({
              tab: tabsArrayToSearch[currTabIndex - 1].tabName,
              step: currTabIndex - 1,
            })
          }}
          className="rounded-[10px] border-[1px] transition-all border-SeabiscuitMainThemeColor/5 border-solid text-SeabiscuitMainThemeColor py-2 px-3 min-w-[100px] bg-SeabiscuitMainThemeColor/5 hover:bg-SeabiscuitMainThemeColor hover:text-white"
        >
          <KeyboardArrowLeft fontSize="small" className="!mb-[2px] mr-1 !w-5" />
          Back
        </button>
      )}

      <button
        disabled={loading}
        className="rounded-[10px] border-[1px] transition-all border-SeabiscuitMainThemeColor/5 border-solid text-SeabiscuitMainThemeColor py-2 px-3 min-w-[100px]  bg-SeabiscuitMainThemeColor/5  hover:bg-SeabiscuitMainThemeColor hover:text-white"
        type="button"
        onClick={() => {
          if (!isManage) {
            saveRidersTeamMembers(getRidersTeamMembers())
              .then((response) => {
                if (response) {
                  router.push(ROUTES_CONST.MY_EVENT.URL)
                  history.push(ROUTES_CONST.MY_EVENT.URL)
                }
              })
              .catch((error) => {
                helpers.logger({ message: error.message })
                toastFunctions.error({
                  message: error.message,
                })
              })
          }
        }}
      >
        {eventTab.tab === CONST.UI.REGISTER.TABS.TEAM || isManage ? 'Exit' : 'Save & Exit'}
      </button>
      <button
        disabled={loading || !isLoggedIn}
        onClick={handleStep}
        className={clsx(
          'rounded-[10px] disabled:opacity-25 transition-all border-[1px] border-SeabiscuitMainThemeColor/5 border-solid py-2 px-3 w-[100px]',
          eventTab.tab === CONST.UI.REGISTER.TABS.REVIEWANDPAY
            ? 'bg-SeabiscuitMainThemeColor hover:bg-SeabiscuitMainThemeColorDark text-white'
            : 'bg-SeabiscuitMainThemeColor/5  hover:bg-SeabiscuitMainThemeColor text-SeabiscuitMainThemeColor hover:text-white',
          !isLoggedIn && 'opacity-5'
        )}
      >
        {loading ? (
          <AutorenewIcon fontSize="small" className="animate-spin" />
        ) : eventTab.tab === CONST.UI.REGISTER.TABS.CONFIRMATION ||
          eventTab.tab === CONST.UI.REGISTER.TABS.REVIEWANDPAY ? (
          'PAY'
        ) : eventTab.tab === CONST.UI.REGISTER.TABS.TEAM ? (
          'Start'
        ) : (
          'Next'
        )}
        {!loading && eventTab.tab !== CONST.UI.REGISTER.TABS.REVIEWANDPAY && (
          <KeyboardArrowRight fontSize="small" className="!mb-[2px] ml-1 !w-5" />
        )}
      </button>
    </motion.div>
  )
}
