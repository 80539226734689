export const SheetFEIGrandPrix1625 = {
  id: 'SheetFEIGrandPrix1625',
  name: 'FEI Grand Prix 16-25',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 370,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter in collected canter</p><p><span>X •</span> Halt - immobility- salute. Proceed in collected trot.</p><p><span>XC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track to the right</p><p><span>MXK •</span> Extended trot</p><p><span>KA •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p><span>N/A •</span> Transitions at M and K</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>Before D&G •</span> 4 half-passes, 5 m to either side of the centre line, beginning to the left ending to the right</p><p><span>C •</span> Track to the right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>CMR •</span> Collected trot</p><p><span>R •</span> Transition to passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description: '<p><span>RI •</span> Half volte right in passage (10 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description: '<p><span>I •</span> Piaffe 8 to 10 steps</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description: '<p><span>I •</span> Transitions passage - piaffe – passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>IS •</span> Half volte left in passage (10 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description: '<p><span>SP •</span> Medium trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p><span>PFD •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description: '<p><span>D •</span> Piaffe 8 to 10 steps</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description: '<p><span>D •</span> Transitions passage - piaffe - passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description: '<p><span>DKV •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description: '<p><span>VXR •</span> Extended walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 16,
          description: '<p><span>RHC •</span> Collected walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>C •</span> Proceed in collected canter right</p><p><span>CM •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description: '<p><span>MXK •</span> Extended canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>K •</span> Collected canter; Flying change of leg.</p><p><span>KAFP •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>PX •</span> Half-pass to the left</p><p><span>X •</span> Down the centre line</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>I •</span> Pirouette to the left</p><p><span>C •</span> Track to the left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>HXF •</span> On the diagonal 9 flying changes of leg every 2nd stride</p><p><span>FAKV •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description:
            '<p><span>VX •</span> Half-pass to the right</p><p><span>X •</span> Down the centre line</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 24,
          description:
            '<p><span>I •</span> Pirouette to the right</p><p><span>C •</span> Track to the right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 25,
          description:
            '<p><span>MXK •</span> On the diagonal 15 flying changes of leg every stride</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 26,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>DL •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 27,
          description: '<p><span>LX •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 28,
          description: '<p><span>X •</span> Piaffe 8 to 10 steps</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 29,
          description: '<p><span>X •</span> Transitions passage - piaffe - passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 30,
          description: '<p><span>XG •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 31,
          description: '<p><span>G •</span> Halt - immobility - salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Collective Marks',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>General Impression</h5>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}
