import React, { useState } from 'react'
import MainModal from './common/MainModal'
import { MODAL_CONSTS } from '../../const/modal-const'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import CustomDatePicker from '../common/inputs/CustomDatePicker'
import { getReactPickerDate } from '../../models/interface.helper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Select from 'react-select'
import { Status } from '../../fakeData/StatusList'
import { customStyles } from '../customStyles/ReactSelectCustomStyle'
import { UserModel } from '../../models/users/user.model'
import FirebaseStorageService from '../../services/storageService'
import { MESSAGES_CONST } from '../../const/messages-const'
import { CONST } from '../../const/const'
import helpers from '../../commonHelpers/helpers'
import useToasterHelper from '../../helpers/ToasterHelper'
import { IProfileReqField } from '../../pages/competitor/clinicAndOther/Tabs/components/ProfileFields'
import FirestoreService from '../../services/firestoreService'
import { IHorseData } from '../../models/horse/horse.interface'
import { selectAllUsers, setAllUsers } from '../../store/users/usersSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectHorses, setHorses } from '../../store/horses/horseSlice'
import { selectProfileData, setProfileDetails } from '../../store/user/userSlice'
import { IUserInterface } from '../../models/users/user.interface'

interface CustomControllerProps {
  control: any
  placeholder: string
  defaultValue: string
  name: string
  saved: boolean
}

const CustomController = ({
  name,
  placeholder,
  control,
  defaultValue,
  saved,
}: CustomControllerProps) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <Select
            onChange={onChange}
            value={Status.find((c) => c.value === value || c.value === value)}
            onBlur={onBlur}
            options={Status}
            isDisabled={saved}
            placeholder={placeholder}
            className={`border-solid border-[#D3DAEE] border rounded-xl mt-3 md:mt-0 w-full selectWithSearch  focus:ring-[#D3DAEE] focus:border-[#D3DAEE] selectWithSearch p-[2px] h-[51px] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
            styles={
              {
                ...customStyles,
                valueContainer: (provided: any) => ({
                  ...provided,
                  textAlign: 'left',
                  justifyContent: 'start',
                }),
                singleValue: (provided: any, state: any) => ({
                  ...(customStyles?.singleValue && customStyles?.singleValue(provided, state)),
                  fontSize: '14px !important',
                  color: state.isDisabled ? '#122b46' : provided.color,
                  opacity: state.isDisabled ? '1' : provided.opacity,
                }),
                dropdownIndicator: (provided: any, state: any) => ({
                  ...provided,
                  display: state.isDisabled ? 'none' : '',
                }),
              } as any
            }
            isClearable={false}
            isMulti={false}
            isSearchable={true}
          />
        )
      }}
    />
  )
}
const addText = <span className={'text-[#122B4680] text-[14px]'}>Add image of card...</span>

interface ImageWrapperProps {
  url: string
  editMode?: boolean
}

const ImageWrapper: React.FC<ImageWrapperProps> = ({ url }) => {
  return (
    <div className={'relative'}>
      {!url ? (
        addText
      ) : (
        <>
          <img
            className={'w-auto h-[35px] rounded'}
            alt={''}
            onClick={() => window.open(url, '_blank')}
            src={url}
          />
        </>
      )}
    </div>
  )
}

interface IProps {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: {
    data: IProfileReqField
    userData?: UserModel
    horseData?: IHorseData
    isHorse?: boolean
  }
}
export const UpdateUserNumberModal: React.FC<IProps> = ({ dataToPassOn, handleModal }) => {
  const dispatch = useAppDispatch()

  const users = useAppSelector(selectAllUsers)
  const myHorses = useAppSelector(selectHorses)

  const { watch, control, register, setValue, handleSubmit } = useForm({
    mode: 'onChange',
  })

  const { update } = useFieldArray({
    control,
    name: 'userExtraDocument',
  })
  const { userData, horseData, isHorse } = dataToPassOn
  const value = dataToPassOn.data.value || ''
  const toastFunctions = useToasterHelper()
  const [loading, setLoading] = useState(false)
  const cutValue = value.replace('Number', '')
  const profileDetails = useAppSelector(selectProfileData)
  const [img, setImg] = React.useState('')
  const renderImageNumberInput = (field: string, isExternal?: boolean) => {
    if (!userData) return <></>

    const addNumberImage = async (event: any, fieldName: any, isExtraFields?: boolean) => {
      const currFormValues = watch()
      const files = event.target.files
      const file = files[0]
      const PATH = CONST.DATA.STORAGE.USERS.NUMBERS_URL.PREFIX
      if (!file) return toastFunctions.info({ message: MESSAGES_CONST.NO_FILE_SELECTED })

      try {
        const downloadUrl = await FirebaseStorageService.uploadFile(
          file,
          `${PATH}/${userData.id}/${new Date().getTime()}`,
          (percent: number) => {
            if (0) console.log(percent)
          }
        )
        if (
          isExtraFields &&
          currFormValues.userExtraDocument &&
          currFormValues.userExtraDocument[+fieldName]
        ) {
          update(+fieldName, {
            ...currFormValues.userExtraDocument[+fieldName],
            image: `${downloadUrl || ''}`,
          })
        } else if (!isExtraFields) {
          const newUserData = new UserModel({
            ...currFormValues,
            ...userData,
          })

          if ((newUserData as any)[fieldName as any]) {
            ;(newUserData as any)[fieldName as any].image = (downloadUrl ?? '') as string
          }

          setValue(fieldName, {
            ...(currFormValues as any)[fieldName],
            image: `${downloadUrl || ''}`,
          })

          setImg(`${downloadUrl}`)
        }

        toastFunctions.success({ message: MESSAGES_CONST.PIC_UPLOADED })
      } catch (error) {
        toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
        helpers.logger({
          isError: true,
          message: error,
        })
      }
    }

    return (
      <>
        <div className={'flex-1 mt-3 md:mt-0 md:pl-3 w-full md:max-w-[260px]'}>
          <div
            className={
              'group flex items-center justify-between px-3  w-[100%] relative border border-solid rounded-xl !ring-0 mr-2 h-[51px] !border-[#D3DAEE]'
            }
          >
            <ImageWrapper url={img} editMode={true} />

            <div
              className={
                'h-[40px] w-[40px] bg-[#E6EAF2] flex items-center relative hover:bg-[#dcdfe7] cursor-pointer rounded-[4px] justify-center'
              }
            >
              <span className={'text-[#1F4173] text-[20px] cursor-pointer'}>+</span>
              <input
                type="file"
                name={isExternal ? 'userExtraDocument.' : '' + field + '.image'}
                accept=".jpeg, .png, .jpg"
                onChange={(e: any) => addNumberImage(e, field, isExternal)}
                className="absolute h-[100%] w-[100%] opacity-0 left-0 right-0 top-0 bottom-0 cursor-pointer z-10"
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  const updateHorse = async (dataForm: any) => {
    let isValid = true

    for (const val of Object.values(dataForm)) {
      if (!val) {
        isValid = false
        break
      }
    }
    if (!isValid) return toastFunctions.error({ message: 'All fields are required' })
    const updateHorseData = {
      [value]: dataForm[value],
      [cutValue + 'Expiration']: `${dataForm[cutValue + 'Expiration']}`,
    }

    if (!horseData) return toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })

    setLoading(true)

    try {
      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSES.NAME,
        horseData.id,
        updateHorseData
      )

      const updated = myHorses.map((horse) => {
        if (horse.id === horseData?.id) {
          return {
            ...horse,
            [value]: dataForm[value],
            [cutValue + 'Expiration']: `${dataForm[cutValue + 'Expiration']}`,
          }
        } else {
          return horse
        }
      })

      dispatch(setHorses(updated))

      return handleModal(false, MODAL_CONSTS.UPDATE_USER_NUMBER)
    } catch (e) {
      console.log(e)
      return toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    } finally {
      setLoading(false)
    }
  }
  const updateUser = async (dataForm: any) => {
    const obj = dataForm[value]
    if (!obj.documentNumber) {
      return toastFunctions.error({ message: 'Field Number is required' })
    }
    if (!obj.endDate) {
      return toastFunctions.error({ message: 'Field Expires date is required' })
    }
    if (!obj.membershipStatus) {
      return toastFunctions.error({ message: 'Membership Status is required' })
    }
    setLoading(true)
    const updateData = {
      [value]: {
        ...obj,
        documentNumber: obj.documentNumber,
        endDate: `${obj.endDate}`,
        membershipStatus: `${obj.membershipStatus.value}`,
      },
    }

    try {
      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
        userData!.id,
        updateData
      )
      let updatedUserIndex: number = -1
      const updateUsers = users.map((user, index) => {
        if (user.id === userData?.id) {
          updatedUserIndex = index
          return {
            ...user,
            [value]: {
              ...obj,
              documentNumber: obj.documentNumber,
              endDate: `${obj.endDate}`,
              membershipStatus: `${obj.membershipStatus.value}`,
            },
          }
        } else {
          return user
        }
      })

      if (updatedUserIndex >= 0 && profileDetails.id === updateUsers[updatedUserIndex].id)
        dispatch(setProfileDetails(updateUsers[updatedUserIndex]))

      dispatch(setAllUsers(updateUsers))
      return handleModal(false, MODAL_CONSTS.UPDATE_USER_NUMBER)
    } catch (e) {
      console.log('=>(UpdateUserNumberModal.tsx:314) e', e)
      return toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    } finally {
      setLoading(false)
    }
  }
  return (
    <MainModal
      title={`Add ${dataToPassOn.data.title || ''}`}
      show={true}
      setHeightAsPerContent
      type="UPDATE_USER_NUMBER"
      size="4xl"
      titleClassName="!font-normal"
      buttons={[
        {
          label: 'SAVE',
          bgClass: 'bg-SeabiscuitMainThemeColor !w-full',
          textClass: 'text-white !w-full',
          loading,
          onClick: handleSubmit(isHorse ? updateHorse : updateUser),
        },
        {
          label: 'CANCEL',
          bgClass: 'bg-SeabiscuitLightThemeColor',
          className: 'outline-none',
          loading,
          borderClass: 'border border-transparent',
          textClass: 'text-SeabiscuitLightTextColor !w-full',
          onClick: () => handleModal(false, MODAL_CONSTS.UPDATE_USER_NUMBER),
        },
      ]}
    >
      <div className="flex flex-col md:flex-row flex-wrap md:items-center text-black mb-[280px] mt-[30px]">
        <label className="md:w-1/6 flex items-center mr-2">
          <img className="mr-2" src="/assets/cp_icons/Hierarchy-1.svg" alt="hierarchy" />
          {dataToPassOn.data.title || ''}
        </label>
        <div className="flex flex-col md:flex-row md:items-center md:flex-1">
          <div className="w-full md:flex-1 mt-3 md:mt-0">
            <input
              type="number"
              defaultValue={
                isHorse
                  ? (dataToPassOn.horseData?.[
                      dataToPassOn.data.value as keyof IHorseData
                    ] as string)
                  : (dataToPassOn.userData?.[dataToPassOn.data.value as keyof UserModel]
                      ?.documentNumber as string)
              }
              placeholder="Enter number..."
              disabled={false}
              {...register(isHorse ? value : value + '.documentNumber')}
              onWheel={(e) => (e.target as HTMLElement).blur()}
              className={`w-full md:w-[96%] border border-solid rounded-xl !ring-0 p-3.5 mr-2 h-[51px] !border-[#D3DAEE]}`}
            />
          </div>

          <div
            className={` mt-3 md:mt-0 usdfDate relative flex items-center md:w-[50%] border border-[#D3DAEE] rounded-xl modalRegistrationUpdates`}
          >
            <Controller
              name={isHorse ? cutValue + 'Expiration' : value + '.endDate'}
              defaultValue={dataToPassOn.data.expiredDate}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <div className="pl-4 text-[14px]">Expires:</div>
                  <CustomDatePicker
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={getReactPickerDate(value)}
                    className={`rounded-xl w-[100%] relative z-50 border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-5 border-white h-[51px]`}
                  />
                </>
              )}
            />
            <KeyboardArrowDownIcon
              fontSize="medium"
              className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
            />
          </div>
        </div>
        {!isHorse && (
          <div className={'mt-3 md:mt-0 md:ml-3 w-full md:w-auto md:min-w-[240px]'}>
            <CustomController
              defaultValue={
                dataToPassOn.userData?.[dataToPassOn.data.value as keyof UserModel]
                  ?.membershipStatus as string
              }
              placeholder={'Status'}
              name={`${value}.membershipStatus`}
              control={control}
              saved={false}
            />
          </div>
        )}

        {renderImageNumberInput(value)}
      </div>
    </MainModal>
  )
}
