import moment from 'moment'
import { Link } from 'react-router-dom'
import { CONST } from '../../../const/const'
import { MODAL_CONSTS } from '../../../const/modal-const'
import {
  IEventRegisteredUserInterfaceTypes,
  IGetMyEventsFilteredDataItem,
} from '../../../models/event-registered-users/event-registered-users.interface'
import { IconDelete2 } from '../../../components/icons/IconDelete2'
import PROFILE_DETAIL_CARD_CONST from '../../../components/pageWise/visitedUserDetails/profile-details-card/profileDetailCard.const'

type Props = {
  handleModal: (show: boolean, modal_name: string, data?: any) => void
  selectedTab: ISelectedTabUS
  userId?: string
  removeHandler?: (row: IGetMyEventsFilteredDataItem) => void
}
type ISelectedTabUS = { tabTitle: string; tab: number; subTitle: string }

const getEventCols = ({ handleModal, selectedTab, userId }: Props) => {
  return [
    {
      draggable: false,
      name: 'Event',
      cell: (row: IGetMyEventsFilteredDataItem) => {
        return (
          <div className="flex py-5 items-center">
            <span className="w-[45px] h-[45px] block shrink-0 mr-4">
              <Link to={`/events-details/${row.event?.id}`}>
                <img
                  src={
                    !row.owner?.userProfilePicture
                      ? PROFILE_DETAIL_CARD_CONST.DEFAULT_USER_PIC
                      : row.owner?.userProfilePicture
                  }
                  className="object-cover w-full h-full rounded-full"
                  onError={(e) => {
                    e.currentTarget.src = PROFILE_DETAIL_CARD_CONST.DEFAULT_USER_PIC
                  }}
                  alt="icon"
                />
              </Link>
            </span>
            <span className="text-[14px] text-SeabiscuitDark200ThemeColor font-bold">
              <Link to={`/events-details/${row.event?.id}`}>{row.event?.eventName}</Link>
            </span>
          </div>
        )
      },
      width: '30%',
    },
    {
      name: 'Discipline',
      draggable: false,
      cell: (row: IGetMyEventsFilteredDataItem) => (
        <>
          <img src={'assets/img/allevent/Horsessign.svg'} alt="icon" />
          {row.event?.tags && row.event?.tags.length ? (
            <p className="text-SeabiscuitDark200ThemeColor block ml-2 capitalize font-normal">
              {row.event?.tags[0]}
            </p>
          ) : (
            <p className="text-SeabiscuitDark200ThemeColor block ml-2 capitalize font-normal">
              N/A
            </p>
          )}
        </>
      ),
      width: '12%',
    },
    {
      name: 'Event type',
      draggable: false,
      cell: (row: IGetMyEventsFilteredDataItem) => (
        <>
          <img src={'assets/img/allevent/Laurelwreath.svg'} alt="icon" />
          <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal whitespace-nowrap">
            {row.event?.category}
          </p>
        </>
      ),
      width: '12%',
    },
    {
      name: 'Location',
      draggable: false,
      cell: (row: IGetMyEventsFilteredDataItem) => (
        <>
          <img src={'assets/img/allevent/Location.svg'} alt="icon" />
          <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
            {row.event?.state}
          </p>
        </>
      ),
      width: '12%',
    },
    {
      name: 'Date',
      draggable: false,
      cell: (row: IGetMyEventsFilteredDataItem) => (
        <>
          <img src={'assets/img/allevent/Calendar.svg'} alt="icon" />
          <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
            {row.event?.eventStartDate && row.event?.eventStartDate !== 'N/A'
              ? moment(row.event?.eventStartDate).format('MMM DD')
              : 'N/A'}
          </p>
        </>
      ),
      width: '12%',
    },
    {
      name: 'Going',
      draggable: false,
      cell: (row: IGetMyEventsFilteredDataItem) => {
        return (
          <div
            onClick={() => {
              handleModal(true, MODAL_CONSTS.REGISTER_EXHIBITORS, {
                id: selectedTab,
                eventClicked: { eventId: row.event?.id ?? '' },
              })
            }}
            className="group flex items-center gap-2 cursor-pointer capitalize"
          >
            <img src={'assets/img/allevent/Testpassed.svg'} alt="icon" />
            {row.event?.status === 'cancel' ? (
              <p className="text-[#FC2804] underline group-hover:no-underline">Cancelled</p>
            ) : (
              <p className="text-SeabiscuitDark200ThemeColor underline group-hover:no-underline">
                {row.riders ? row.riders?.length : 0} registered
              </p>
            )}
          </div>
        )
      },
      width: '13%',
    },
    {
      name: '',
      draggable: false,
      cell: (row: IGetMyEventsFilteredDataItem) => {
        return (
          <Link
            to={`${CONST.ROUTES.COMPETITOR_REGISTERED_EVENT.URL}/${userId}/${row.event?.id}/${row.registration?.id}`}
            aria-disabled={!row.event?.id || !userId}
            className="whitespace-nowrap min-w-[78px] text-center mr-[-8px] w-[102px] h-[35px] flex items-center py-2 !ring-0 border border-transparent rounded-lg shadow-sm text-sm font-medium text-SeabiscuitDark200ThemeColor bg-[#F6F7FB] hover:text-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 hover:bg-SeabiscuitMainThemeColor/5 focus:ring-SeabiscuitMainThemeColor justify-center"
          >
            Manage
          </Link>
        )
      },
      width: '7%',
    },
  ]
}

const getDraftCols = ({ handleModal, selectedTab, userId, removeHandler }: Props) => {
  return [
    {
      draggable: false,
      name: 'Event',
      cell: (row: IGetMyEventsFilteredDataItem) => {
        return (
          <div className="flex items-center py-5">
            <span className="w-[45px] h-[45px] block shrink-0 mr-4">
              <Link to={`/events-details/${row.event?.id}`}>
                <img
                  src={
                    !row.owner?.userProfilePicture
                      ? PROFILE_DETAIL_CARD_CONST.DEFAULT_USER_PIC
                      : row.owner?.userProfilePicture
                  }
                  className="object-cover w-full h-full rounded-full"
                  onError={(e) => {
                    e.currentTarget.src = PROFILE_DETAIL_CARD_CONST.DEFAULT_USER_PIC
                  }}
                  alt="icon"
                />
              </Link>
            </span>
            <span className="text-[14px] text-SeabiscuitDark200ThemeColor font-bold">
              <Link to={`/events-details/${row.event?.id}`}>{row.event?.eventName}</Link>
            </span>
          </div>
        )
      },
      width: '27%',
    },
    {
      name: 'Discipline',
      draggable: false,
      cell: (row: IGetMyEventsFilteredDataItem) => (
        <>
          <img src={'assets/img/allevent/Horsessign.svg'} alt="icon" />
          {row.event?.tags && row.event?.tags.length ? (
            <p className="text-SeabiscuitDark200ThemeColor block ml-2 capitalize font-normal">
              {row.event?.tags[0]}
            </p>
          ) : (
            <p className="text-SeabiscuitDark200ThemeColor block ml-2 capitalize font-normal">
              N/A
            </p>
          )}
        </>
      ),
      width: '12%',
    },
    {
      name: 'Event type',
      draggable: false,
      cell: (row: IGetMyEventsFilteredDataItem) => (
        <>
          <img src={'assets/img/allevent/Laurelwreath.svg'} alt="icon" />
          <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
            {row.event?.category}
          </p>
        </>
      ),
      width: '10%',
    },
    {
      name: 'Location',
      draggable: false,
      cell: (row: IGetMyEventsFilteredDataItem) => (
        <>
          <img src={'assets/img/allevent/Location.svg'} alt="icon" />
          <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
            {row.event?.state}
          </p>
        </>
      ),
      width: '12%',
    },
    {
      name: 'Date',
      draggable: false,
      cell: (row: IGetMyEventsFilteredDataItem) => (
        <>
          <img src={'assets/img/allevent/Calendar.svg'} alt="icon" />
          <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
            {row.event?.eventStartDate && row.event?.eventStartDate !== 'N/A'
              ? moment(row.event?.eventStartDate).format('MMM DD')
              : 'N/A'}
          </p>
        </>
      ),
      width: '10%',
    },
    {
      name: 'Going',
      draggable: false,
      cell: (row: IGetMyEventsFilteredDataItem) => {
        const registrationLimit = row.EventFees?.registrationLimit
        const spotsRemaining = registrationLimit?.isEnable
          ? (registrationLimit?.spots ?? 0) - row.riders?.length
          : 0
        return (
          <div
            onClick={() => {
              handleModal(true, MODAL_CONSTS.REGISTER_EXHIBITORS, {
                id: selectedTab,
                eventClicked: { eventId: row.event?.id ?? '' },
              })
            }}
            className="flex items-center gap-2 cursor-pointer capitalize"
          >
            <img src={'assets/img/allevent/Testpassed.svg'} alt="icon" />
            {row.event?.status === 'cancel' ? (
              <p className="text-[#FC2804] underline group-hover:no-underline">Cancelled</p>
            ) : (
              <p className="text-SeabiscuitDark200ThemeColor underline group-hover:no-underline">
                {spotsRemaining > 0 ? spotsRemaining : 'N/A'} spots remaining
              </p>
            )}
          </div>
        )
      },
      width: '17%',
    },
    {
      name: '',
      draggable: false,
      cell: (row: IGetMyEventsFilteredDataItem) => {
        return (
          <Link
            to={`${CONST.ROUTES.MANAGE_REGISTER_EVENT.URL}/${row.event?.id}/${userId}`}
            aria-disabled={!row.event?.id || !userId}
            className="text-center mr-[-8px] px-2 whitespace-nowrap h-[35px] flex items-center py-2 !ring-0 border border-transparent rounded-lg shadow-sm text-sm font-medium text-SeabiscuitDark200ThemeColor bg-[#F6F7FB] hover:text-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 hover:bg-SeabiscuitMainThemeColor/5 focus:ring-SeabiscuitMainThemeColor justify-center"
          >
            Resume {'>'}
          </Link>
        )
      },
      width: '110px',
    },
    {
      name: '',
      draggable: false,
      cell: (row: IGetMyEventsFilteredDataItem) => {
        return (
          <div className={'flex justify-end w-full  md:relative'}>
            <button
              type={'button'}
              onClick={async () => {
                try {
                  removeHandler && removeHandler(row)
                } catch (e) {}
              }}
            >
              <IconDelete2 />
            </button>
          </div>
        )
      },
      width: '5%',
    },
  ]
}

const getBookmarkedDtCols = () => {
  return [
    {
      name: 'Event',
      draggable: false,
      cell: (row: IGetMyEventsFilteredDataItem) => (
        <div className="flex items-center py-5">
          <span className="w-[45px] h-[45px] block shrink-0 mr-4">
            <Link to={`/events-details/${row.event?.id}`}>
              <img
                src={
                  !row.owner?.userProfilePicture
                    ? PROFILE_DETAIL_CARD_CONST.DEFAULT_USER_PIC
                    : row.owner?.userProfilePicture
                }
                className="object-cover w-full h-full rounded-full"
                alt="icon"
              />
            </Link>
          </span>
          <span className="text-[14px] text-SeabiscuitDark200ThemeColor font-bold">
            {row.event?.eventName}
          </span>
        </div>
      ),
      width: '30%',
    },
    {
      name: 'Discipline',
      draggable: false,
      cell: (row: IGetMyEventsFilteredDataItem) => (
        <>
          <img src={'assets/img/allevent/Horsessign.svg'} alt="icon" />
          {row.event?.tags && row.event?.tags.length ? (
            <p className="text-SeabiscuitDark200ThemeColor block ml-2 capitalize font-normal">
              {row.event?.tags[0]}
            </p>
          ) : (
            <p className="text-SeabiscuitDark200ThemeColor block ml-2 capitalize font-normal">
              N/A
            </p>
          )}
        </>
      ),
      width: '12%',
    },
    {
      name: 'Event type',
      draggable: false,
      cell: (row: IGetMyEventsFilteredDataItem) => (
        <>
          <img src={'assets/img/allevent/Laurelwreath.svg'} alt="icon" />
          <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
            {row.event?.category}
          </p>
        </>
      ),
      width: '12%',
    },
    {
      name: 'Location',
      draggable: false,
      cell: (row: IGetMyEventsFilteredDataItem) => (
        <>
          <img src={'assets/img/allevent/Location.svg'} alt="icon" />
          <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
            {row.event?.state}
          </p>
        </>
      ),
      width: '12%',
    },
    {
      name: 'Date',
      draggable: false,
      cell: (row: IGetMyEventsFilteredDataItem) => (
        <>
          <img src={'assets/img/allevent/Calendar.svg'} alt="icon" />
          <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
            {row.event?.eventStartDate ? moment(row.event?.eventStartDate).format('MMM DD') : 'N/A'}
          </p>
        </>
      ),
      width: '12%',
    },
    {
      name: 'Remaining spots',
      draggable: false,
      cell: (row: IGetMyEventsFilteredDataItem) => {
        const registrationLimit = row.EventFees?.registrationLimit
        const spotsRemaining = registrationLimit?.isEnable
          ? (registrationLimit?.spots ?? 0) - row.riders?.length
          : 0

        return (
          <>
            <img src={'assets/img/allevent/Calendar.svg'} alt="icon" />
            <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
              {spotsRemaining > 0 ? spotsRemaining : 'N/A'}
            </p>
          </>
        )
      },
      width: '12%',
    },
    {
      name: 'View Event',
      draggable: false,
      cell: (row: IGetMyEventsFilteredDataItem) => (
        <>
          <Link
            to={`/events-details/${row.event?.id}`}
            className="text-center mr-[-8px] w-[80px] h-[35px] flex items-center py-2 !ring-0 border border-transparent rounded-lg shadow-sm text-sm font-medium text-SeabiscuitDark200ThemeColor bg-[#F6F7FB] hover:text-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 hover:bg-SeabiscuitMainThemeColor/5 focus:ring-SeabiscuitMainThemeColor justify-center"
          >
            More &gt;
          </Link>
        </>
      ),
      width: '10%',
    },
  ]
}

const getTicketsCols = (props: Props) => {
  return [
    {
      name: 'Event',
      draggable: false,
      cell: (row: IEventRegisteredUserInterfaceTypes['ITicketBoughtItem']) => (
        <div className="flex items-center py-5">
          <span className="w-[45px] h-[45px] block shrink-0 mr-4">
            <Link to={`/events-details/${row.registration?.eventId}`}>
              <img
                src={
                  !row.registration?.eventLogo || row.registration?.eventLogo === ''
                    ? PROFILE_DETAIL_CARD_CONST.DEFAULT_USER_PIC
                    : row.registration?.eventLogo
                }
                className="object-cover w-full h-full rounded-full"
                onError={(e) => {
                  e.currentTarget.src = PROFILE_DETAIL_CARD_CONST.DEFAULT_USER_PIC
                }}
                alt="icon"
              />
            </Link>
          </span>
          <span className="text-[14px] text-[#122B46] font-bold capitalize">
            {row.registration?.eventName}
          </span>
        </div>
      ),
      width: '33%',
    },
    {
      name: 'Discipline',
      draggable: false,
      cell: (row: IEventRegisteredUserInterfaceTypes['ITicketBoughtItem']) => (
        <>
          <img src={'assets/img/allevent/Horsessign.svg'} alt="icon" />
          {row.registration?.discipline && row.registration?.discipline.length ? (
            <p className="text-SeabiscuitDark200ThemeColor block ml-2 capitalize font-normal">
              {row.registration?.discipline[0]}
            </p>
          ) : (
            <p className="text-SeabiscuitDark200ThemeColor block ml-2 capitalize font-normal">
              N/A
            </p>
          )}
        </>
      ),
      width: '12%',
    },
    {
      name: 'Event type',
      draggable: false,
      cell: (row: IEventRegisteredUserInterfaceTypes['ITicketBoughtItem']) => (
        <>
          <img src={'assets/img/allevent/Laurelwreath.svg'} alt="icon" />
          <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
            {row.registration?.category}
          </p>
        </>
      ),
      width: '12%',
    },
    {
      name: 'Location',
      draggable: false,
      cell: (row: IEventRegisteredUserInterfaceTypes['ITicketBoughtItem']) => (
        <>
          <img src={'assets/img/allevent/Location.svg'} alt="icon" />
          <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
            {row.registration?.eventState}
          </p>
        </>
      ),
      width: '12%',
    },
    {
      name: 'Date',
      draggable: false,
      cell: (row: any) => (
        <>
          <img src={'assets/img/allevent/Calendar.svg'} alt="icon" />
          <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
            {row.eventDetails?.registrationOpenDate
              ? moment(row.registration?.registrationOpenDate).format('MMM DD')
              : 'N/A'}
          </p>
        </>
      ),
      width: '12%',
    },
    {
      name: 'Purchased',
      draggable: false,
      cell: (row: any, index: number) => (
        <>
          <img src={'assets/og_icons/Two Tickets.svg'} alt="icon" />
          <p
            className="text-SeabiscuitDark200ThemeColor ml-2 font-normal underline cursor-pointer"
            onClick={() => {
              props.handleModal(true, MODAL_CONSTS.TICKETS_DISTRIBUTER_MODAL, {
                ...row,
                tableRowIndex: index,
              })
            }}
          >
            {row.registration?.ticket_Buyers?.ticketBoughtCount
              ? `${row.registration?.ticket_Buyers?.ticketBoughtCount} tickets`
              : 'N/A'}
          </p>
        </>
      ),
      width: '12%',
    },
    {
      name: 'View Event',
      draggable: false,
      cell: (row: IEventRegisteredUserInterfaceTypes['ITicketBoughtItem']) => (
        <>
          <Link
            to={`/events-details/${row.registration?.eventId}`}
            className="text-center mr-[-8px] w-[80px] h-[35px] flex items-center py-2 !ring-0 border border-transparent rounded-lg shadow-sm text-sm font-medium text-SeabiscuitDark200ThemeColor bg-[#F6F7FB] hover:text-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 hover:bg-SeabiscuitMainThemeColor/5 focus:ring-SeabiscuitMainThemeColor justify-center"
          >
            More &gt;
          </Link>
        </>
      ),
      width: '7%',
    },
  ]
}

const getAssignedTicketsCols = (props: Props) => {
  return [
    {
      name: 'Event Name',
      cell: (row: IEventRegisteredUserInterfaceTypes['ITicketBoughtItem']) => {
        let cell_data = row ? row.eventDetails?.eventCardCoverImageUrl : ''
        return (
          <div className="flex items-center py-5">
            <span className="w-[45px] h-[45px] block shrink-0 mr-4">
              <Link to={`/events-details/${row.eventId}`}>
                <img
                  src={
                    !cell_data || cell_data === ''
                      ? PROFILE_DETAIL_CARD_CONST.DEFAULT_USER_PIC
                      : cell_data
                  }
                  className="object-cover w-full h-full rounded-full"
                  onError={(e) => {
                    e.currentTarget.src = PROFILE_DETAIL_CARD_CONST.DEFAULT_USER_PIC
                  }}
                  alt="icon"
                />
              </Link>
            </span>
            <span className="text-[14px] text-[#122B46] font-bold capitalize">
              {row.eventDetails?.eventName}
            </span>
          </div>
        )
      },
      draggable: false,
      width: '250px',
    },
    {
      name: 'Discipline',
      draggable: false,
      cell: (row: IEventRegisteredUserInterfaceTypes['ITicketBoughtItem']) => (
        <>
          <img src={'assets/img/allevent/Horsessign.svg'} alt="icon" />
          {row.registration?.discipline && row.registration?.discipline.length ? (
            <p className="text-SeabiscuitDark200ThemeColor block ml-2 capitalize font-normal">
              {row.registration?.discipline[0]}
            </p>
          ) : (
            <p className="text-SeabiscuitDark200ThemeColor block ml-2 capitalize font-normal">
              N/A
            </p>
          )}
        </>
      ),
    },
    {
      name: 'Location',
      draggable: false,
      cell: (row: IEventRegisteredUserInterfaceTypes['ITicketBoughtItem']) => (
        <>
          <img src={'assets/img/allevent/Location.svg'} alt="icon" />
          <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
            {row.eventDetails?.state}
          </p>
        </>
      ),
    },
    {
      name: 'Sender Name',
      draggable: false,
      cell: (row: IEventRegisteredUserInterfaceTypes['ITicketBoughtItem']) => (
        <>
          <img src={'assets/img/allevent/Tags.svg'} alt="icon" />
          <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
            {row.registration?.userName}
          </p>
        </>
      ),
    },
    {
      name: 'Date',
      draggable: false,
      cell: (row: IEventRegisteredUserInterfaceTypes['ITicketBoughtItem']) => (
        <>
          <img src={'assets/img/allevent/Calendar.svg'} alt="icon" />
          <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
            {row.eventDetails?.registrationOpenDate
              ? moment(row.eventDetails?.registrationOpenDate).format('MMM DD')
              : 'N/A'}
          </p>
        </>
      ),
    },
    {
      name: 'Purchased',
      draggable: false,
      cell: (row: IEventRegisteredUserInterfaceTypes['ITicketBoughtItem']) => {
        return (
          <>
            <img src={'assets/og_icons/Two Tickets.svg'} alt="icon" />
            <p
              className="text-SeabiscuitDark200ThemeColor ml-2 font-normal underline cursor-pointer"
              onClick={() => {
                props.handleModal(true, MODAL_CONSTS.TICKETS_DISTRIBUTER_MODAL, row)
              }}
            >
              {row.remainingTicketsCount ? `${row.remainingTicketsCount} tickets` : 'N/A'}
            </p>
          </>
        )
      },
    },
    {
      name: '',
      draggable: false,
      cell: (row: IEventRegisteredUserInterfaceTypes['ITicketBoughtItem']) => (
        <>
          <Link
            to={`/events-details/${row.eventId}`}
            className="text-center w-[100px] h-[45px] mx-auto mt-3 flex items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-SeabiscuitDark200ThemeColor bg-SeabiscuitLightThemeColor hover:text-white hover:bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
          >
            More
          </Link>
        </>
      ),
    },
  ]
}

const getOrganizerPaymentTransactions = () => {
  return [
    {
      name: 'Amount',
      cell: (row: any) => {
        return (
          <div className="text-SeabiscuitDark200ThemeColor text-center flex items-center">
            <img src="/assets/og_icons/Money Bag-2.svg" className="mr-4 w-6" alt="moneyBag" />
            <span className="mr-2">${row.amount / 100}</span> transfered
          </div>
        )
      },
      draggable: false,
      width: '300px',
    },
    {
      name: 'Paid on',
      cell: (row: any) => {
        const date = new Date(row.created * 1000)

        const day = date.getDate()
        const dayWithSuffix =
          day +
          (day % 10 === 1 && day !== 11
            ? 'st'
            : day % 10 === 2 && day !== 12
              ? 'nd'
              : day % 10 === 3 && day !== 13
                ? 'rd'
                : 'th')

        const formattedDateWithSuffix =
          date.toLocaleString('en-US', { month: 'long' }) +
          ' ' +
          dayWithSuffix +
          ', ' +
          date.getFullYear()

        return (
          <div className="text-SeabiscuitDark200ThemeColor text-center flex items-center">
            <img src="/assets/og_icons/Tear-OffCalendar.svg" className="mr-4 w-6" alt="calender" />
            {formattedDateWithSuffix}
          </div>
        )
      },
      draggable: false,
      width: '300px',
    },
    {
      name: 'Account Number',
      cell: (row: any) => {
        return (
          <div className="text-SeabiscuitDark200ThemeColor flex items-center">
            <img src="/assets/og_icons/Museum2.svg" className="mr-4 w-6" alt="moneyBag" />
            Account ending in: ****{row.accountNumber}
          </div>
        )
      },
      draggable: false,
      width: '340px',
    },
    {
      name: 'Transaction Id',
      cell: (row: any) => {
        return (
          <div className="text-SeabiscuitDark200ThemeColor flex items-center">
            <img src="/assets/og_icons/Fingerprint.svg" className="mr-4 w-6" alt="moneyBag" />
            {row.statement_descriptor}
          </div>
        )
      },
      draggable: false,
      width: '420px',
    },
  ]
}

export {
  getAssignedTicketsCols,
  getBookmarkedDtCols,
  getEventCols,
  getDraftCols,
  getOrganizerPaymentTransactions,
  getTicketsCols,
}
