export const SheetFEIJuniorsPreliminary = {
  id: 'SheetFEIJuniorsPreliminary',
  name: 'FEI Juniors Preliminary',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 280,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter in collected canter</p><p><span>X •</span> Halt - immobility - salute. Proceed in collected trot.</p><p><span>XC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track to the left</p><p><span>S •</span> Volte left (10 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p><span>SV •</span> Shoulder-in left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>VL •</span> Half volte left (10 m Ø)</p><p><span>LS •</span> Half pass to the left</p><p><span>H •</span> Turn right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>M •</span> M turn right</p><p><span>R •</span> Volte right (10 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description: '<p><span>RP •</span> Shoulder-in right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>PL •</span> Half volte right (10 m Ø)</p><p><span>LR •</span> Half pass to the right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>RMCH •</span> Collected trot</p><p><span>HP •</span> Medium trot</p><p><span>P •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>N/A •</span> Transitions at H and P</p><p><span>PF •</span> The collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description: '<p><span>F •</span> Medium walk</p><p><span>FAK •</span> Medium walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p><span>KR •</span> Extended walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>R •</span> Collected walk</p><p><span>RMC •</span> Collected walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>C •</span> Proceed in collected canter left</p><p><span>CS •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p><span>SK •</span> Medium canter</p><p><span>K •</span> Collected canter</p><p><span>KA •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>DE •</span> Half-pass to the left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description: '<p><span>ESHC •</span> Counter canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description: '<p><span>C •</span> Simple change of leg</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>MF •</span> Extended canter</p><p><span>F •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>N/A •</span> Transitions at M and F</p><p><span>FA •</span> The collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>DB •</span> Half-pass to the right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description: '<p><span>BRMC •</span> Counter canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description: '<p><span>C •</span> Simple change of leg</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description: '<p><span>HB •</span> Collected canter with flying change of leg at I</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 24,
          description: '<p><span>BK •</span> Collected canter with flying change of leg at L</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 25,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>X •</span> Halt - immobility - salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Collective Marks',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>General Impression</h5>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}

export const SheetFEIJuniorsTeam = {
  id: 'SheetFEIJuniorsTeam',
  name: 'FEI Juniors Team',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 310,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter in collected canter</p><p><span>X •</span> Halt - immobility - salute. Proceed in collected trot.</p><p><span>XC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track to the right</p><p><span>R •</span> Volte right (10 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p><span>RP •</span> Shoulder-in right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>PL •</span> Half volte right (10 m Ø)</p><p><span>LR •</span> Half pass to the right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>M •</span> Turn left</p><p><span>G •</span> Halt - immobility; Rein back 4 steps and immediately proceed in collected trot</p><p><span>H •</span> Turn left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>SF •</span> Medium trot</p><p><span>F •</span> Collected trot</p><p><span>FA •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description: '<p><span>A •</span> Medium walk</p><p><span>AKV •</span> Medium walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description: '<p><span>VR •</span> Extended walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>RMG •</span> [Collected walk]</p><p><span>Between G &amp; H •</span> Half pirouette to the left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description: '<p><span>Between G &amp; M •</span> Half pirouette to the right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p><span>N/A •</span> The collected walk R-M-G-(H)-G-(M)-G</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>G •</span> Proceed in collected trot</p><p><span>H •</span> Turn left</p><p><span>S •</span> Volte left (10 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description: '<p><span>SV •</span> Shoulder-in left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p><span>VL •</span> Half volte left (10 m Ø)</p><p><span>LS •</span> Half pass to the left</p><p><span>SHCM •</span> Collected trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>MXK •</span> Extended trot</p><p><span>K •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>N/A •</span> Transitions at M and K</p><p><span>KA •</span> The collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description: '<p><span>A •</span> Proceed in collected canter left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>AFP •</span> [Collected canter]</p><p><span>P •</span> [Turn left]</p><p><span>L •</span> Flying change of leg</p><p><span>V •</span> [Turn right]</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 19,
          description: '<p><span>N/A •</span> The collected canter A-F-P-V-E</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>EG •</span> Half pass to the right</p><p><span>C •</span> Track to the right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description: '<p><span>ME •</span> Collected canter with flying change of leg at I</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description: '<p><span>EF •</span> Collected canter with flying change of leg at L</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description:
            '<p><span>AKV •</span> [Collected canter]</p><p><span>V •</span> [Turn right]</p><p><span>L •</span> Flying change of leg</p><p><span>P •</span> [Turn left]</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 24,
          description: '<p><span>N/A •</span> The collected canter A-K-V-P-B</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 25,
          description:
            '<p><span>BG •</span> Half pass to the left</p><p><span>C •</span> Track to the left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 26,
          description:
            '<p><span>HK •</span> Extended canter</p><p><span>K •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 27,
          description:
            '<p><span>N/A •</span> Transitions at H and K</p><p><span>KA •</span> The collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 28,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>X •</span> Halt - immobility - salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Collective Marks',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>General Impression</h5>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}

export const SheetFEIJuniorsIndividual = {
  id: 'SheetFEIJuniorsIndividual',
  name: 'FEI Juniors Individual',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 320,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>AX •</span> Enter in collected canter</p><p><span>X •</span> Halt - immobility - salute. Proceed in collected trot.</p><p><span>XC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track to the left</p><p><span>HXF •</span> Medium trot</p><p><span>F •</span> Collected trot</p><p><span>FAK •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p><span>KE •</span> Shoulder-in right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>EX •</span> Half volte right (10 m Ø)</p><p><span>XB •</span> Half volte left (10 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>BG •</span> Half pass to the left</p><p><span>G •</span> On centre line</p><p><span>C •</span> Track to the right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>RS •</span> Half circle right (20 m Ø). Let the horse stretch on a long rein.</p><p><span>SHCM •</span> Collected trot</p><p><span>Between S &amp; H •</span> Retake the reins</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>MXK •</span> Extended trot</p><p><span>K •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>N/A •</span> Transitions at M and K</p><p><span>KAF •</span> The collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>FB •</span> Shoulder-in left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>BX •</span> Half volte left (10 m Ø)</p><p><span>XE •</span> Half volte right (10 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description:
            '<p><span>EG •</span> Half pass to the right</p><p><span>G •</span> On centre line</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>Before C •</span> [Collected walk]</p><p><span>C •</span> [Track to the left]</p><p><span>H •</span> [Turn left]</p><p><span>G •</span> Half pirouette to the left</p><p><span>GH •</span> [Collected walk]</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description: '<p><span>N/A •</span> The collected walk C-H-G-H</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description: '<p><span>HB •</span> Extended walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>B •</span> Collected walk</p><p><span>BPL •</span> Collected walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>L •</span> Half pirouette to the right</p><p><span>L(P) •</span> Collected walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>Before P •</span> Proceed in collected canter right</p><p><span>PFAK •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>KX •</span> Half pass to the right</p><p><span>X •</span> Down the centre line</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>I •</span> Flying change of leg</p><p><span>C •</span> Track to the left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>HP •</span> Medium canter</p><p><span>P •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description: '<p><span>PF •</span> Counter canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description: '<p><span>F •</span> Flying change of leg</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description:
            '<p><span>FD •</span> Half volte right (10 m Ø)</p><p><span>D •</span> Down the centre line</p><p><span>Before D &amp; L •</span> Simple change of leg</p><p><span>LV •</span> Half volte left (10 m Ø)</p><p><span>VKAF •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 24,
          description:
            '<p><span>FX •</span> Half pass to the left</p><p><span>X •</span> Down the centre line</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 25,
          description:
            '<p><span>I •</span> Flying change of leg</p><p><span>C •</span> Track to the right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 26,
          description:
            '<p><span>MV •</span> Extended canter</p><p><span>V •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 27,
          description: '<p><span>VK •</span> Counter canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 28,
          description:
            '<p><span>K •</span> Flying change of leg</p><p><span>KA •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 29,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>X •</span> Halt - immobility - salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Collective Marks',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>General Impression</h5>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}
