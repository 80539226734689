import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { where } from 'firebase/firestore'

import { TableBtn } from '../components/TableBtn'
import { TableName } from '../components/TableName'
import { HandleModalContext } from '../../../../../layout/mainlayout/MainLayout'
import useHorse from '../../common/table/HorsesManage/hooks/useHorse'
import { BackNumber } from '../components/BackNumber'

import FirestoreService from '../../../../../services/firestoreService'

import helpers from '../../../../../commonHelpers/helpers'
import useToasterHelper from '../../../../../helpers/ToasterHelper'
import { CustomError, generateName, getUserFullName } from '../../../../../helpers/helpers'

import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { organizerSliceAcs } from '../../../../../store/organizer/organizerSlice'
import { selectedEvent } from '../../../../../store/events/eventsSlice'

import { getConvertedData } from '../../../../../models/interface.helper'
import { RiderTeamMemberModel } from '../../../../../models/rider-team-member/riderTeamMember.model'
import { IRiderTeamMemberInterface } from '../../../../../models/rider-team-member/riderTeamMember.interface'
import { RecipientModel } from '../../../../../models/recipients/recipients'

import { IRegisteredUser, IRider } from '../../../../../types/entryRider'

import { MESSAGES_CONST } from '../../../../../const/messages-const'
import { CONST } from '../../../../../const/const'
import { MODAL_CONSTS } from '../../../../../const/modal-const'
import { IconSwitch } from '../../../../../components/icons/IconSwitch'
import { UserDocumentModel } from '../../../../../models/user-documents/user-documents.model'
import { IHorseData } from '../../../../../models/horse/horse.interface'
import { EventRegisteredUsersModel } from '../../../../../models/event-registered-users/event-registered-users.model'
import { selectAllUsers } from '../../../../../store/users/usersSlice'
import { useEventTrainers } from '../../../../../hooks/useEventTrainers'
import { Tooltip } from '@mui/material'
import { getRiders } from '../../../../../helpers/riders'

const UNKNOWN = 'Unknown'
const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
const PAYMENT_STATUSES = COLLECTIONS.RECIPIENT.FIELDS.PAYMENT_STATUS.VALUES

export enum ETabId {
  entries,
  spectators,
  horses,
  draft,
  // checkIn,
}

interface ITab {
  tab: string
  id: ETabId
  disabled?: boolean
}
export const MANAGE_CLINIC_TABS: ITab[] = [
  { tab: 'Entries', id: ETabId.entries },
  { tab: 'Spectators', id: ETabId.spectators, disabled: true },
  { tab: 'Horses', id: ETabId.horses, disabled: true },
  // { tab: 'Check In', id: ETabId.checkIn, disabled: true },
]

export const ALL_CLASSES = { value: 'all', label: 'All Classes' }

export enum EStatuses {
  all = 'All',
  good = 'Good',
  bad = 'Bad',
  scratched = 'Scratched',
  draft = 'Draft',
}
export const ALL_STATUSES = { value: EStatuses.all, label: EStatuses.all }

enum ESorts {
  none,
  rider,
  horse,
  back,
}
export const useManageClinicNOtherAdminTabNew = (eventId: string) => {
  const { horses, loading: horsesLoading, allHorses } = useHorse(eventId)
  const { eventTrainers } = useEventTrainers(eventId)

  const toastFunction = useToasterHelper()

  const handleModalContext = useContext(HandleModalContext)

  const { EventFees } = useAppSelector(selectedEvent)
  const users = useAppSelector(selectAllUsers)

  const [ridersClasses, setRidersClasses] = useState<(typeof ALL_CLASSES)[]>([])
  const [activeTab, setActiveTab] = useState<ETabId>(ETabId.entries)
  const [riders, setRiders] = useState<IRider[]>([])
  const [frozenRiders, setFrozenRiders] = useState<IRider[]>([])
  const [activeClass, setActiveClass] = useState({ value: '', label: '' })
  const [activeStatus, setActiveStatus] = useState<{ value: string; label: string }>(ALL_STATUSES)
  const [registeredUsers, setRegisteredUsers] = useState<IRegisteredUser[]>([])
  const [draftRiders, setDraftRiders] = useState<IRider[]>([])
  const [frozenDraftRiders, setFrozenDraftRiders] = useState<IRider[]>([])
  const [searchNameValue, setSearchNameValue] = useState('')
  const [loading, setLoading] = useState(true)
  const [activeSortType, setActiveSortType] = useState<ESorts>(ESorts.none)

  const sortRidersByScratched = (allRiders: IRider[]): IRider[] => {
    return allRiders.sort((a, b) => {
      if (a.isScratched === b.isScratched) return 0
      return a.isScratched ? 1 : -1
    })
  }

  const onViewReceiptClick = async (
    recipientId: number | string | null | undefined,
    registrationId: number | string | null | undefined
  ) => {
    if (!recipientId || !registrationId) {
      return toastFunction.info({ message: MESSAGES_CONST.NO_RECIPIENT })
    }

    try {
      const docSnapshot = await FirestoreService.filterItems(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.RECIPIENT.NAME,
        [
          where(
            CONST.DATA.FIRESTORE.V01.COLLECTIONS.RECIPIENT.FIELDS.RECIPIENT_ID.KEY,
            '==',
            recipientId
          ),
          where(
            CONST.DATA.FIRESTORE.V01.COLLECTIONS.RECIPIENT.FIELDS.REGISTRATION_DOC_ID.KEY,
            '==',
            registrationId
          ),
        ],
        1
      )
      let recipient = RecipientModel.fromFirestoreDoc(docSnapshot.docs[0]).toObject()
      handleModalContext?.handleModal?.(true, MODAL_CONSTS.BILL_BRIEFE, {
        bill: recipient,
      })
    } catch (error) {
      console.error('error', error)
    }
  }

  const tableColumns = [
    {
      name: (
        <TableName
          title={'Back #'}
          isActive={activeSortType === ESorts.back}
          titleAdds={
            !searchNameValue.length &&
            activeStatus.value === ALL_STATUSES.value &&
            activeClass.value === ALL_CLASSES.value ? (
              <span
                onClick={() => {
                  handleModalContext?.handleModal(true, MODAL_CONSTS.AUTO_ASSIGN_MODAL, {
                    riders,
                    cb: (arrValues: number[]) => {
                      const newRiders = [...frozenRiders]
                      arrValues.forEach((num, i) => {
                        if (newRiders[i]) newRiders[i].backNumber = num
                      })
                      setRiders(newRiders)
                      setFrozenRiders(newRiders)
                    },
                  })
                }}
                className={'text-[#122B4680] opacity-50 cursor-pointer hover:opacity-90'}
              >
                • Auto
              </span>
            ) : (
              <></>
            )
          }
          btnHandler={() => sortHandler('backNumber')}
        />
      ),
      cell: (row: IRider) => {
        if (row.paymentStatus === 'pending') {
          return (
            <span className={'whitespace-nowrap text-center w-[40px] text-[16px] text-[#122B4680]'}>
              Draft
            </span>
          )
        }
        return (
          <BackNumber
            id={row.id || ''}
            backNumber={row.backNumber}
            registeredUsers={registeredUsers}
            riders={frozenRiders}
            cbUpdate={(value) => {
              const newFrozenRiders = [...frozenRiders]
              const newRiders = [...riders]
              const frozenRiderIndex = newFrozenRiders.findIndex((r) => r.id === row.id)
              const riderIndex = newRiders.findIndex((r) => r.id === row.id)
              newFrozenRiders[frozenRiderIndex].backNumber = value
              newRiders[riderIndex].backNumber = value
              setRiders(newRiders)
              setFrozenRiders(newFrozenRiders)
            }}
          />
        )
      },
      width: '8%',
    },
    {
      name: (
        <TableName
          title={'Rider'}
          isActive={activeSortType === ESorts.rider}
          btnHandler={() => sortHandler('riderName')}
        />
      ),
      cell: (row: IRider) => (
        <>
          <Link
            to={`${CONST.ROUTES.MANAGE.MANAGE_RIDER.URL}/${row?.userId}/${eventId}/${row?.registrationDocId}`}
            className="flex items-center gap-4 w-[300px] hover:opacity-70 transition-all"
          >
            <span className="p-[2px] bg-[#f6f7fb] my-2 flex items-center rounded-full justify-center shrink-0">
              <img
                alt="icons"
                onError={(e) =>
                  ((e.target as any).src =
                    `https://ui-avatars.com/api/?name=${helpers.generateName(row.riderName ?? UNKNOWN)}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
                }
                src={row?.riderProfilePicture ?? ''}
                className="w-[45px] h-[45px] object-cover rounded-full"
              />
            </span>
            <div className={'max-w-[100%]'}>
              <span className="text-base block text-SeabiscuitDark200ThemeColor font-normal capitalize max-w-[220px] ellipsis">
                {row.riderName ?? UNKNOWN}
              </span>
              <span className={'block text-[#122b4680]'}>
                Registered by •{' '}
                <span className={'underline cursor-pointer'}>
                  {row.registeredBy || row.riderName || UNKNOWN}
                </span>
              </span>
            </div>
          </Link>
        </>
      ),
      width: '20%',
    },
    {
      name: (
        <TableName
          isActive={activeSortType === ESorts.horse}
          title={'Horse'}
          btnHandler={() => sortHandler('horseName')}
        />
      ),
      cell: (row: IRider) => {
        if (row.horseIds && !row.horseIds[0] && row.horseName) {
          return row.horseName
        }
        if (!row.horse) {
          return <>N/A</>
        }

        const trainer = eventTrainers?.find(
          (eventTrainer) => eventTrainer.registrationByDayUniqId === row.uniqId
        )

        return (
          <div className={'flex gap-2 items-center'}>
            <span className="p-[2px] bg-[#f6f7fb] my-2 flex items-center rounded-full justify-center shrink-0">
              <img
                src={row.horseProfilePicture ?? ''}
                alt="icons"
                className="object-cover w-[45px] h-[45px] rounded-full"
                onError={(e) =>
                  ((e.target as any).src =
                    `https://ui-avatars.com/api/?name=${generateName(row.horseName ?? '')}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
                }
              />
            </span>
            <div>
              <div className={'flex items-center gap-2'}>
                <div className={'flex items-center gap-1'}>
                  <span className={'font-[600]'}>{row.horse.horseName || 'N/A'}</span>
                  <span>{row.horses.length > 1 ? `+${row.horses.length - 1} horses` : ''}</span>
                </div>

                <Tooltip
                  title={<span className={'text-[12px]'}>Switch horse</span>}
                  placement="top"
                  arrow
                >
                  <button
                    className={'group'}
                    onClick={() => {
                      if (row.paymentStatus === 'pending') {
                        return toastFunction.info({ message: MESSAGES_CONST.NOT_REGISTERED_YET })
                      }
                      handleModalContext?.handleModal(true, MODAL_CONSTS.SWITCH_HORSE, {
                        rider: row,
                        cb: (horse: IHorseData, newHorses: IHorseData[], horseIds: string[]) => {
                          const newRiders = [...riders]
                          const newFrozenRiders = [...frozenRiders]
                          const riderIndex = newRiders.findIndex((r) => r.id === row?.id)
                          const riderFrozenIndex = newFrozenRiders.findIndex(
                            (r) => r.id === row?.id
                          )

                          if (!newFrozenRiders[riderFrozenIndex]) return null
                          if (!newRiders[riderIndex]) return null

                          newRiders[riderIndex].horseProfilePicture = horse.horseProfilePicture
                          newFrozenRiders[riderFrozenIndex].horseProfilePicture =
                            horse.horseProfilePicture

                          newRiders[riderIndex].horses = newHorses
                          newFrozenRiders[riderFrozenIndex].horses = newHorses

                          newRiders[riderIndex].horseName = horse.horseName
                          newFrozenRiders[riderFrozenIndex].horseName = horse.horseName

                          newRiders[riderIndex].horseIds = horseIds
                          newFrozenRiders[riderFrozenIndex].horseIds = horseIds

                          newRiders[riderIndex].horseId = horse.horseId || ''
                          newFrozenRiders[riderFrozenIndex].horseId = horse.horseId || ''

                          newRiders[riderIndex].horse = horse
                          newFrozenRiders[riderFrozenIndex].horse = horse

                          setRiders([...newRiders])
                          setFrozenRiders([...newFrozenRiders])

                          handleModalContext?.handleModal(false, MODAL_CONSTS.SWITCH_HORSE)
                        },
                      })
                    }}
                  >
                    <IconSwitch />
                  </button>
                </Tooltip>
              </div>
              <span className={'block text-[#122b4680]'}>
                Trainer •{' '}
                <span className={'underline cursor-pointer'}>
                  {trainer?.teamMemberName || 'N/A'}
                </span>
              </span>
            </div>
          </div>
        )
      },
      width: '20%',
    },
    {
      name: <TableName title={'Classes'} centered />,
      cell: (row: IRider) => (
        <>
          {!row.classes[0] || row.isScratched ? (
            <span className={'block w-[100%] md:text-center text-[#122b4680] text-[14px]'}>
              All Scratched
            </span>
          ) : (
            <span className={'block w-[100%] md:text-center text-[#122b4680] text-[14px]'}>
              {row.paymentStatus === 'pending'
                ? `   ${row.classes.length} class${row.classes.length > 1 ? 'es' : ''}`
                : // ? `Draft`
                  row.scratchedClasses && row.scratchedClasses.length > 0
                  ? `${row.classes.length} live, ${row.scratchedClasses.length} scratched`
                  : `
                 ${row.classes.length} class${row.classes.length > 1 ? 'es' : ''}
                `}
            </span>
          )}
        </>
      ),
      width: '10%',
    },
    {
      name: <TableName title={'Paperwork'} centered />,
      width: '10%',
      cell: (row: IRider) => {
        const isUnsigned = row.documents?.some((d) => d.status !== 'Signed')

        return (
          <>
            <div className="md:justify-center w-full flex text-[14px]">
              {isUnsigned ? (
                <span
                  className={
                    row.paymentStatus === 'pending'
                      ? 'text-[#122B4680]'
                      : 'text-SeabiscuitMainThemeColor'
                  }
                >
                  Unsigned
                </span>
              ) : (
                <span className={'text-[#122b4680]'}>Signed</span>
              )}
            </div>
          </>
        )
      },
    },
    {
      name: <TableName title={'Payment'} centered />,
      cell: (row: IRider) => {
        // const teamMember = riders.find((r) => row.riderTeamMember?.userId === r.riderId)

        return (
          <>
            <div className="md:justify-center w-full flex text-[14px]">
              {row?.paymentStatus === PAYMENT_STATUSES.PAID ? (
                <span className={'text-[#122B4680]'}>Paid</span>
              ) : (
                <span
                  onClick={() => {
                    if (row.paymentStatus === 'pending') return null
                    dispatch(organizerSliceAcs.setRegistrationByDayId(row.id))
                    onViewReceiptClick(row.userId, row.registrationDocId).then()
                  }}
                  className={
                    row.paymentStatus === 'pending'
                      ? 'text-[#122B4680]'
                      : 'cursor-pointer text-SeabiscuitMainThemeColor'
                  }
                >
                  {row?.paymentStatus === PAYMENT_STATUSES.CASH ? 'Cash' : 'Unpaid'}
                </span>
              )}
            </div>
          </>
        )
      },
      width: '10%',
    },
    {
      name: <TableName title={'Answers'} centered />,
      cell: (row: IRider) => {
        return (
          <div
            className={
              'text-center w-full text-[#122B4680] cursor-pointer hover:text-SeabiscuitMainThemeColor'
            }
          >
            <span
              onClick={() =>
                handleModalContext?.handleModal(true, MODAL_CONSTS.ANSWERS, {
                  rider: row,
                })
              }
            >
              {row.answers?.length || 0} answers
            </span>
          </div>
        )
      },
      width: '10%',
    },
    {
      name: <TableName title={'Manage'} centered />,
      cell: (row: IRider) => (
        <div className={'flex gap-2 md:justify-end w-[100%]'}>
          <TableBtn
            onClick={() => {
              handleModalContext?.handleModal(true, MODAL_CONSTS.SEND_MESSAGE, {
                emailId: row.riderEmail,
                user_name: row.riderName,
                profilePicture: row.riderProfilePicture,
              })
            }}
            type={'message'}
          />
          <Link
            to={
              row.paymentStatus === 'pending'
                ? '#'
                : `${CONST.ROUTES.MANAGE.MANAGE_RIDER.URL}/${row?.userId}/${eventId}/${row?.registrationDocId}`
            }
            onClick={(e) => {
              if (row.paymentStatus === 'pending') {
                e.preventDefault()
                toastFunction.info({ message: MESSAGES_CONST.NOT_REGISTERED_YET })
              }
            }}
            className="hover:opacity-70 transition-all"
          >
            <TableBtn type={'go'} />
          </Link>
        </div>
      ),
      width: '12%',
    },
  ]

  const dispatch = useAppDispatch()

  const getRegisteredUsers = async () => {
    const riderTeamMembers: IRiderTeamMemberInterface[] = []
    const newEventRegisteredUsers: EventRegisteredUsersModel[] = []

    const riderTeamMembersSnaps = await FirestoreService.filterItems(
      COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
      [where(COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.EVENT_ID.KEY, '==', eventId)]
    )

    if (riderTeamMembersSnaps.size) {
      riderTeamMembersSnaps.forEach((currDoc) => {
        riderTeamMembers.push(
          getConvertedData(RiderTeamMemberModel.fromFirestoreDoc(currDoc).toObject())
        )
      })
    }

    const eventRegisteredUsersSnaps = await FirestoreService.filterItems(
      COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
      [where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.EVENT_ID.KEY, '==', eventId)]
    )

    eventRegisteredUsersSnaps.docs.forEach((currDoc) => {
      newEventRegisteredUsers.push(
        getConvertedData(
          EventRegisteredUsersModel.fromFirestoreDoc(currDoc).toObject()
        ) as EventRegisteredUsersModel
      )
    })

    const registeredUsersByDay = await getRiders([eventId])

    const newRegisteredUsersByDay = registeredUsersByDay.map((ru) => ({
      ...ru,
      riderTeamMember: riderTeamMembers.find(
        (rtm) => rtm.registrationDocId === ru.registrationDocId
      ),
    })) as IRegisteredUser[]

    newRegisteredUsersByDay.forEach((rider, index) => {
      const registeredUser = newEventRegisteredUsers.find((user) => rider.userId === user.userId)
      if (registeredUser) {
        newRegisteredUsersByDay[index] = {
          ...rider,
          answers: registeredUser.answers,
        }
      }
    })

    setRegisteredUsers(newRegisteredUsersByDay)

    setLoading(false)
  }

  useEffect(() => {
    if (horsesLoading) return
    if (!eventId) {
      helpers.logger({
        message: CustomError.somethingWentWrong({
          moduleName: 'useEffect',
          fileName: 'ExhibitorsListingRoot',
          devMessage: `eventId is ${eventId}`,
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        }),
      })

      toastFunction.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })

      dispatch(organizerSliceAcs.resetExhibitorAc())

      return
    }

    dispatch(
      organizerSliceAcs.setExhibitorsAc({
        eventId,
        cursorId: null,
        tabName: 'all',
      })
    )

    getRegisteredUsers().then()

    return () => {
      dispatch(organizerSliceAcs.resetExhibitorAc())
    }
  }, [eventId, horsesLoading])

  useEffect(() => {
    if (EventFees && frozenRiders[0]) {
      const allClassesLabel = {
        value: ALL_CLASSES.value,
        label: `${ALL_CLASSES.label}  <span class="text-[#122B4680]">• ${frozenRiders.length}  entries</span>`,
      }
      const arrClasses =
        EventFees?.registrationFees?.map((itm) => ({
          value: itm.name || '',
          label: `${itm.name} <span class="text-[#122B4680]">• ${frozenRiders.filter((r) => r.classes.includes(itm.name || '')).length} entries</span>`,
        })) || []
      const newRidersClasses: typeof ridersClasses = [allClassesLabel, ...arrClasses]

      setActiveClass(allClassesLabel)
      setRidersClasses(newRidersClasses)
    }
  }, [EventFees, frozenRiders])

  const getSortedRiders = async () => {
    const newRiders: IRider[] = []

    const groupedByHorseAndRider = registeredUsers.reduce((acc: any, item) => {
      const horseIds = item.horseIds?.join('')
      const key = `${horseIds}_${item.riderId}`

      if (!acc[key]) {
        acc[key] = { ...item, classes: [], scratchedClasses: [] }
      }
      if (item.registrationByDayName && !item.isScratched) {
        acc[key].classes.push(item.registrationByDayName)
      } else if (item.registrationByDayName && item.isScratched) {
        acc[key].scratchedClasses.push(item.registrationByDayName)
      }

      return acc
    }, {})

    registeredUsers.forEach((rider) => {
      const horseIds = rider.horseIds?.join('')

      const horse = horses.find((hr) => hr.id === rider.horseId)
      const riderHorses = allHorses.filter((h) => rider.horseIds?.includes(h.id || ''))

      let classes: string[] = []
      let scratchedClasses: string[] = []
      if (groupedByHorseAndRider[`${horseIds}_${rider.riderId}`]) {
        classes = [...groupedByHorseAndRider[`${horseIds}_${rider.riderId}`].classes]
        scratchedClasses = [
          ...groupedByHorseAndRider[`${horseIds}_${rider.riderId}`].scratchedClasses,
        ]
      }

      const hasRider = newRiders.find(
        (rd) =>
          rd.registrationDocId === rider.registrationDocId &&
          rd.riderId === rider.riderId &&
          rd.horseIds?.join('') === horseIds
      )

      if (!hasRider) {
        const user = users.find((user) => user.id === rider.userId)
        const riderAsUserObj = users.find((user) => user.id === rider.riderId)

        newRiders.push({
          ...rider,
          horse,
          riderName: riderAsUserObj ? getUserFullName(riderAsUserObj) : rider.riderName,
          documents: [],
          horses: riderHorses,
          registeredBy: user ? getUserFullName(user) : '',
          classes,
          scratchedClasses,
        })
      }
    })

    let documentsSnaps = await FirestoreService.filterItems(COLLECTIONS.USERS_DOCUMENTS.NAME, [
      where(COLLECTIONS.USERS_DOCUMENTS.FIELDS.EVENT_ID.KEY, '==', eventId),
    ])

    let documents = documentsSnaps.docs.map((currDoc) =>
      getConvertedData(UserDocumentModel.fromFirestoreDoc(currDoc).toObject())
    )

    newRiders.forEach((rider) => {
      rider['documents'] = documents.filter(
        (d) => d.signatoryId === rider.riderId
      ) as UserDocumentModel[]
    })
    const sortedRiders = sortRidersByScratched(newRiders).filter(
      (rider) => rider.paymentStatus !== 'pending'
    )

    const newDraftRiders = newRiders.filter((rider) => rider.paymentStatus === 'pending')

    setRiders(sortedRiders)
    setDraftRiders(newDraftRiders)
    setFrozenDraftRiders(newDraftRiders)
    setFrozenRiders(sortedRiders)
  }
  useEffect(() => {
    if (registeredUsers.length > 0) getSortedRiders().then()
  }, [registeredUsers, horses])

  function sortHandler(type: 'riderName' | 'backNumber' | 'horseName') {
    if (activeSortType !== ESorts.none) {
      const newRiders = riders.sort((a, b) => {
        const v1 = type === 'backNumber' ? +a[type] || 0 : a[type] || ''
        const v2 = type === 'backNumber' ? +b[type] || 0 : b[type] || ''

        if (v1 < v2) {
          return -1
        }
        if (v1 > v2) {
          return 1
        }
        return 0
      })
      setRiders(newRiders)
      setActiveSortType(ESorts.none)
    } else {
      const newRiders = riders.sort((a, b) => {
        const v1 = type === 'backNumber' ? +a[type] || 0 : a[type] || ''
        const v2 = type === 'backNumber' ? +b[type] || 0 : b[type] || ''

        if (v1 > v2) {
          return -1
        }
        if (v1 < v2) {
          return 1
        }
        return 0
      })
      setRiders(newRiders)
      switch (type) {
        case 'riderName':
          return setActiveSortType(ESorts.rider)
        case 'horseName':
          return setActiveSortType(ESorts.horse)
        default:
          return setActiveSortType(ESorts.back)
      }
    }
  }

  return {
    tableColumns,
    riders: [...riders],
    draftRiders,
    horses,
    activeTab,
    setActiveTab,
    setActiveClass,
    loading,
    setActiveStatus,
    setRiders,
    frozenRiders,
    setSearchNameValue,
    activeClass,
    searchNameValue,
    ridersClasses,
    activeStatus,
    setDraftRiders,
    frozenDraftRiders,
    setFrozenDraftRiders,
  }
}
