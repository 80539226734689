import { ChevronRight } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { IEventInterface } from '../../../models/events/event.interface'
import { IEventInterfaceExtended } from './EventsProfileListing'

import { CONST } from '../../../const/const'
import { MODAL_CONSTS } from '../../../const/modal-const'

const maxTagsToShow = 2

const getLocation = (currEvent: IEventInterface) => {
  return currEvent.state && currEvent.state !== '' && currEvent.state !== 'unknown'
    ? currEvent.state
    : currEvent.country && currEvent.country !== '' && currEvent.country !== 'unknown'
      ? currEvent.country
      : 'N/A'
}

const EVENT_COLS = [
  {
    name: 'Event',
    cell: (row: IEventInterfaceExtended) => (
      <Link
        to={`${CONST.ROUTES.EVENT_DETAILS.URL}/${row.id}`}
        className="hover:opacity-70 transition-all"
      >
        <div className="flex items-center py-2">
          <span className="w-[45px] h-[45px] block shrink-0">
            <img
              src={row.eventLogo}
              alt=""
              className="object-cover w-full h-full rounded-full"
              onError={(e) => (e.currentTarget.src = '/assets/placeholders/Emptyuser.png')}
            />
          </span>
          <span className="text-[14px] text-SeabiscuitDark200ThemeColor font-bold ml-4 capitalize">
            {row.eventName}
          </span>
        </div>
      </Link>
    ),
  },
  {
    name: 'Discipline',
    cell: (row: IEventInterfaceExtended) => (
      <>
        <img src={'assets/img/allevent/Horsessign.svg'} alt="" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize">
          {row.tags && row.tags.length
            ? [...(row.tags ?? [])]
                .splice(0, maxTagsToShow)
                .map(
                  (item: string, index: number) =>
                    `${item}${index + 1 === row.tags?.length || index + 1 === maxTagsToShow ? '' : ', '}`
                )
            : 'N/A'}
        </p>
      </>
    ),
    width: '200px',
  },
  {
    name: 'Event type',
    cell: (row: IEventInterfaceExtended) => (
      <>
        <img src={'assets/img/allevent/Laurelwreath.svg'} alt="icons" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize">{row.category}</p>
      </>
    ),
    width: '140px',
  },
  {
    name: 'Location',
    cell: (row: IEventInterfaceExtended) => (
      <>
        <img src={'assets/img/allevent/Location.svg'} alt="" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize">{getLocation(row)}</p>
      </>
    ),
    width: '170px',
  },
  {
    name: 'Start date',
    cell: (row: IEventInterfaceExtended) => (
      <>
        <img alt="" src={'assets/img/allevent/Calendar.svg'} />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize">
          {moment(row.eventStartDate).format('MMM DD')}
        </p>
      </>
    ),
    width: '120px',
  },
  {
    name: 'Going',
    cell: (row: IEventInterfaceExtended) => (
      <div
        className="flex items-center gap-2 cursor-pointer transition-all hover:opacity-70"
        onClick={() =>
          row?.handleModal?.(true, MODAL_CONSTS.REGISTER_EXHIBITORS, {
            eventClicked: {
              eventId: row?.id ?? null,
            },
          })
        }
      >
        <img src={'assets/img/allevent/Testpassed.svg'} alt="icons" />
        <p className="text-SeabiscuitDark200ThemeColor underline">{row.riders ?? 0}</p>
      </div>
    ),
    width: '100px',
  },
  {
    name: '',
    cell: (row: IEventInterfaceExtended) => (
      <Link
        to={`${CONST.ROUTES.EVENT_DETAILS.URL}/${row.id}`}
        className="ml-0 flex w-[100px] h-[45px] mx-auto items-center justify-center py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-SeabiscuitDark200ThemeColor bg-SeabiscuitLightThemeColor hover:text-white hover:bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
      >
        More
        <ChevronRight sx={{ fontSize: 16 }} />
      </Link>
    ),
    width: '120px',
  },
]

const ORGANIZER_EVENT_COLS = [
  {
    name: 'Event',
    cell: (row: IEventInterfaceExtended) => (
      <Link
        to={`${CONST.ROUTES.EVENT_DETAILS.URL}/${row.id}`}
        className="hover:opacity-70 transition-all"
      >
        <div className="flex items-center py-2">
          <span className="w-[45px] h-[45px] block shrink-0">
            <img
              src={row.eventLogo}
              alt="icons"
              className="object-cover w-full h-full rounded-full"
              onError={(e) => (e.currentTarget.src = '/assets/placeholders/Emptyuser.png')}
            />
          </span>
          <span className="text-[14px] text-SeabiscuitDark200ThemeColor font-bold ml-4 capitalize">
            {row.eventName}
          </span>
        </div>
      </Link>
    ),
  },
  {
    name: 'Discipline',
    cell: (row: IEventInterfaceExtended) => (
      <>
        <img src={'assets/img/allevent/Horsessign.svg'} alt="" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize">
          {row.tags && row.tags.length
            ? [...(row.tags ?? [])]
                .splice(0, maxTagsToShow)
                .map(
                  (item, index) =>
                    `${item}${index + 1 === row.tags?.length || index + 1 === maxTagsToShow ? '' : ', '}`
                )
            : 'N/A'}
        </p>
      </>
    ),
    width: '200px',
  },
  {
    name: 'Event type',
    cell: (row: IEventInterfaceExtended) => (
      <>
        <img src={'assets/img/allevent/Laurelwreath.svg'} alt="icons" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize">{row.category}</p>
      </>
    ),
    width: '140px',
  },
  {
    name: 'Start date',
    cell: (row: IEventInterfaceExtended) => (
      <>
        <img src={'assets/img/allevent/Calendar.svg'} alt="" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize">
          {moment(row.eventStartDate).format('MMM DD')}
        </p>
      </>
    ),
    width: '120px',
  },
  {
    name: 'Going',
    cell: (row: IEventInterfaceExtended) => (
      <div
        className="flex items-center gap-2 cursor-pointer transition-all hover:opacity-70"
        onClick={() =>
          row?.handleModal?.(true, MODAL_CONSTS.REGISTER_EXHIBITORS, {
            eventClicked: {
              eventId: row?.id ?? null,
            },
          })
        }
      >
        <img src={'assets/img/allevent/Testpassed.svg'} alt="icons" />
        <p className="text-SeabiscuitDark200ThemeColor underline">{row.riders ?? 0}</p>
      </div>
    ),
    width: '100px',
  },
  {
    name: '',
    cell: (row: IEventInterfaceExtended) => (
      <>
        <Link
          to={`${CONST.ROUTES.EVENT_DETAILS.URL}/${row.id}`}
          className="ml-0 flex w-[100px] h-[45px] mx-auto items-center justify-center py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-SeabiscuitDark200ThemeColor bg-SeabiscuitLightThemeColor hover:text-white hover:bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
        >
          More
          <ChevronRight sx={{ fontSize: 16 }} />
        </Link>
      </>
    ),
    width: '120px',
  },
]

const HORSE_PROFILE_COLS = [
  {
    name: 'Event',
    cell: (row: IEventInterface) => (
      <Link
        to={`${CONST.ROUTES.EVENT_DETAILS.URL}/${row.id}`}
        className="hover:opacity-70 transition-all"
      >
        <div className="flex items-center py-2">
          <span className="w-[45px] h-[45px] block shrink-0">
            <img
              src={row.eventCardCoverImageUrl}
              alt="icons"
              className="object-cover w-full h-full rounded-full"
              onError={(e) => (e.currentTarget.src = '/assets/placeholders/Emptyuser.png')}
            />
          </span>
          <span className="text-[14px] text-SeabiscuitDark200ThemeColor font-bold ml-4 capitalize">
            {row.eventName}
          </span>
        </div>
      </Link>
    ),
  },
  {
    name: 'Discipline',
    cell: (row: IEventInterface) => (
      <>
        <img src={'assets/img/allevent/Horsessign.svg'} alt="" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize">
          {row.tags && row.tags.length
            ? [...(row.tags ?? [])]
                .splice(0, maxTagsToShow)
                .map(
                  (item, index) =>
                    `${item}${index + 1 === row?.tags?.length || index + 1 === maxTagsToShow ? '' : ', '}`
                )
            : 'N/A'}
        </p>
      </>
    ),
    width: '200px',
  },
  {
    name: 'Event type',
    cell: (row: IEventInterface) => (
      <>
        <img src={'assets/img/allevent/Laurelwreath.svg'} alt="" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize">{row.category}</p>
      </>
    ),
    width: '140px',
  },
  {
    name: 'Location',
    cell: (row: IEventInterface) => (
      <>
        <img src={'assets/img/allevent/Location.svg'} alt="" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize"> {getLocation(row)}</p>
      </>
    ),
    width: '170px',
  },
  {
    name: 'Start date',
    cell: (row: IEventInterface) => (
      <>
        <img src={'assets/img/allevent/Calendar.svg'} alt="" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize">
          {moment(row.eventStartDate).format('MMM DD')}
        </p>
      </>
    ),
    width: '120px',
  },
]

const COLS = {
  EVENT_COLS,
  HORSE_PROFILE_COLS,
  ORGANIZER_EVENT_COLS,
}

export const ALL_EVENT_LISTING_ROOT_PAGE_DATA = {
  COLS,
}
