import { where } from 'firebase/firestore'

import { getConvertedData } from '../models/interface.helper'
import FirestoreService from '../services/firestoreService'

import { IRegistrationByDayInterface } from '../models/registrations-by-day/registrationByDay.interface'

import { RegistrationByDayModel } from '../models/registrations-by-day/registrationByDay.model'

import { CONST } from '../const/const'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

export const getRiders = async (eventIds: string[]) => {
  try {
    const registeredRiders: IRegistrationByDayInterface[] = []

    const registeredRidersSnaps = await FirestoreService.filterItems(
      COLLECTIONS.REGISTRATION_BY_DAY.NAME,
      [
        where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.PAYMENT_STATUS.KEY, 'in', [
          COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.PAYMENT_STATUS.VALUES.PAID,
          COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.PAYMENT_STATUS.VALUES.PENDING,
          COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.PAYMENT_STATUS.VALUES.CASH,
        ]),
      ]
    )

    registeredRidersSnaps.docs.forEach((currDoc) => {
      const current = getConvertedData(RegistrationByDayModel.fromFirestoreDoc(currDoc).toObject())
      if (eventIds.includes(current?.eventId ?? '')) registeredRiders.push(current)
    })

    return registeredRiders
  } catch (error) {
    console.error(error, 'error')
    return []
  }
}
