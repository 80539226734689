import React, { FC } from 'react'
import clsx from 'clsx'

const PrimaryButton: FC<{
  onClick?: () => void
  caption: string
  disabled?: boolean
  className?: string
}> = ({ onClick, caption, disabled, className }) => {
  return (
    <>
      {!disabled && (
        <button
          onClick={onClick}
          type="button"
          className={clsx(
            'bg-SeabiscuitMainThemeColor hover:bg-[#d70443] transition-all text-SeabiscuitWhiteThemeColor text-sm rounded-xl border border-solid border-SeabiscuitGrayLight100ThemeColor px-4 py-4 mb-2',
            className
          )}
          disabled={disabled}
        >
          {caption}
        </button>
      )}
    </>
  )
}
export default PrimaryButton
