export const SheetFEIRegionalGamesPreliminary = {
  id: 'SheetFEIRegionalGamesPreliminary',
  name: 'FEI Regional Games Preliminary',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 220,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter in collected canter</p><p><span>X •</span> Halt - immobility - salute Proceed in collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track to the right</p><p><span>MXK •</span> Change rein in medium trot</p><p><span>K •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>L •</span> Volte to the left 8 m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>LS •</span> Half-pass to the left</p><p><span>SHCM •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>MF •</span> Extended trot</p><p><span>F •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>L •</span> Volte to the right 8 m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description: '<p><span>LR •</span> Half-pass to the right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>M •</span> Turn left</p><p><span>G •</span> Halt - rein back 5 steps - immediately proceed in medium walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>GHSI •</span> Medium walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>N/A •</span> Shorten the steps</p><p><span>Between I &amp; R •</span> Half pirouette to the left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description:
            '<p><span>N/A •</span> Shorten the steps</p><p><span>Before I &amp; S •</span> Half pirouette to the right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>N / A •</span> Medium walk</p><p><span>N / A •</span> I(R)I(S)IR</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description: '<p><span>RK •</span> Change rein in extended walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p><span>K •</span> Medium walk</p><p><span>A •</span> Proceed in collected canter left</p><p><span>AP •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>P •</span> Turn left</p><p><span>L •</span> Simple change of leg</p><p><span>V •</span> Turn right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>S •</span> Turn right</p><p><span>I •</span> Simple change of leg</p><p><span>R •</span> Turn left</p><p><span>RMCH •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>HB •</span> Change rein</p><p><span>BE •</span> Half volte 20 m (counter canter)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>S •</span> Flying change of leg</p><p><span>SHCM •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>ME •</span> Change rein</p><p><span>EB •</span> Half volte 20 m (counter canter)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>R •</span> Flying change of leg</p><p><span>RMCH •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>HXF •</span> Medium canter</p><p><span>F •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>X •</span> Halt - immobility - salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Collective Marks',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>General Impression</h5>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}

export const SheetFEIRegionalGamesTeam = {
  id: 'SheetFEIRegionalGamesTeam',
  name: 'FEI Regional Games Team',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 220,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter in collected canter</p><p><span>X •</span> Halt - immobility- salute. Proceed in collected trot.</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track to the right</p><p><span>R •</span> Volte to the right 8 m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p><span>RP •</span> Shoulder-in to the right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>PL •</span> Half volte to the right 10 m</p><p><span>LR •</span> Half-pass to the right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>M •</span> Turn left</p><p><span>G •</span> Halt - immobility 5 seconds. Proceed in collected trot.</p><p><span>H •</span> Turn left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description: '<p><span>S •</span> Volte to the left 8 m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description: '<p><span>SV •</span> Shoulder-in to the left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>VL •</span> Half volte to the left 10 m</p><p><span>LS •</span> Half-pass to the left</p><p><span>SHCM •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>MXK •</span> Medium trot</p><p><span>K •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>A •</span> Halt - rein back 5 steps. Immediately proceed in collected trot.</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description:
            '<p><span>FXH •</span> Extended trot</p><p><span>H •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>C •</span> Medium walk</p><p><span>Before M •</span> Shorten the steps</p><p><span>M •</span> Half pirouette to the right</p><p><span>MCH •</span> Medium walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>Before H•</span> Shorten the steps</p><p><span>H •</span> Half pirouette to the left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description: '<p><span>N/A •</span> Medium walk (CMCH)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>HCM •</span> Medium walk</p><p><span>LMV•</span> Change rein in extended walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>V •</span> Medium walk</p><p><span>K •</span> Proceed in collected canter left</p><p><span>KAF •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>FM •</span> Medium canter</p><p><span>M •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>SI •</span> Half volte to the left 10 m</p><p><span>I •</span> Simple change of leg</p><p><span>IR •</span> Half volte to the right 10 m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>RXV •</span> Change rein in collected canter</p><p><span>VA •</span> Counter canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description: '<p><span>A •</span> Flying change of leg</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>PXS •</span> Change rein in collected canter</p><p><span>SC •</span> Counter canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description: '<p><span>C •</span> Flying change of leg</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description:
            '<p><span>MF •</span> Medium canter</p><p><span>FAKV •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 24,
          description:
            '<p><span>VL •</span> Half volte to the right 10 m</p><p><span>L •</span> Simple change of leg</p><p><span>LP •</span> Half volte to the left 10 m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 25,
          description: '<p><span>PMC •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 26,
          description:
            '<p><span>C •</span> Collected trot</p><p><span>HXF •</span> Extended trot</p><p><span>F •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 27,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>X •</span> Halt - immobility - salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Collective Marks',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>General Impression</h5>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}

export const SheetFEIRegionalGamesIndividual = {
  id: 'SheetFEIRegionalGamesIndividual',
  name: 'FEI Regional Games Individual',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 290,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter in collected canter</p><p><span>X •</span> Halt - immobility- salute. Proceed in collected trot.</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track to the left</p><p><span>HB •</span> Change rein in medium trot</p><p><span>B •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p><span>B •</span> Volte to the right 8 m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p><span>BF •</span> Shoulder-in to the right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>DB •</span> Half-pass to the right</p><p><span>BM •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>Before C •</span> Collected walk</p><p><span>C •</span> Half pirouette to the left. Immediately proceed in collected trot.</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>ME •</span> Change rein in medium trot</p><p><span>E •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description: '<p><span>E •</span> Volte to the left 8 m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>EK •</span> Shoulder-in to the left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>DE •</span> Half-pass to the left</p><p><span>EHCM •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description:
            '<p><span>MXK •</span> Extended trot</p><p><span>K •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description: '<p><span>A •</span> Halt - immobility 5 seconds</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>A •</span> Proceed in medium walk</p><p><span>AP •</span> Medium walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description: '<p><span>PXS •</span> Change rein in extended walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description: '<p><span>SHC •</span> Medium walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>C •</span> Halt - rein back 5 steps - immediately proceed in collected canter right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description: '<p><span>MXK •</span> Medium canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>K •</span> Collected canter and flying change of leg</p><p><span>KAF •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 19,
          description: '<p><span>FLE •</span> Change rein with flying change of leg at L</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description: '<p><span>EIM •</span> Change rein with flying change of leg at I</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description: '<p><span>HX •</span> Half-pass to the left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>X •</span> Volte to the left 8 m</p><p><span>XL •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description:
            '<p><span>L •</span> Collected walk</p><p><span>Between L & D •</span> Half pirouette to the right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 24,
          description: '<p><span>L •</span> Proceed in collected canter right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 25,
          description:
            '<p><span>X •</span> Volte to the right 8 m</p><p><span>XM •</span> Half-pass to the right</p><p><span>MC •</span> Counter canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 26,
          description: '<p><span>C •</span> Flying change of leg</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 27,
          description: '<p><span>HXF •</span> Extended canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 28,
          description: '<p><span>F •</span> Collected canter and flying change of leg</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 29,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>X •</span> Halt - immobility - salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Collective Marks',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>General Impression</h5>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}
