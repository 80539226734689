import React, { FC, useContext } from 'react'

import { Controller, UseFormWatch } from 'react-hook-form'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Control, UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types/form'
import clsx from 'clsx'
import Select from 'react-select'
import { FieldArrayWithId, UseFieldArrayUpdate } from 'react-hook-form/dist/types/fieldArray'

import CustomDatePicker from '../../../common/inputs/CustomDatePicker'
import { IconDelete2 } from '../../../icons/IconDelete2'

import { UserModel } from '../../../../models/users/user.model'

import { IUserInterface } from '../../../../models/users/user.interface'

import FirebaseStorageService from '../../../../services/storageService'
import helpers from '../../../../commonHelpers/helpers'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'

import { getReactPickerDate } from '../../../../models/interface.helper'

import { useAppSelector } from '../../../../store/hooks'
import { selectUserId } from '../../../../store/user/userSlice'

import { Status } from '../../../../fakeData/StatusList'
import { customStyles } from '../../../customStyles/ReactSelectCustomStyle'

import { MODAL_CONSTS } from '../../../../const/modal-const'
import { CONST } from '../../../../const/const'
import { MESSAGES_CONST } from '../../../../const/messages-const'

interface ImageWrapperProps {
  url: string
  removeHandler: () => void
  editMode?: boolean
}

const ImageWrapper: React.FC<ImageWrapperProps> = ({ url, removeHandler, editMode }) => {
  const [error, setError] = React.useState(false)

  return (
    <div className={'relative'}>
      {error || !url ? (
        <span className={'text-[#122B4680] text-[14px]'}>Add image of card...</span>
      ) : (
        <>
          <img
            className={'w-auto h-[35px] rounded'}
            onError={() => setError(true)}
            alt={''}
            onClick={() => window.open(url, '_blank')}
            src={url}
          />
          {editMode && (
            <button className={'absolute top-[-5px] right-[-15px]'} onClick={removeHandler}>
              <IconDelete2 />
            </button>
          )}
        </>
      )}
    </div>
  )
}

interface CompetitorNumberItemProps {
  icon?: string
  label?: string
  fieldKey:
    | 'userUSEF'
    | 'userFEI'
    | 'userUSDF'
    | 'userUSEA'
    | 'userUSHJA'
    | 'userAHHS'
    | 'userAMHA'
    | 'userARHPA'
    | 'userASHA'
    | 'userUPHA'
    | 'userWDAA'
    | `userExtraDocument.${number}`
  saved: boolean
  register: UseFormRegister<IUserInterface>
  control: Control<IUserInterface>
  onRemove?: () => void
  fields: FieldArrayWithId<IUserInterface, 'userExtraDocument', 'id'>[]
  userData: IUserInterface | null
  setUserData: (data: IUserInterface) => void
  setValue: UseFormSetValue<IUserInterface>
  update: UseFieldArrayUpdate<IUserInterface, 'userExtraDocument'>
  watch: UseFormWatch<IUserInterface>
  setImgLoading: (loading: boolean) => void
}

export const CompetitorNumberItem: FC<CompetitorNumberItemProps> = ({
  icon,
  label,
  fieldKey,
  saved,
  register,
  control,
  onRemove,
  fields,
  userData,
  setUserData,
  setValue,
  update,
  watch,
  setImgLoading,
}) => {
  const userId = useAppSelector(selectUserId)

  const handleModalContext = useContext(HandleModalContext)
  const toastFunctions = useToasterHelper()

  const addNumberImage = async (event: any, fieldName: any, isExtraFields?: boolean) => {
    const currFormValues = watch()
    const files = event.target.files
    const file = files[0]
    const PATH = CONST.DATA.STORAGE.USERS.NUMBERS_URL.PREFIX
    if (!file) return toastFunctions.info({ message: MESSAGES_CONST.NO_FILE_SELECTED })

    try {
      const downloadUrl = await FirebaseStorageService.uploadFile(
        file,
        `${PATH}/${userId}/${new Date().getTime()}`,
        (percent: number) => {
          if (0) console.log(percent)
        }
      )
      if (
        isExtraFields &&
        currFormValues.userExtraDocument &&
        currFormValues.userExtraDocument[+fieldName]
      ) {
        update(+fieldName, {
          ...currFormValues.userExtraDocument[+fieldName],
          image: `${downloadUrl || ''}`,
        })
      } else if (!isExtraFields) {
        const newUserData = new UserModel({
          ...currFormValues,
          ...userData,
        })

        if ((newUserData as any)[fieldName as any]) {
          ;(newUserData as any)[fieldName as any].image = (downloadUrl ?? '') as string
        }

        setValue(fieldName, {
          ...(currFormValues as any)[fieldName],
          image: `${downloadUrl || ''}`,
        })

        setUserData(newUserData)
      }

      toastFunctions.success({ message: MESSAGES_CONST.PIC_UPLOADED })
    } catch (error) {
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      setImgLoading(false)
    }
  }

  const removeImage = async (url: string, fieldName: any, isExtraFields?: boolean) => {
    const currFormValues = watch()

    try {
      await FirebaseStorageService.deleteFile(url)

      if (
        isExtraFields &&
        currFormValues.userExtraDocument &&
        currFormValues.userExtraDocument[+fieldName]
      ) {
        update(+fieldName, {
          ...currFormValues.userExtraDocument[+fieldName],
          image: ``,
        })
      } else if (!isExtraFields) {
        const newUserData = new UserModel({
          ...currFormValues,
          ...userData,
        })

        if ((newUserData as any)[fieldName as any]) {
          ;(newUserData as any)[fieldName as any].image = ''
        }

        setValue(fieldName, {
          ...(currFormValues as any)[fieldName],
          image: '',
        })

        setUserData(newUserData)
      }

      toastFunctions.success({
        message: MESSAGES_CONST.IMAGE_REMOVED,
      })
    } catch (error) {
      console.error(error, 'error')
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    }
  }

  const renderImageNumberInput = (field: string, isExternal?: boolean) => {
    if (!userData) return <></>

    let imgUri = ''

    if (isExternal) {
      const externalField = fields[+field]
      imgUri = externalField?.image || ''
    } else {
      imgUri = userData[field as keyof IUserInterface]?.image || null
    }

    return saved ? (
      <ImageWrapper url={imgUri} removeHandler={() => null} />
    ) : (
      <div
        className={
          'group flex items-center justify-between px-3  w-full relative border border-solid rounded-xl !ring-0 h-[51px] !border-[#D3DAEE]'
        }
      >
        <ImageWrapper
          url={imgUri}
          removeHandler={() => {
            handleModalContext?.handleModal(true, MODAL_CONSTS.CONFIRM_REMOVE_IMAGE, {
              confirmDelete: () => removeImage(imgUri, field, isExternal),
            })
          }}
          editMode={true}
        />
        <div
          className={
            'h-[40px] w-[40px] bg-[#E6EAF2] flex items-center relative hover:bg-[#dcdfe7] cursor-pointer rounded-[4px] justify-center'
          }
        >
          <span className={'text-[#1F4173] text-[20px] cursor-pointer'}>+</span>
          <input
            type="file"
            name={isExternal ? 'userExtraDocument.' : '' + field + '.image'}
            accept=".jpeg, .png, .jpg"
            onChange={(e: any) => addNumberImage(e, field, isExternal)}
            className="absolute h-[100%] w-[100%] opacity-0 left-0 right-0 top-0 bottom-0 cursor-pointer z-10"
          />
        </div>
      </div>
    )
  }

  return (
    <div className="flex justify-between gap-2 flex-wrap items-center mt-2 max-w-[1350px]">
      <label className="pr-8 flex items-center gap-2 text-[#122B46] md:w-[26%] flex-1">
        <img src={icon ?? '/assets/cp_icons/Hierarchy-1.svg'} className="w-6" alt="" />
        {label ? (
          <p className="p-3">{label}</p>
        ) : (
          <input
            type="text"
            {...register(`${fieldKey}.documentFullName`)}
            placeholder="Enter name..."
            disabled={saved}
            className={clsx(
              `w-full border border-SeabiscuitLightThemeColorD3 text-gray-900 text-sm rounded-xl focus:border-SeabiscuitLightThemeColorD3 focus:ring-0 p-3`,
              saved
                ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor'
                : '!border-SeabiscuitLightThemeColorD3'
            )}
          />
        )}
      </label>
      <div className="pr-8 gap-2 flex flex-wrap items-center justify-between relative w-full md:w-[72%]">
        <div className="w-full min-w-[200px] md:w-[22%] flex-1">
          <input
            type="text"
            {...register(`${fieldKey}.documentNumber`)}
            placeholder={`Enter ${label ?? 'number'}...`}
            disabled={saved}
            className={clsx(
              `rounded-xl w-full border-SeabiscuitLightThemeColorD3 border focus:ring-SeabiscuitLightThemeColorD3 focus:border-SeabiscuitLightThemeColorD3 py-3 placeholder:text-[#122B4680] placeholder:text-sm text-sm`,
              saved
                ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor'
                : '!border-SeabiscuitLightThemeColorD3'
            )}
          />
        </div>
        <div
          className={clsx(
            'relative flex items-center w-full md:w-[22%] flex-1 min-w-[200px] border',
            saved
              ? 'border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor'
              : 'border-SeabiscuitLightThemeColorD3 rounded-xl'
          )}
        >
          <div className="pl-4 text-[14px]">Expires:</div>
          <Controller
            name={`${fieldKey}.endDate`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <CustomDatePicker
                onChange={onChange}
                onBlur={onBlur}
                selected={getReactPickerDate(value)}
                placeholderText="Select Date"
                disabled={saved}
                className="rounded-xl w-full selectWithSearch border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 pr-10 pl-2 !border-white h-[51px]"
              />
            )}
          />
          {!saved && (
            <KeyboardArrowDownIcon
              fontSize="medium"
              className="absolute z-2 top-1/2 -translate-y-1/2 pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
            />
          )}
        </div>

        <div
          className={clsx(
            'relative flex items-center w-full md:w-[22%] flex-1 min-w-[200px] border',
            saved
              ? 'border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor'
              : 'border-SeabiscuitLightThemeColorD3 rounded-xl'
          )}
        >
          <Controller
            control={control}
            name={`${fieldKey}.membershipStatus`}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <Select
                  onChange={onChange}
                  value={Status.find((c) => c.value === value || c.value === value)}
                  onBlur={onBlur}
                  options={Status}
                  isDisabled={saved}
                  placeholder={`${label ?? 'Number'} Status`}
                  className="rounded-xl w-full selectWithSearch border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 !border-white h-[51px]"
                  styles={customStyles as any}
                  isSearchable={true}
                />
              )
            }}
          />
        </div>
        <div className="relative flex items-center w-full md:w-[30%] flex-1 min-w-[230px]">
          {renderImageNumberInput(fieldKey)}
        </div>
        {onRemove && !saved && (
          <div
            className="absolute z-2 top-1/2 -translate-y-1/2 right-0 text-SeabiscuitLightParagraphTextColor"
            onClick={onRemove}
          >
            <img src="/assets/og_icons/Cancel.svg" className="h-6 w-6" alt="" />
          </div>
        )}
      </div>
    </div>
  )
}
