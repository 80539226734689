import { useContext, useEffect, useState } from 'react'

import { where } from 'firebase/firestore'
import { sortBy } from 'lodash'

import { PillButtonElement } from '../../../components/elements/buttons/pill/PillButtonElement'
import InfiniteScrollDataTable from '../../../components/common/tables/InfiniteScrollDataTable'
import NoDataAvailable from '../../myEvent/component/NoDataAvailable'

import { HandleModalContext } from '../../../layout/mainlayout/MainLayout'

import { useAppSelector } from '../../../store/hooks'
import { selectVisitedUserDetailsR } from '../../../store/profilePage/profilePageSlice'
import { selectAllUsers } from '../../../store/users/usersSlice'

import { myEventsTableStyles } from '../../myEvent/data/myEventsTableStyles'
import FirestoreService from '../../../services/firestoreService'

import { IEventInterface } from '../../../models/events/event.interface'
import { getConvertedData } from '../../../models/interface.helper'

import { EventModel } from '../../../models/events/event.model'
import { EventRegisteredUsersModel } from '../../../models/event-registered-users/event-registered-users.model'

// Constants
import { CONST } from '../../../const/const'
import { ALL_EVENT_LISTING_ROOT_PAGE_DATA } from './AllEventListingRootPage.data'
import { getRiders } from '../../../helpers/riders'
import ViewsLoader from '../../../components/loader/ViewsLoader'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

type IEventType = 'new-events' | 'past-events'

const TabData: { id: IEventType; title: string }[] = [
  { id: 'new-events', title: 'New events' },
  { id: 'past-events', title: 'Past events' },
]

export interface IEventInterfaceExtended extends IEventInterface {
  handleModal: ((showHide: boolean, typeOfModal: string, data?: any) => void) | null
  eventLogo: string
  riders?: number
}
const EventsProfileListing = () => {
  const [selectedTab, setSelectedTab] = useState<IEventType>('new-events')
  const [isPrivateProfile, setIsPrivateProfile] = useState(false)
  const [newEvents, setNewEvents] = useState<IEventInterfaceExtended[]>([])
  const [pastEvents, setPastEvents] = useState<IEventInterfaceExtended[]>([])
  const [events, setEvents] = useState<IEventInterfaceExtended[]>([])
  const [loading, setLoading] = useState(false)

  const handleModal = useContext(HandleModalContext)?.handleModal || null

  const visitedUserDetailsR = useAppSelector(selectVisitedUserDetailsR)
  const allUsers = useAppSelector(selectAllUsers)

  const getOrganizerEvents = async () => {
    setLoading(true)

    let events_: IEventInterfaceExtended[] = []
    const eventIds: string[] = []

    const eventsSnaps = await FirestoreService.filterItems(COLLECTIONS.EVENTS.NAME, [
      where(COLLECTIONS.EVENTS.FIELDS.OWNER.KEY, '==', visitedUserDetailsR?.profileDetails.id),
      where(COLLECTIONS.EVENTS.FIELDS.IS_PUBLISHED.KEY, '==', true),
    ])

    eventsSnaps.forEach((event) => {
      const current = getConvertedData(EventModel.fromFirestoreDoc(event).toObject())
      events_.push({
        ...current,
        handleModal,
        eventLogo: visitedUserDetailsR?.profileDetails?.userProfilePicture ?? '',
      })
    })

    events_.forEach((event) => event?.id && eventIds.push(event.id))
    const riders_ = await getRiders(eventIds)

    events_ = events_.map((event) => {
      const currentRiders = riders_.filter((rider) => rider.eventId === event.id)
      return { ...event, riders: currentRiders.length }
    })

    const sortedEvents = sortBy(
      events_,
      (event) => new Date(event.eventStartDate as string)
    ).reverse()

    setEvents(sortedEvents)
    setLoading(false)
  }

  const getCompetitorEvents = async () => {
    setLoading(true)

    const events_: IEventInterfaceExtended[] = []
    const eventIds: string[] = []

    const registeredEventsSnaps = await FirestoreService.filterItems(
      COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
      [
        where(
          COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.USER_ID.KEY,
          '==',
          visitedUserDetailsR?.profileDetails.id
        ),
        where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.IS_REGISTERED.KEY, '==', true),
      ]
    )

    registeredEventsSnaps.forEach((registered) => {
      const current = getConvertedData(
        EventRegisteredUsersModel.fromFirestoreDoc(registered).toObject()
      )
      if (current.eventId) eventIds.push(current.eventId)
    })

    events_.forEach((event) => event?.id && eventIds.push(event.id))
    const riders_ = await getRiders(eventIds)

    const eventsSnaps = await FirestoreService.getItemsUsingIds(COLLECTIONS.EVENTS.NAME, eventIds)

    eventsSnaps.forEach((event) => {
      const current = getConvertedData(EventModel.fromFirestoreDoc(event).toObject())
      const user = allUsers.find((registeredUser) => registeredUser.id === current.owner)
      const currentRiders = riders_.filter((rider) => rider.eventId === event.id)

      events_.push({
        ...current,
        handleModal,
        eventLogo: user?.userProfilePicture ?? '',
        riders: currentRiders.length,
      })
    })

    const sortedEvents = sortBy(
      events_,
      (event) => new Date(event.eventStartDate as string)
    ).reverse()

    setEvents(sortedEvents)
    setLoading(false)
  }

  useEffect(() => {
    if (visitedUserDetailsR?.profileDetails.id) {
      if (visitedUserDetailsR?.profileDetails.userType === CONST.USE_MODE.ORGANIZER) {
        getOrganizerEvents().then()
      } else {
        getCompetitorEvents().then()
      }
    }
  }, [visitedUserDetailsR?.profileDetails.id])

  useEffect(() => {
    setIsPrivateProfile(
      visitedUserDetailsR?.profileDetails.userAccountPrivacy && !visitedUserDetailsR?.isMyProfile
    )
  }, [visitedUserDetailsR?.profileDetails.id, visitedUserDetailsR])

  useEffect(() => {
    let pastEvents_: IEventInterfaceExtended[] = []
    let newEvents_: IEventInterfaceExtended[] = []

    events.forEach((event) => {
      if (event?.id && event.eventEndDate) {
        if (new Date(event.eventEndDate) < new Date()) {
          pastEvents_.push(event)
        }
        if (new Date(event.eventEndDate) >= new Date()) {
          newEvents_.push(event)
        }
      }
    })

    setPastEvents(pastEvents_)
    setNewEvents(newEvents_)
  }, [events])

  if (loading)
    return (
      <div className="flex justify-center items-center min-h-[300px]">
        <ViewsLoader
          className="flex items-center w-full justify-center min-h-[30vh] mt-3"
          size="lg"
          color="red"
        />
      </div>
    )

  return (
    <>
      <div className="flex-1 flex flex-col bg-SeabiscuitWhiteThemeColor pt-6 pb-2 px-6">
        <div className="max-w-full overflow-y-auto mb-4 flex w-fit pb-3">
          {TabData.map((item, index) => {
            return (
              <PillButtonElement
                key={`${JSON.stringify(item)}${index}`}
                text={item.title}
                Class="h-[45px] md:w-[200px] border-none text-SeabiscuitDark200ThemeColor"
                onButtonClicked={() => setSelectedTab(item.id)}
                value={item.id}
                selected={selectedTab === item.id}
              />
            )
          })}
        </div>

        {isPrivateProfile ? (
          <div className="flex w-full h-[300px] items-center justify-center flex-col">
            <span className="w-fit">This account is Private</span>
            <img
              src="/assets/modals/privatetab.svg"
              alt="privatetab"
              className="h-[140px] mt-3 mb-3"
            />
            <span className="text-SeabiscuitMainThemeColor w-fit">
              Connect With this member to view their profile
            </span>
          </div>
        ) : (
          <div className="relative flex-1 flex flex-col">
            <InfiniteScrollDataTable
              hasMore={false}
              fixedHeader={true}
              data={selectedTab === 'new-events' ? newEvents : pastEvents}
              columns={
                visitedUserDetailsR?.isOrganizerProfileView
                  ? ALL_EVENT_LISTING_ROOT_PAGE_DATA.COLS.ORGANIZER_EVENT_COLS
                  : ALL_EVENT_LISTING_ROOT_PAGE_DATA.COLS.EVENT_COLS
              }
              className="transition-all flex flex-col flex-1"
              noDataComponent={
                <NoDataAvailable
                  imgSrc={'assets/placeholders/NoEventsFoundProfile.svg'}
                  text="No event exists"
                  textColor="text-[#122B46]"
                />
              }
              customStyles={myEventsTableStyles}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default EventsProfileListing
