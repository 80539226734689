import { useEffect, useState } from 'react'
import InfiniteScrollDataTable from '../../../../components/common/tables/InfiniteScrollDataTable'
import { PillButtonElement } from '../../../../components/elements/buttons/pill/PillButtonElement'
import ViewsLoader from '../../../../components/loader/ViewsLoader'
import { IEventInterface } from '../../../../models/events/event.interface'
import { ALL_EVENT_LISTING_ROOT_PAGE_DATA } from '../../../allEvents/root/AllEventListingRootPage.data'
import NoDataAvailable from '../../../myEvent/component/NoDataAvailable'
import { myEventsTableStyles } from '../../../myEvent/data/myEventsTableStyles'

const TabData = [
  { id: 1, title: 'New events' },
  { id: 2, title: 'Past events' },
]

type IProps = {
  pastEvents: IEventInterface[]
  newEvents: IEventInterface[]
}

const HorseCompetetionDetailsCard = ({ pastEvents, newEvents }: IProps) => {
  const [activeTab, setActiveTab] = useState(1)
  const [events, setEvents] = useState<IEventInterface[]>([])

  useEffect(() => {
    setEvents(activeTab === 1 ? newEvents : pastEvents)
  }, [activeTab])

  return (
    <>
      <div className="flex-1 flex flex-col bg-SeabiscuitWhiteThemeColor pt-6 pb-2 px-6">
        <div className="max-w-full overflow-y-auto mb-4 flex w-fit pb-3">
          {TabData.map((item, index) => {
            return (
              <PillButtonElement
                key={`${JSON.stringify(item)}${index}`}
                text={item.title}
                Class="h-[45px] w-[200px] border-none text-SeabiscuitDark200ThemeColor"
                onButtonClicked={() => setActiveTab(item.id)}
                value={item.id}
                selected={activeTab === item.id}
              />
            )
          })}
        </div>
        <div className="relative flex-1 flex flex-col">
          <InfiniteScrollDataTable
            columns={ALL_EVENT_LISTING_ROOT_PAGE_DATA.COLS.HORSE_PROFILE_COLS}
            hasMore={false}
            fixedHeader={true}
            data={events}
            progressComponent={<ViewsLoader size="xl" color="red" />}
            noDataComponent={
              <NoDataAvailable
                imgSrc={'assets/placeholders/NoEventsFoundProfile.svg'}
                text="No event exists"
                textColor="text-[#122B46]"
              />
            }
            className="transition-all flex flex-col flex-1"
            customStyles={myEventsTableStyles}
            noHeader={true}
          />
        </div>
      </div>
    </>
  )
}

export default HorseCompetetionDetailsCard
