import React, { FC } from 'react'

import { Controller } from 'react-hook-form'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Control, UseFormRegister } from 'react-hook-form/dist/types/form'
import clsx from 'clsx'

import { getReactPickerDate } from '../../../models/interface.helper'
import CustomDatePicker from '../../../components/common/inputs/CustomDatePicker'

import { IHorseData } from '../../../models/horse/horse.interface'

interface HorseNumberItemProps {
  icon?: string
  label?: string
  titleKey?: `horsesOther.${number}.name`
  numberKey:
    | 'horseWdaaNumber'
    | 'horseUphaNumber'
    | 'horseAshaNumber'
    | 'horseArhpaNumber'
    | 'horseAmhaNumber'
    | 'horseAhhsNumber'
    | 'horseUshjaNumber'
    | 'horseUseaNumber'
    | 'horseUsdfNumber'
    | 'horseFeiNumber'
    | 'horseUsefNumber'
    | 'horsePassportNumber'
    | 'horseDNACaseNumber'
    | 'horseMicrochipNumber'
    | `horsesOther.${number}.number`
  expiresKey:
    | 'horseWdaaExpiration'
    | 'horseUphaExpiration'
    | 'horseAshaExpiration'
    | 'horseArhpaExpiration'
    | 'horseAmhaExpiration'
    | 'horseAhhsExpiration'
    | 'horseUshjaExpiration'
    | 'horseUseaExpiration'
    | 'horseUsdfExpiration'
    | 'horseFeiExpiration'
    | 'horseUsefExpiration'
    | 'horsePassportExpiration'
    | 'horseDNACaseExpiration'
    | 'horseMicrochipExpiration'
    | `horsesOther.${number}.date`
  saved: boolean
  register: UseFormRegister<IHorseData>
  control: Control<IHorseData>
  onRemove?: () => void
}

export const HorseNumberItem: FC<HorseNumberItemProps> = ({
  icon,
  label,
  titleKey,
  numberKey,
  expiresKey,
  saved,
  register,
  control,
  onRemove,
}) => {
  return (
    <div className="flex justify-between gap-2 flex-wrap items-center mt-2 max-w-[930px]">
      <label className="pr-8 flex items-center gap-2 text-[#122B46] md:w-[38%] flex-1">
        <img src={icon ?? '/assets/cp_icons/Hierarchy-1.svg'} className="w-6" alt="" />
        {titleKey ? (
          <input
            type="text"
            {...register(titleKey)}
            placeholder="Enter name..."
            disabled={saved}
            className={clsx(
              `w-full border border-SeabiscuitLightThemeColorD3 text-gray-900 text-sm rounded-xl focus:border-SeabiscuitLightThemeColorD3 focus:ring-0 p-3`,
              saved
                ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor'
                : '!border-SeabiscuitLightThemeColorD3'
            )}
          />
        ) : (
          <p className="p-3">{label}</p>
        )}
      </label>
      <div className="pr-8 gap-2 flex flex-wrap items-center justify-between relative w-full md:w-[60%]">
        <div className="w-full min-w-[200px] md:w-[48%] flex-1">
          <input
            type="text"
            {...register(numberKey)}
            placeholder={`Enter ${label ?? 'number'}...`}
            disabled={saved}
            className={clsx(
              `rounded-xl w-full border-SeabiscuitLightThemeColorD3 border focus:ring-SeabiscuitLightThemeColorD3 focus:border-SeabiscuitLightThemeColorD3 py-3 placeholder:text-[#122B4680] placeholder:text-sm text-sm`,
              saved
                ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor'
                : '!border-SeabiscuitLightThemeColorD3'
            )}
          />
        </div>
        <div
          className={clsx(
            'relative flex items-center w-full md:w-[48%] flex-1 min-w-[200px] border',
            saved
              ? 'border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor'
              : 'border-SeabiscuitLightThemeColorD3 rounded-xl'
          )}
        >
          <div className="pl-4 text-[14px]">Expires:</div>
          <Controller
            name={expiresKey}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <CustomDatePicker
                onChange={onChange}
                onBlur={onBlur}
                selected={getReactPickerDate(value)}
                placeholderText="Select Date"
                disabled={saved}
                className={`rounded-xl w-full selectWithSearch border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 pr-10 pl-2 !border-white h-[51px]`}
              />
            )}
          />
          {!saved && (
            <KeyboardArrowDownIcon
              fontSize="medium"
              className="absolute z-2 top-1/2 -translate-y-1/2 pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
            />
          )}
        </div>
        {onRemove && !saved && (
          <div
            className="absolute z-2 top-1/2 -translate-y-1/2 right-0 text-SeabiscuitLightParagraphTextColor"
            onClick={onRemove}
          >
            <img src="/assets/og_icons/Cancel.svg" className="h-6 w-6" alt="" />
          </div>
        )}
      </div>
    </div>
  )
}
