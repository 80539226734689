import React, { FC, useContext, useEffect, useState } from 'react'

import { Tooltip } from '@mui/material'
import { where } from 'firebase/firestore'
import { useParams } from 'react-router'

import TooltipIcon from '../../../../../helpers/TooltipIcon'
import { IconUser } from '../../../../../components/icons/IconUser'
import { NoProfileData } from './NoProfileData'
import { Authentication } from '../../../../../components/authentication'
import { ProfileFields } from './ProfileFields'

import { selectHorses, setHorses } from '../../../../../store/horses/horseSlice'
import {
  selectRegistrationByDay,
  selectTeamTabData,
  setTeamTabData,
} from '../../../../../store/registration/registrationSlice'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { selectIsLoggedIn } from '../../../../../store/user/userSlice'
import { selectAllUsers } from '../../../../../store/users/usersSlice'
import { selectedEvent } from '../../../../../store/events/eventsSlice'

import { getUserFullName } from '../../../../../helpers/helpers'
import { HandleModalContext } from '../../../../../layout/mainlayout/MainLayout'
import FirestoreService from '../../../../../services/firestoreService'
import { getConvertedData } from '../../../../../models/interface.helper'

import { IUserInterface } from '../../../../../models/users/user.interface'
import { IHorseData } from '../../../../../models/horse/horse.interface'
import { IUserHorseMappingInterface } from '../../../../../models/user-horse-mapping/userHorseMapping.interface'
import { UserHorseMappingModel } from '../../../../../models/user-horse-mapping/userHorseMapping.model'
import ITypes from '../../../event-registration-tabs/hooks/useEventRegistrationTabs.types'

import { ITEAMMEMBER_MODAL_TYPES } from '../../../../../components/home/modals/UserProfileTeamTabAddTeamMember'
import { MODAL_CONSTS } from '../../../../../const/modal-const'
import { CONST } from '../../../../../const/const'
import ViewsLoader from '../../../../../components/loader/ViewsLoader'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

interface PeoplesProps {
  teamTabDataWithUser: IUserInterface[]
  mainLoading: boolean
}
export const Peoples: FC<PeoplesProps> = ({ teamTabDataWithUser, mainLoading }) => {
  const dispatch = useAppDispatch()
  const registrationByDay = useAppSelector(selectRegistrationByDay)

  const { userId } = useParams<ITypes['IParams']>()

  const handleModalContext = useContext(HandleModalContext)
  const handleModal = handleModalContext?.handleModal
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const users = useAppSelector(selectAllUsers)
  const teamTabData = useAppSelector(selectTeamTabData)
  const { Event } = useAppSelector(selectedEvent)
  const myHorses = useAppSelector(selectHorses)
  const [authMeHorses, setAuthMeHorses] = useState<IUserHorseMappingInterface[]>([])

  const getAuthMeHorses = async () => {
    try {
      const authHorseData: IUserHorseMappingInterface[] = []
      const horseMappingSnap = await FirestoreService.filterItems(
        COLLECTIONS.USER_HORSE_MAPPING.NAME,
        [where(COLLECTIONS.USER_HORSE_MAPPING.FIELDS.HORSE_OWNER_ID.KEY, '==', userId)]
      )

      horseMappingSnap.forEach((currSnap) =>
        authHorseData.push(
          getConvertedData(UserHorseMappingModel.fromFirestoreDoc(currSnap).toObject())
        )
      )
      if (authHorseData[0]) setAuthMeHorses(authHorseData)
    } catch (err) {
      console.error('err', err)
    }
  }
  useEffect(() => {
    if (Event?.id && userId) {
      getAuthMeHorses().then()
    }
  }, [Event?.id, userId])

  const removeHandler = (id: string) => {
    let userIsRegistered = false
    if (registrationByDay) {
      userIsRegistered = !!registrationByDay.find((rider) => rider.riderId === id)
    }

    handleModalContext?.handleModal?.(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
      removeItem: () => {
        const newTeam = teamTabData.filter((user) => user.memberId !== id)
        dispatch(setTeamTabData(newTeam))
      },
      isBtnOk: userIsRegistered,
      alertText: userIsRegistered ? (
        <>
          <h5 className="text-xl text-center mb-10 leading-relaxed font-semibold">
            The rider is already registered. <br /> You can remove them in the next step.
          </h5>
        </>
      ) : (
        <div>
          <h5 className="font-semibold text-xl mb-10">
            Are you sure you want to remove this rider?
          </h5>
        </div>
      ),
    })
  }

  if (teamTabDataWithUser.length <= 0 && isLoggedIn) {
    return (
      <NoProfileData
        title={'You have not created a profile'}
        createHandler={() => {
          handleModal?.(true, MODAL_CONSTS.ADD_NEW_TEAM_MEMBER)
        }}
        btnCreateTitle={'Create profile to register >'}
      />
    )
  }

  if (!isLoggedIn) {
    return (
      <div className={'flex items-center justify-center pt-10'}>
        <Authentication
          handleModal={handleModal}
          registerHandler={() => {
            handleModal?.(true, MODAL_CONSTS.ADD_NEW_TEAM_MEMBER, { isLogin: true })
          }}
        />
      </div>
    )
  }

  if (mainLoading) {
    return (
      <div className="w-full lg:w-[48%] flex border flex-col rounded-md border-[#D3DAEE] p-4 flex-1 gap-5 pb-[300px]">
        <ViewsLoader
          className="flex items-center md:w-80 w-full justify-center my-3"
          size="md"
          color="red"
        />
      </div>
    )
  }

  return (
    <div className="w-full lg:w-[48%] flex border flex-col rounded-md border-[#D3DAEE] p-4 gap-2.5 min-h-[300px]">
      <div className="flex w-full justify-between">
        <div>
          <div className="flex items-center">
            <h3 className="text-[#122B46] text-[22px] font-bold mr-1">People</h3>
            <Tooltip title={''} placement="top" arrow>
              <button
                type="button"
                className=""
                onClick={() => handleModal?.(true, MODAL_CONSTS.REGISTRATION_PEOPLE)}
              >
                <TooltipIcon color="#122B46" />
              </button>
            </Tooltip>
          </div>
          <p className={'text-[#122B4680] text-[14px]'}>
            Add all team members included in registration
          </p>
        </div>
        <div className={'flex gap-5'}>
          {isLoggedIn && (
            <div className={'flex flex-col text-right'}>
              <span className={'text-[#122B4680] text-[12px]'}>Team</span>
              <button
                disabled={mainLoading}
                onClick={() => {
                  const userTeamMembers: IUserInterface[] = []
                  const teamMembers =
                    users.find((user) => user.id === userId)?.userTeamMembers ?? []

                  ;[...teamMembers, ...teamTabData].forEach((member) => {
                    const current = users.find((user) => user.id === member.memberId)
                    const isExist = userTeamMembers.find((user) => user.id === member.memberId)
                    if (current && !isExist) userTeamMembers.push(current)
                  })

                  const competitor = users.find((user) => user.id === userId)

                  handleModal?.(true, MODAL_CONSTS.REGISTER_TEAM, {
                    modal_type: ITEAMMEMBER_MODAL_TYPES.TEAM,
                    userTeamMembers: userTeamMembers,
                    users,
                    competitor,
                    authorizeUserHorses: authMeHorses,
                    createCb: (horse: IHorseData) => {
                      dispatch(setHorses([...myHorses, horse]))
                      handleModal?.(false, MODAL_CONSTS.ADD_NEW_HORSE_MEMBER)
                    },
                  })
                }}
                className={'text-[14px] text-[#122B46] underline hover:no-underline'}
              >
                Add person
              </button>
            </div>
          )}

          <div
            className={'flex w-[45px] rounded-md h-[45px] items-center justify-center bg-[#F6F7FB]'}
          >
            <IconUser />
          </div>
        </div>
      </div>
      {teamTabDataWithUser.map((item, index) => {
        return (
          <ProfileFields
            eventRequiredFields={Event?.requiredFields || []}
            key={`${item.id} + ${index}`}
            user={item}
            editHandler={(data) => {
              handleModal?.(true, MODAL_CONSTS.UPDATE_USER_NUMBER, {
                data,
                userData: item,
              })
            }}
            headerImgSrc={item?.userProfilePicture || ''}
            headerTitle={getUserFullName(item)}
            headerSubTitle={`@${item.userName}`}
            teamTabDataWithUser={teamTabDataWithUser}
            removeHandler={() => removeHandler(item.id)}
            isDeleteBtnHide={item.id === userId}
          />
        )
      })}
    </div>
  )
}
