export const Sheet2023USDFIntroductoryLevelTestAWalkTrot = {
  id: 'Sheet2023USDFIntroductoryLevelTestAWalkTrot',
  name: '2023 USDF INTRODUCTORY LEVEL – TEST A WALK—TROT',
  furtherRemarks: '',
  type: 'score-sheet-1',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 90,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter, working trot rising</p><p><span>X & C •</span> Medium walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track right</p><p><span>M •</span> Working trot rising</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p><span>A •</span> Circle right 20 meters, working trot rising</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p><span>K-X-M •</span> Change rein, working trot rising</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description: '<p><span>C •</span> Circle left 20 meters, working trot rising</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description: '<p><span>Between C & H •</span> Medium walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description: '<p><span>H-X-F •</span> Free walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>F-A •</span> Medium walk</p><p><span>A •</span> Down centerline</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>X •</span> Halt and salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena in free walk. Exit at A.',
    },
    {
      name: 'Collective Marks',
      max: 70,
      type: 'mark',
      rows: [
        {
          description: '<h5>Gaits</h5><p>Freedom and regularity</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Impulsion</h5><p>Desire to move forward with suppleness of the back and steady tempo</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Submission</h5><p>Acceptance of steady contact, attention, and confidence</p>',
          score: '',
          coefficient: 2,
        },
        {
          description: '<h5>Riders Pos.</h5><p>Keeping in balance with horse</p>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Use of aids</h5><p>Correct bend and preparation of transitions</p>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Geo. & Acc.</h5><p>Correct size and shape of circles and turns</p>',
          score: '',
          coefficient: 1,
        },
      ],
    },
  ],
}

export const Sheet2023USDFIntroductoryLevelTestB = {
  id: 'Sheet2023USDFIntroductoryLevelTestB',
  name: '2023 USDF INTRODUCTORY LEVEL – TEST B',
  furtherRemarks: '',
  type: 'score-sheet-1',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 90,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter working trot rising</p><p><span>X •</span> Halt through medium walk • Salute - Proceed working trot rising</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description: '<p><span>C •</span> Track left, working trot rise</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p><span>E •</span> Circle left 20 meters, working trot rising</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p><span>Between K & A •</span> Medium Walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description: '<p><span>F-E •</span> Free Walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description: '<p><span>E-H •</span> Free walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description: '<p><span>Between H & C •</span> Working Trot Rising</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description: '<p><span>B •</span> Circle right 20m, working trot risig</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>A •</span> Down centerline</p><p><span>X •</span> Halt through medium walk, Salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena in free walk. Exit at A.',
    },
    {
      name: 'Collective Marks',
      max: 70,
      type: 'mark',
      rows: [
        {
          description: '<h5>Gaits</h5><p>Freedom and regularity</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Impulsion</h5><p>Desire to move forward with suppleness of the back and steady tempo</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Submission</h5><p>Acceptance of steady contact, attention, and confidence</p>',
          score: '',
          coefficient: 2,
        },
        {
          description: '<h5>Riders Pos.</h5><p>Keeping in balance with horse</p>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Use of aids</h5><p>Correct bend and preparation of transitions</p>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Geo. & Acc.</h5><p>Correct size and shape of circles and turns</p>',
          score: '',
          coefficient: 1,
        },
      ],
    },
  ],
}

export const Sheet2023USDFIntroductoryLevelTestCWalkTrotCanter = {
  id: 'Sheet2023USDFIntroductoryLevelTestCWalkTrotCanter',
  name: '2023 USDF INTRODUCTORY LEVEL – TEST C WALK—TROT - CANTER',
  furtherRemarks: '',
  type: 'score-sheet-1',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 130,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter working trot rising</p><p><span>X •</span> Halt through medium walk • Salute - Proceed working\n' +
            'trot rising</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description: '<p><span></span> Track right, working trot rise</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p><span>B •</span> Circle left 20 meters, working trot rising</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>A •</span> Circle right 20m, developing working canter in 1st qtr of the circle, right lead</p><p><span>Before A •</span> Working trot rising</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description: '<p><span>N/A •</span> Transition in and out of canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description: '<p><span>K-X-M •</span> Changing rein, working trot rising</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description: '<p><span>E •</span> Circle left 20m, working trot rising</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>A •</span> Circle left 20m, developing working canter in 1st qtr of the circle, left lead</p><p><span>Before A •</span> Working trot rising</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>N/A •</span> Transition in and out of canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description: '<p><span>Between F & B •</span> Medium walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p><span>B-H •</span> Free walk</p><p><span>H •</span> Medium walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description: '<p><span>Between C & M •</span> Working trot rising to A</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>A •</span> Down centerline</p><p><span>X •</span> Halt through medium walk, Salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena in free walk. Exit at A.',
    },
    {
      name: 'Collective Marks',
      max: 70,
      type: 'mark',
      rows: [
        {
          description: '<h5>Gaits</h5><p>Freedom and regularity</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Impulsion</h5><p>Desire to move forward with suppleness of the back and steady tempo</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Submission</h5><p>Acceptance of steady contact, attention, and confidence</p>',
          score: '',
          coefficient: 2,
        },
        {
          description: '<h5>Riders Pos.</h5><p>Keeping in balance with horse</p>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Use of aids</h5><p>Correct bend and preparation of transitions</p>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Geo. & Acc.</h5><p>Correct size and shape of circles and turns</p>',
          score: '',
          coefficient: 1,
        },
      ],
    },
  ],
}

export const Sheet2023USEFTrainingLevelTest1 = {
  id: 'Sheet2023USEFTrainingLevelTest1',
  name: '2023 USEF TRAINING LEVEL TEST 1',
  furtherRemarks: '',
  type: 'score-sheet-1',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 190,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter working trot</p><p><span>X •</span> Halt, salute</p><p><span>N/A •</span Proceed working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description: '<p><span>C •</span> Track left</p><p><span>E •</span> Circle left 20m</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>A •</span> Circle left 20m developing left lead canter in first quarter of circle</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p><span>A - F - B •</span> Working canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description: '<p><span>Between B & M •</span> Working trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 6,
          description: '<p><span>Between C & H •</span> Medium walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>E - F •</span> Change rein, free walk</p><p><span>F •</span> Medium walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 8,
          description: '<p><span>A •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>E •</span> Circle right 20m</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>C •</span> Circle right 20m developing right lead canter in first quarter of circle</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p><span>C - M - B •</span> Working canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description: '<p><span>Between B & F •</span> Working trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>A •</span> Down centerline</p><p><span>X •</span> Halt, salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in free walk.',
    },
    {
      name: 'Collective Marks',
      max: 70,
      type: 'mark',
      rows: [
        {
          description: '<h5>Gaits</h5><p>Freedom and regularity</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Impulsion</h5><p>Desire to move forward; elasticity of the steps; suppleness of the back; engagement of the hindquarters</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Submission</h5><p>Willing cooperation; harmony; attention and confidence; acceptance of bit and aids; straightness; lightness of forehand and ease of movements</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Riders Pos.</h5><p>Alignment; posture; stability; weight placement; following mechanics of the gaits</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Use of aids</h5><p>Clarity; subtlety; independence; accuracy of test</p>',
          score: '',
          coefficient: 1,
        },
      ],
    },
  ],
}

export const Sheet2023USEFTrainingLevelTest2 = {
  id: 'Sheet2023USEFTrainingLevelTest2',
  name: '2023 USEF TRAINING LEVEL TEST 2',
  furtherRemarks: '',
  type: 'score-sheet-1',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 220,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter working trot</p><p><span>X •</span> Halt, salute</p><p><span>N/A •</span> Proceed working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track right</p><p><span>B •</span> Circle right 20m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p><span>K - X - M •</span> Change rein</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 4,
          description: '<p><span>Between C & H •</span> Working canter left lead</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 5,
          description: '<p><span>E•</span> Circle left 20m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description: '<p><span>Between E & K •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>A •</span> Circle left 20m rising trot, allowing the horse to stretch forward and downward while maintaining contact</p><p><span>Before A •</span>  Shorten the reins</p><p><span>A •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>F •</span> Medium walk</p><p><span>F - E •</span> Change rein, medium walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>E - M •</span> Change rein, free walk</p><p><span>M •</span> Medium walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 10,
          description: '<p><span>C •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p><span>E •</span> Circle left 20m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description: '<p><span>F - X - H •</span> Changing rein</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 13,
          description: '<p><span>Between C&M •</span> Working canter right lead</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 14,
          description: '<p><span>B •</span> Circle right 20m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description: '<p><span>Between B&F •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>A •</span> Down centerline</p><p><span>X •</span> Halt, salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in free walk.',
    },
    {
      name: 'Collective Marks',
      max: 70,
      type: 'mark',
      rows: [
        {
          description: '<h5>Gaits</h5><p>Freedom and regularity</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Impulsion</h5><p>Desire to move forward; elasticity of the steps; suppleness of the back; engagement of the hindquarters)</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Submission</h5><p>Willing cooperation; harmony; attention and confidence; acceptance of bit and aids; straightness; lightness of forehand and ease of movements</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Riders Pos.</h5><p>Alignment; posture; stability; weight placement; following mechanics of the gaits</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Use of aids</h5><p>Clarity; subtlety; independence; accuracy of test</p>',
          score: '',
          coefficient: 1,
        },
      ],
    },
  ],
}

export const Sheet2023USEFTrainingLevelTest3 = {
  id: 'Sheet2023USEFTrainingLevelTest3',
  name: '2023 USEF TRAINING LEVEL TEST 3',
  furtherRemarks: '',
  type: 'score-sheet-1',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 180,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter working trot</p><p><span>X •</span> Halt, salute</p><p><span>N/A •</span> Proceed working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track left</p><p><span>H - X - K •</span> Slightly after H begin a single loop to X returning to the track slightly before K </p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 3,
          description: '<p><span>Between A & F •</span> Working canter left lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p><span>B •</span> Circle left 20m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>H - X - F •</span> Change rein</p><p><span>X •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description: '<p><span>A •</span> Medium walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>K - X - H •</span> Free walk</p><p><span>H •</span> Medium walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>C •</span> Working trot</p><p><span>M - X - F •</span> Slightly after M begin a single loop to X returning to the track slightly before F</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>Between A&K •</span> Working canter right lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description: '<p><span>E •</span> Circle right 20m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p><span>C •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>B •</span> Circle right 20m in rising trot allowing the horse to stretch forward and downward</p><p><span>Before B •</span> Shorten the reins</p><p><span>B •</span> Working trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>A •</span> Down centerline</p><p><span>X •</span>  Halt, salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in free walk.',
    },
    {
      name: 'Collective Marks',
      max: 70,
      type: 'mark',
      rows: [
        {
          description: '<h5>Gaits</h5><p>Freedom and regularity</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Impulsion</h5><p>Desire to move forward; elasticity of the steps; suppleness of the back; engagement of the hindquarters)</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Submission</h5><p>Willing cooperation; harmony; attention and confidence; acceptance of bit and aids; straightness; lightness of forehand and ease of movements</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Riders Pos.</h5><p>Alignment; posture; stability; weight placement; following mechanics of the gaits</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Use of aids</h5><p>Clarity; subtlety; independence; accuracy of test</p>',
          score: '',
          coefficient: 1,
        },
      ],
    },
  ],
}

export const Sheet2023USEFFirstLevelTest1 = {
  id: 'Sheet2023USEFFirstLevelTest1',
  name: '2023 USEF FIRST LEVEL TEST 1',
  furtherRemarks: '',
  type: 'score-sheet-1',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 200,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter working trot</p><p><span>X •</span> Halt, salute</p><p><span>N/a •</span> Proceed working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track left</p><p><span>E - X •</span> Half circle left 10m, returning to track at H</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p><span>B - X •</span> Half circle right 10m, returning to track at M</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>C •</span> Circle left 20m in rising trot, allowing the horse to stretch forward and downward while maintaining contact</p><p><span>Before C •</span> Shorten the reins</p><p><span>C •</span> Working trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>H - P •</span> Change rein, lengthen stride in trot </p><p><span>P •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description: '<p><span>A •</span> Medium walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>V - R •</span> Change rein, free walk</p><p><span>R •</span> Medium walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>M •</span> Working trot</p><p><span>C •</span> Working canter left lead</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>E •</span> Circle left 15m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>F - X - H •</span> Change rein</p><p><span>X •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p><span>C •</span> Working canter right lead</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 12,
          description: '<p><span>B •</span> Circle right 15m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description: '<p><span>A •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p><span>K - R •</span> Change rein, lengthen stride in trot</p><p><span>R •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>E •</span> Half circle left 10m</p><p><span>X •</span> Down centerline</p><p><span>G •</span> Halt, salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in free walk.',
    },
    {
      name: 'Collective Marks',
      max: 70,
      type: 'mark',
      rows: [
        {
          description: '<h5>Gaits</h5><p>Freedom and regularity</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Impulsion</h5><p>Desire to move forward; elasticity of the steps; suppleness of the back; engagement of the hindquarters)</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Submission</h5><p>Willing cooperation; harmony; attention and confidence; acceptance of bit and aids; straightness; lightness of forehand and ease of movements</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Riders Pos.</h5><p>Alignment; posture; stability; weight placement; following mechanics of the gaits</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Use of aids</h5><p>Clarity; subtlety; independence; accuracy of test</p>',
          score: '',
          coefficient: 1,
        },
      ],
    },
  ],
}

export const Sheet2023USEFFirstLevelTest2 = {
  id: 'Sheet2023USEFFirstLevelTest2',
  name: '2023 USEF FIRST LEVEL TEST 2',
  furtherRemarks: '',
  type: 'score-sheet-1',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 260,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter working trot</p><p><span>X •</span> Halt, salute</p><p><span>N/a •</span> Proceed working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track right</p><p><span>M - V •</span> Change rein, lengthen stride in trot</p><p><span>V •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>A •</span> Turn down centerline</p><p><span>L - M •</span> Leg yield right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>H - P •</span> Change rein, lengthen stride in trot</p><p><span>P •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>A •</span> Turn down centerline</p><p><span>L - H •</span> Leg yield left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 6,
          description: '<p><span>C •</span> Medium walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>M - V •</span> Change rein, free walk</p><p><span>V •</span> Medium walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>K •</span> Working trot</p><p><span>A •</span> Working canter left lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>P •</span> Circle left 15m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description: '<p><span>P - M •</span> Lengthen stride in canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 11,
          description: '<p><span>Between M - C •</span> Develop working canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>H - X - F •</span> Change rein</p><p><span>X •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description: '<p><span>F •</span> Working canter right lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description: '<p><span>V •</span> Circle right 15m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description: '<p><span>V - H •</span> Lengthen stride in canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 16,
          description: '<p><span>Between H - C •</span> Develop working canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description: '<p><span>M •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>B •</span> Circle right 20m rising trot, allowing the horse to stretch forward and downward while maintaining contact</p><p><span>Before B •</span> Shorten the reins</p><p><span>B •</span> Working trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>A •</span> Down centerline</p><p><span>X •</span> Halt, salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in free walk.',
    },
    {
      name: 'Collective Marks',
      max: 70,
      type: 'mark',
      rows: [
        {
          description: '<h5>Gaits</h5><p>Freedom and regularity</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Impulsion</h5><p>Desire to move forward; elasticity of the steps; suppleness of the back; engagement of the hindquarters)</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Submission</h5><p>Willing cooperation; harmony; attention and confidence; acceptance of bit and aids; straightness; lightness of forehand and ease of movements</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Riders Pos.</h5><p>Alignment; posture; stability; weight placement; following mechanics of the gaits</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Use of aids</h5><p>Clarity; subtlety; independence; accuracy of test</p>',
          score: '',
          coefficient: 1,
        },
      ],
    },
  ],
}

export const Sheet2023USEFFirstLevelTest3 = {
  id: 'Sheet2023USEFFirstLevelTest3',
  name: '2023 USEF FIRST LEVEL TEST 3',
  furtherRemarks: '',
  type: 'score-sheet-1',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 290,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter working trot</p><p><span>X •</span> Halt, salute</p><p><span>N/A •</span> Proceed working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track left</p><p><span>H - X - F •</span> Change rein, lengthen stride in trot</p><p><span>F •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p><span>V - I •</span> Leg yield right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>I •</span> Circle left 10m</p><p><span>I •</span> Straight ahead</p><p><span>C •</span> Track right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>B •</span> Circle right 20m rising trot, allowing the horse to stretch forward and downward while maintaining contact</p><p><span>Before B •</span> Shorten the reins</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 6,
          description: '<p><span>A •</span> Working canter right lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>K - X - H •</span> Slightly after K begin a single loop to X returning to the track slightly before H, maintaining lead</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 8,
          description: '<p><span>C •</span> Circle right 15m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>M - F •</span> Lengthen stride in canter</p><p><span>F •</span> Working canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>K - X - M •</span> Change rein</p><p><span>X •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p><span>C •</span> Medium walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>H - X - K •</span> Free walk</p><p><span>K •</span> Medium walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 13,
          description: '<p><span>A •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description: '<p><span>P - I •</span> Leg yield left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>I •</span> Circle right 10m</p><p><span>I •</span> Straight ahead</p><p><span>C •</span> Track right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>M - X - K •</span> Lengthen stride in trot</p><p><span>K •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description: '<p><span>A •</span> Working canter left lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>F - X - M •</span> Slightly after F begin a single loop to X returning to the track slightly before M, maintaining lead</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 19,
          description: '<p><span>C •</span> Circle left 15m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>H - K •</span> Lengthen stride in canter</p><p><span>K •</span> Working canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>F - X - H •</span> Change rein</p><p><span>X •</span> Change of lead through trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>C •</span> Working trot</p><p><span>B •</span> Half circle right 10m</p><p><span>X •</span> Down centerline</p><p><span>G •</span> Halt, salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in free walk.',
    },
    {
      name: 'Collective Marks',
      max: 70,
      type: 'mark',
      rows: [
        {
          description: '<h5>Gaits</h5><p>Freedom and regularity</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Impulsion</h5><p>Desire to move forward; elasticity of the steps; suppleness of the back; engagement of the hindquarters)</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Submission</h5><p>Willing cooperation; harmony; attention and confidence; acceptance of bit and aids; straightness; lightness of forehand and ease of movements</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Riders Pos.</h5><p>Alignment; posture; stability; weight placement; following mechanics of the gaits</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Use of aids</h5><p>Clarity; subtlety; independence; accuracy of test</p>',
          score: '',
          coefficient: 1,
        },
      ],
    },
  ],
}

export const Sheet2023USEFSecondLevelTest1 = {
  id: 'Sheet2023USEFSecondLevelTest1',
  name: '2023 USEF SECOND LEVEL TEST 1',
  furtherRemarks: '',
  type: 'score-sheet-1',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 280,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter collected trot</p><p><span>X •</span> Halt, salute</p><p><span>N/A •</span> Proceed collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track left</p><p><span>H - P •</span> Change rein, medium trot</p><p><span>P •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>N/A •</span> Transition H and P</p><p><span>P - F - A - K •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>K - E •</span> Shoulder in right</p><p><span>E •</span> Turn right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>B •</span> Turn left</p><p><span>B - M •</span> Shoulder in left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>C •</span> Halt, rein back 3 to 4 steps</p><p><span>N/A •</span> Proceed medium walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 7,
          description: '<p><span>C - S •</span> Medium walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>S - F •</span> Change rein, free, walk</p><p><span>F •</span> Medium walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>A •</span> Collected canter right lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>K - S •</span> Medium canter</p><p><span>S •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p><span>C •</span> Circle right 10 m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>M - E •</span> Change rein</p><p><span>E - V •</span> Counter canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 13,
          description: '<p><span>V •</span> Collected trot</p><p><span>K •</span> Medium walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description: '<p><span>A •</span> Collected canter left lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>F - R •</span> Medium canter</p><p><span>R •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description: '<p><span>C •</span> Circle left 10m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>H - B •</span> Change rein</p><p><span>B - P •</span> Counter canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 18,
          description: '<p><span>P •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>K - R •</span> Medium trot</p><p><span>R •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>N/A •</span> Transitions K and R</p><p><span>R - M - C - H - S •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>S •</span> Turn left</p><p><span>I •</span> Turn left</p><p><span>G •</span> Halt, salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in free walk.',
    },
    {
      name: 'Collective Marks',
      max: 70,
      type: 'mark',
      rows: [
        {
          description: '<h5>Gaits</h5><p>Freedom and regularity</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Impulsion</h5><p>Desire to move forward; elasticity of the steps; suppleness of the back; engagement of the hindquarters)</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Submission</h5><p>Willing cooperation; harmony; attention and confidence; acceptance of bit and aids; straightness; lightness of forehand and ease of movements</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Riders Pos.</h5><p>Alignment; posture; stability; weight placement; following mechanics of the gaits</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Use of aids</h5><p>Clarity; subtlety; independence; accuracy of test</p>',
          score: '',
          coefficient: 1,
        },
      ],
    },
  ],
}

export const Sheet2023USEFSecondLevelTest2 = {
  id: 'Sheet2023USEFSecondLevelTest2',
  name: '2023 USEF SECOND LEVEL TEST 2',
  furtherRemarks: '',
  type: 'score-sheet-1',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 320,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter collected trot</p><p><span>X •</span> Halt, salute</p><p><span>N/A •</span> Proceed collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description: '<p><span>C •</span> Track right</p><p><span>M - V •</span> Medium trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>N/A•</span> (Transitions M and V)</p><p><span>V - K - A - F •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>F - B •</span> Shoulder in left</p><p><span>B - X •</span> Half circle left 10m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>X - E •</span> Half circle right 10m</p><p><span>E - H •</span> Shoulder in right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description: '<p><span>M - P •</span> Medium trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>N/A •</span> (Transitions M and P)</p><p><span>P - F - A - K •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>K - E •</span> Travers right</p><p><span>E - X •</span> Half circle right 10m</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>X - B •</span> Half circle left 10m</p><p><span>B - M •</span> Travers left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>C •</span> Medium walk</p><p><span>H •</span> Turn left</p><p><span>Between G & M •</span> Collect and half turn on haunches left. Proceed medium walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description:
            '<p><span>Between G & H •</span> Collect and half turn on haunches right. Proceed medium walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>N/A •</span> (Medium walk)</p><p><span>N/A •</span> [CHG(M)G(H)GMR]</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>M •</span> Turn right</p><p><span>R - K •</span> Free walk</p><p><span>K - A •</span> Medium walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 14,
          description: '<p><span>A •</span> Collected canter left lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>F - R •</span> Medium canter</p><p><span>R •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>N/A •</span> (Transitions F and R)</p><p><span>R - M - C •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description: '<p><span>C - A •</span> Score for first simple change)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 18,
          description: '<p><span>C - A •</span> (Score for second simple change)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 19,
          description: '<p><span>C - A •</span> (Score for quality of serpentine)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>F - E •</span> Change rein</p><p><span>E - H •</span> Counter canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>H •</span> Medium walk</p><p><span>Before C •</span> Collected canter right lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>M - P •</span> Medium canter</p><p><span>P •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description:
            '<p><span>N/A •</span> (Transitions M and P)</p><p><span>P - F - A - K •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 24,
          description:
            '<p><span>K - B •</span> Change rein</p><p><span>B - M •</span> Counter canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 25,
          description: '<p><span>M •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 26,
          description:
            '<p><span>E •</span> Turn left</p><p><span>X •</span> Turn left</p><p><span>G •</span> Halt, salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in free walk.',
    },
    {
      name: 'Collective Marks',
      max: 70,
      type: 'mark',
      rows: [
        {
          description: '<h5>Gaits</h5><p>Freedom and regularity</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Impulsion</h5><p>Desire to move forward; elasticity of the steps; suppleness of the back; engagement of the hindquarters)</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Submission</h5><p>Willing cooperation; harmony; attention and confidence; acceptance of bit and aids; straightness; lightness of forehand and ease of movements</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Riders Pos.</h5><p>Alignment; posture; stability; weight placement; following mechanics of the gaits</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Use of aids</h5><p>Clarity; subtlety; independence; accuracy of test</p>',
          score: '',
          coefficient: 1,
        },
      ],
    },
  ],
}

export const Sheet2023USEFSecondLevelTest3 = {
  id: 'Sheet2023USEFSecondLevelTest3',
  name: '2023 USEF SECOND LEVEL TEST 3',
  furtherRemarks: '',
  type: 'score-sheet-1',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 350,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter collected trot</p><p><span>X •</span> Halt, salute. </p><p><span>N/A •</span> Proceed collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track left</p><p><span>H - X - F •</span> Change rein, medium trot</p><p><span>F •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>N/A •</span> (Transitions H and F)</p><p><span>F - A - K •</span> Collected trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 4,
          description: '<p><span>K - E •</span> Shoulder in right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description: '<p><span>E •</span> Circle right 10m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description: '<p><span>E - H •</span> Travers right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>M - X - K •</span> Change rein, medium trot</p><p><span>K •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>N/A •</span> (Transitions M and K)</p><p><span>K - A - F •</span> Collected trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>F - B •</span> Shoulder-in left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description: '<p><span>B •</span> Circle left 10m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p><span>B - M •</span> Travers left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description: '<p><span>C •</span> Halt, rein back 3 to 4 steps. Proceed medium walk.</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>H •</span> Turn left</p><p><span>Between •</span> Collect and half turn on haunches left</p><p><span>G & M •</span> Proceed medium walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p><span>Between G & H •</span> Collect and half turn on haunches right. Process medium walk.</p><p><span>M •</span> Turn right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>N/A •</span> (Medium walk)</p><p><span>N/A •</span> [CHG(M)G(H)GMR]</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>R - V •</span> Change rein, free walk</p><p><span>V •</span> Medium walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 17,
          description: '<p><span>K •</span> Collected canter left lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>F - M •</span> Medium canter</p><p><span>M •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>N/A •</span> (Transitions F and M)</p><p><span>M - C •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>C - A •</span> Serpentine 3 equal loops, width of the arena, no change of lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>F - E •</span> Change rein</p><p><span>L •</span> Simple change</p><p><span>E - H - C - M •</span> Collected canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>M - F •</span> Medium canter</p><p><span>F •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description:
            '<p><span>N/A •</span> (Transitions at M and F)</p><p><span>F - A •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 24,
          description:
            '<p><span>A - C •</span> Serpentine 3 equal loops, width of the arena, no change of lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 25,
          description:
            '<p><span>M - E •</span> Change rein</p><p><span>I •</span> Simple change</p><p><span>E - K •</span> Collected canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 26,
          description: '<p><span>K •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 27,
          description:
            '<p><span>A •</span> Down centerline</p><p><span>X •</span> Halt, salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in free walk.',
    },
    {
      name: 'Collective Marks',
      max: 70,
      type: 'mark',
      rows: [
        {
          description: '<h5>Gaits</h5><p>Freedom and regularity</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Impulsion</h5><p>Desire to move forward; elasticity of the steps; suppleness of the back; engagement of the hindquarters)</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Submission</h5><p>Willing cooperation; harmony; attention and confidence; acceptance of bit and aids; straightness; lightness of forehand and ease of movements</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Riders Pos.</h5><p>Alignment; posture; stability; weight placement; following mechanics of the gaits</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Use of aids</h5><p>Clarity; subtlety; independence; accuracy of test</p>',
          score: '',
          coefficient: 1,
        },
      ],
    },
  ],
}

export const Sheet2023USEFThirdLevelTest1 = {
  id: 'Sheet2023USEFThirdLevelTest1',
  name: '2023 USEF THIRD LEVEL TEST 1',
  furtherRemarks: '',
  type: 'score-sheet-1',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 300,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter collected trot</p><p><span>X •</span> Halt, salute. </p><p><span>N/A •</span> Proceed collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track left</p><p><span>S - V •</span> Shoulder in left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>V - L •</span> Half circle left 10m</p><p><span>L - H •</span> Half pass left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 4,
          description: '<p><span>R - P •</span> Shoulder in right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>P - L •</span> Half circle right 10m</p><p><span>L - M •</span> Half pass right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>H - X - F •</span> Medium trot</p><p><span>F •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description: '<p><span>A •</span> Halt, rein back 4 steps. Proceed medium walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>K - R •</span> Change rein, extended walk</p><p><span>R •</span> Medium walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>M •</span> Turn left</p><p><span>Between G & H •</span> Collect and half turn on haunches left. Proceed medium walk.</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>Between G & M •</span> Collect and half turn on haunches right. Proceed medium walk</p><p><span>H •</span> Track right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description:
            '<p><span>N/A •</span> (Medium walk)</p><p><span>N/A •</span> [RMG(H)G(M)GHC]</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 12,
          description: '<p><span>C •</span> Collected canter right lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>M - F •</span> Medium canter</p><p><span>F •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description: '<p><span>V •</span> Circle right 10m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>V - R •</span> Change rein, flying change of lead between centerline and R</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>H - K •</span> Extended canter</p><p><span>K •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p>N/A • (Transitions H and K)</p><p><span>K - A - P •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description: '<p><span>P •</span> Circle left 10m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>P - S •</span> Change rein, flying change of lead between centerline and S</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 20,
          description: '<p><span>C •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>M - X - K •</span> Extended trot</p><p><span>K •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>N/A •</span> (Transitions M and K)</p><p><span>K - A •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description:
            '<p><span>A •</span> Down centerline</p><p><span>X •</span> Halt, salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in free walk.',
    },
    {
      name: 'Collective Marks',
      max: 70,
      type: 'mark',
      rows: [
        {
          description: '<h5>Gaits</h5><p>Freedom and regularity</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Impulsion</h5><p>Desire to move forward; elasticity of the steps; suppleness of the back; engagement of the hindquarters)</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Submission</h5><p>Willing cooperation; harmony; attention and confidence; acceptance of bit and aids; straightness; lightness of forehand and ease of movements</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Riders Pos.</h5><p>Alignment; posture; stability; weight placement; following mechanics of the gaits</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Use of aids</h5><p>Clarity; subtlety; independence; accuracy of test</p>',
          score: '',
          coefficient: 1,
        },
      ],
    },
  ],
}

export const Sheet2023USEFThirdLevelTest2 = {
  id: 'Sheet2023USEFThirdLevelTest2',
  name: '2023 USEF THIRD LEVEL TEST 2',
  furtherRemarks: '',
  type: 'score-sheet-1',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 310,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter collected trot</p><p><span>X •</span> Halt, salute. </p><p><span>N/A •</span> Proceed collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track right</p><p><span>M - X - K •</span> Change rein, medium trot</p><p><span>K •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p><span>F - B •</span> Shoulder-in left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p><span>B - M •</span> Renvers right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>H - X - F •</span> Change rein, extended trot</p><p><span>F •</span> Collected trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>N/A •</span> (Transitions H and F)</p><p><span>F - A - K •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description: '<p><span>K - E •</span> Shoulder-in right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description: '<p><span>E - H •</span> Renvers left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>C •</span> Medium walk</p><p>M • Turn right</p><p><span>Between G & H •</span> Collect and half turn on haunches right. Proceed medium walk.</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>Between G & M •</span> Collect and half turn on haunches left. Proceed medium walk.</p><p><span>H •</span> Track left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description:
            '<p><span>N/A •</span> (Medium walk)</p><p><span>N/A •</span> [CMG(H)G(M)GHS]</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>S - P •</span> Change rein, extended walk</p><p><span>P •</span> Medium walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 13,
          description: '<p><span>F •</span> Collected canter right lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p><span>A •</span> Down centerline</p><p><span>D - R •</span> Half pass right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description: '<p><span>Between R & M •</span> Flying change of lead</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>H - K •</span> Medium canter</p><p><span>K •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>A •</span> Down centerline</p><p><span>D - S •</span> Half pass left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description: '<p><span>Between S & H •</span> Flying change of lead</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>C •</span> Circle right 20m, showing a clear release of both reins for 4-5 strides over centerline</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>M - F •</span> Extended canter</p><p><span>F •</span> Collected canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>N/A •</span> (Transitions M and F)</p><p><span>F - A •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>A •</span> Down centerline</p><p><span>L •</span> Collected trot</p><p><span>I •</span> Halt, salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in free walk.',
    },
    {
      name: 'Collective Marks',
      max: 70,
      type: 'mark',
      rows: [
        {
          description: '<h5>Gaits</h5><p>Freedom and regularity</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Impulsion</h5><p>Desire to move forward; elasticity of the steps; suppleness of the back; engagement of the hindquarters)</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Submission</h5><p>Willing cooperation; harmony; attention and confidence; acceptance of bit and aids; straightness; lightness of forehand and ease of movements</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Riders Pos.</h5><p>Alignment; posture; stability; weight placement; following mechanics of the gaits</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Use of aids</h5><p>Clarity; subtlety; independence; accuracy of test</p>',
          score: '',
          coefficient: 1,
        },
      ],
    },
  ],
}

export const Sheet2023USEFThirdLevelTest3 = {
  id: 'Sheet2023USEFThirdLevelTest3',
  name: '2023 USEF THIRD LEVEL TEST 3',
  furtherRemarks: '',
  type: 'score-sheet-1',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 330,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter collected trot</p><p><span>X •</span> Halt, salute. </p><p><span>N/A •</span> Proceed collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track left</p><p><span>H - X - K •</span> Change rein, medium trot</p><p><span>F •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p><span>K - E •</span> Shoulder-in right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>E - X •</span> Half circle right 10m</p><p><span>X - B •</span> Half circle left 10m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>B - G •</span> Half pass left</p><p><span>C •</span> Track right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>M - X - K •</span> Change rein, extended trot</p><p><span>K •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>N/A •</span> (Transitions M and K)</p><p><span>K - A •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description: '<p><span>A •</span> Halt, rein back 4 steps. Proceed collected trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>F - B •</span> Shoulder-in left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>B - X •</span> Half circle left 10m</p><p><span>X - E •</span> Half circle right 10m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description:
            '<p><span>E - G •</span> Half pass right</p><p><span>C •</span> Track left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>Between C & H •</span> Medium walk</p><p><span>H •</span> Turn left. Collect and half turn on haunches left</p><p><span>Between G & M •</span> Proceed medium walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>Between G & H •</span> Collect and half turn on haunches right</p><p><span>M •</span> Proceed medium walk. Turn right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p><span>N/A •</span> (Medium walk)</p><p><span>N/A •</span> [CHG(M)G(H)CMR]</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>R - V •</span> Change rein, extended walk</p><p><span>V •</span> Medium walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 16,
          description: '<p><span>K •</span> Collected canter left lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>F - X •</span> Half pass left</p><p><span>X - I •</span> Collected canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>I - S •</span> Half circle left 10m</p><p><span>S - F •</span> Change rein, flying change of lead near centerline</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>K - X •</span> Half pass right</p><p><span>X - I •</span> Collected canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>I - R •</span> Half circle right 10m</p><p><span>R - K •</span> Change rein, flying change of lead near centerline</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>F - M •</span> Extended canter</p><p><span>M •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>N/A •</span> (Transitions F and M)</p><p><span>M - C - H •</span> Collected Canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description: '<p><span>H •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 24,
          description:
            '<p><span>E •</span> Turn left</p><p><span>X •</span> Turn left</p><p><span>G •</span> Halt, salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in free walk.',
    },
    {
      name: 'Collective Marks',
      max: 70,
      type: 'mark',
      rows: [
        {
          description: '<h5>Gaits</h5><p>Freedom and regularity</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Impulsion</h5><p>Desire to move forward; elasticity of the steps; suppleness of the back; engagement of the hindquarters)</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Submission</h5><p>Willing cooperation; harmony; attention and confidence; acceptance of bit and aids; straightness; lightness of forehand and ease of movements</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Riders Pos.</h5><p>Alignment; posture; stability; weight placement; following mechanics of the gaits</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Use of aids</h5><p>Clarity; subtlety; independence; accuracy of test</p>',
          score: '',
          coefficient: 1,
        },
      ],
    },
  ],
}

export const Sheet2023USEFFourthLevelTest1 = {
  id: 'Sheet2023USEFFourthLevelTest1',
  name: '2023 USEF FOURTH LEVEL TEST 1',
  furtherRemarks: '',
  type: 'score-sheet-1',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 320,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter collected canter</p><p><span>X •</span> Halt, salute. </p><p><span>N/A •</span> Proceed collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track left</p><p><span>H - X - F •</span> Change rein, medium trot</p><p><span>Over X •</span> 6-7 steps collected trot</p><p><span>F •</span> Collected trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 3,
          description: '<p><span>K - X •</span> Half pass right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 4,
          description: '<p><span>X - H •</span> Half pass left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>M - X - K •</span> Change rein, extended trot</p><p><span>K •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>N/A •</span> (Transitions M and K)</p><p><span>K - A - F •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>F - B •</span> Shoulder-in left</p><p><span>B •</span> Turn left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>E •</span> Turn right</p><p><span>E - H •</span> Shoulder-in right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>C •</span> Collected walk</p><p><span>M •</span> Turn right</p><p><span>Between G & H •</span> Half pirouette right proceed collected walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>Between G & H •</span> Half pirouette left proceed collected walk</p><p><span>H •</span> Turn left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description:
            '<p><span>N/A •</span> (Collected walk)</p><p><span>N/A •</span> CMG(H)G(M)GHS</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>S - P •</span> Extended walk</p><p><span>P •</span> Collected walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 13,
          description: '<p><span>F •</span> Collected canter right lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p><span>A •</span> Down centerline</p><p><span>D - B •</span> Half pass right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>B - M •</span> Counter canter</p><p><span>M •</span> Flying change of lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>C •</span> Circle left 20m, 5-6 strides of very collected canter between quarterlines</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>H - K •</span> Medium canter</p><p><span>K •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>A •</span> Down centerline</p><p><span>D - E •</span> Half pass left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>E - H •</span> Counter canter</p><p><span>H •</span> Flying change of lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>M - F •</span> Extended canter</p><p><span>F •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>N/A •</span> (Transitions M and F)</p><p><span>F - A - K •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>K - X - M •</span> Change rein, 3 single flying changes of lead, near first quarterline, near X, and near last quarterline</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 23,
          description: '<p><span>H •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 24,
          description:
            '<p><span>E •</span> Turn left</p><p><span>X •</span> Turn left</p><p><span>G •</span> Halt, salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in free walk.',
    },
    {
      name: 'Collective Marks',
      max: 70,
      type: 'mark',
      rows: [
        {
          description: '<h5>Gaits</h5><p>Freedom and regularity</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Impulsion</h5><p>Desire to move forward; elasticity of the steps; suppleness of the back; engagement of the hindquarters)</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Submission</h5><p>Willing cooperation; harmony; attention and confidence; acceptance of bit and aids; straightness; lightness of forehand and ease of movements</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Riders Pos.</h5><p>Alignment; posture; stability; weight placement; following mechanics of the gaits</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Use of aids</h5><p>Clarity; subtlety; independence; accuracy of test</p>',
          score: '',
          coefficient: 1,
        },
      ],
    },
  ],
}

export const Sheet2023USEFFourthLevelTest2 = {
  id: 'Sheet2023USEFFourthLevelTest2',
  name: '2023 USEF FOURTH LEVEL TEST 2',
  furtherRemarks: '',
  type: 'score-sheet-1',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 310,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter collected canter</p><p><span>X •</span> Halt, salute. </p><p><span>N/A •</span> Proceed collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track right</p><p><span>M - B •</span> Shoulder-in right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>B - K •</span> Change rein, medium trot</p><p><span>K •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>A •</span> Down centerline</p><p><span>D - E •</span> Half pass left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>E - G •</span> Half pass right</p><p><span>C •</span> Track left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description: '<p><span>H - E •</span> Shoulder-in left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>E - F •</span> Change rein, medium trot</p><p><span>F •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>A •</span> Collected walk</p><p><span>K - R •</span> Change rein, extended walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>R •</span> Collected walk</p><p><span>M •</span> Turn left</p><p><span>Between G & H •</span> Half pirouette left. Proceed collected walk.</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>Between G & M •</span> Half pirouette right. Proceed collected walk.</p><p><span>H •</span> Turn right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 11,
          description:
            '<p><span>N/A •</span> (Collected walk)</p><p><span>N/A •</span> [AK/RMG(H)G(M)GHC]</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description: '<p><span>C •</span> Collected canter right lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>M - F •</span> Medium canter</p><p><span>F •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p><span>A •</span> Down centerline</p><p><span>D - B •</span> Half pass right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 15,
          description: '<p><span>B •</span> Flying change of lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>B - G •</span> Half pass left</p><p><span>C •</span> Track left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>H - X - F •</span> Change rein extended canter</p><p><span>F •</span> Collected canter and flying change of lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>K - X •</span> On diagonal develop very collected canter</p><p><span>Approaching X •</span> Working pirouette right toward the letter F</p><p><span>Toward F •</span> Proceed collected canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>K - X - M •</span> Change rein, three flying changes of lead every fourth stride</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>H - X •</span> On diagonal develop very collected canter</p><p><span>Approaching X •</span> Working pirouette left toward the letter M</p><p><span>Toward M •</span> Proceed collected canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 21,
          description: '<p><span>H •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>S - F •</span> Change rein, extended trot</p><p><span>F •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description:
            '<p><span>A •</span> Down centerline</p><p><span>X •</span> Halt, salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in free walk.',
    },
    {
      name: 'Collective Marks',
      max: 70,
      type: 'mark',
      rows: [
        {
          description: '<h5>Gaits</h5><p>Freedom and regularity</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Impulsion</h5><p>Desire to move forward; elasticity of the steps; suppleness of the back; engagement of the hindquarters)</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Submission</h5><p>Willing cooperation; harmony; attention and confidence; acceptance of bit and aids; straightness; lightness of forehand and ease of movements</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Riders Pos.</h5><p>Alignment; posture; stability; weight placement; following mechanics of the gaits</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Use of aids</h5><p>Clarity; subtlety; independence; accuracy of test</p>',
          score: '',
          coefficient: 1,
        },
      ],
    },
  ],
}

export const Sheet2023USEFFourthLevelTest3 = {
  id: 'Sheet2023USEFFourthLevelTest3',
  name: '2023 USEF FOURTH LEVEL TEST 3',
  furtherRemarks: '',
  type: 'score-sheet-1',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 290,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter collected canter</p><p><span>X •</span> Halt, salute. </p><p><span>N/A •</span> Proceed collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track LEFT</p><p><span>H - X - F •</span> Change rein, medium trot</p><p><span></span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p><span>K - E •</span> Shoulder-in right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>E - G •</span> Half pass right</p><p><span>C •</span> Track right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>M - X - K •</span> Extended trot</p><p><span>K •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>N/A •</span> (Transitions M and K)</p><p><span>K - A •</span> Collected trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 7,
          description: '<p><span>A •</span> Halt, rein back 4 steps and proceed collected trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 8,
          description: '<p><span>F - B •</span> Shoulder-in left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>B - G •</span> Half pass left</p><p><span>C •</span> Track left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description: '<p><span>H •</span> Collected walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 11,
          description:
            '<p><span>S - R •</span> Half circle 20m Extended walk</p><p><span>R - M - C •</span> Collected walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 12,
          description: '<p><span>C •</span> Collected canter left lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>H - K •</span> Medium canter</p><p><span>K - F •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p><span>F - X •</span> Half pass left</p><p><span>I - S •</span> Half 10m circle</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>S - P •</span> Change rein</p><p><span>Between centerline and P •</span> Working half-pirouette left approximately 3m in diameter. Proceed collected canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 16,
          description: '<p><span>Before S •</span> Flying change of lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>M - X •</span> Half pass right</p><p><span>L - V •</span> Half 10m circle</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>V - R •</span> Change rein</p><p><span>Between centerline and R •</span> Working half-pirouette right approximately 3m in diameter. Proceed collected canter.</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 19,
          description: '<p><span>Before V •</span> Flying change of lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>F - X - H •</span> Extended canter</p><p><span>H •</span> Collected canter and flying change of lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>M - X - K •</span> Three flying changes of lead every third stride</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>A •</span> Down centerline</p><p><span>X •</span> Halt, salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in free walk.',
    },
    {
      name: 'Collective Marks',
      max: 70,
      type: 'mark',
      rows: [
        {
          description: '<h5>Gaits</h5><p>Freedom and regularity</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Impulsion</h5><p>Desire to move forward; elasticity of the steps; suppleness of the back; engagement of the hindquarters)</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Submission</h5><p>Willing cooperation; harmony; attention and confidence; acceptance of bit and aids; straightness; lightness of forehand and ease of movements</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Riders Pos.</h5><p>Alignment; posture; stability; weight placement; following mechanics of the gaits</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Use of aids</h5><p>Clarity; subtlety; independence; accuracy of test</p>',
          score: '',
          coefficient: 1,
        },
      ],
    },
  ],
}
