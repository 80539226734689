import { useEffect, useState, Dispatch, SetStateAction } from 'react'
import { useAppSelector, useAppDispatch } from '../../../store/hooks'

import useProfileHook from '../../../hooks/users/competitor/profile/useProfileHook'
import {
  selectVisitedUserDetailsR,
  setVisitedUserProfileDetailsAc,
  selectVisitedUserProfileDetailsR,
  setVisitedUserDetailsAc,
} from '../../../store/profilePage/profilePageSlice'
import { selectUserId } from '../../../store/user/userSlice'
import { getConvertedData } from '../../../models/interface.helper'
import { CONST } from '../../../const/const'
import { IUserInterfaceExtended } from '../../../store/storeHelpers/userSlice/types'
import { Clear, ArrowBack } from '@mui/icons-material'
import { useChatContext } from 'stream-chat-react'
import ProfileImage from './ProfileImage'
import CompetitorProfileCard from '../../allEvents/root/CompetitorProfileCard'

// Types
type Props = {
  setShowProfile: Dispatch<SetStateAction<boolean>>
}

export default function ProfilePanel({ setShowProfile }: Props) {
  const dispatch = useAppDispatch()
  const userId = useAppSelector(selectUserId)
  const { channel } = useChatContext()
  const { getModelledProfileDataFn } = useProfileHook({ dontFetch: false })
  const visitedUserDetails = useAppSelector(selectVisitedUserDetailsR)
  const visitedUserProfileDetailsR = useAppSelector(selectVisitedUserProfileDetailsR)

  async function getUserDetails(userId: string) {
    let res = await getModelledProfileDataFn(userId)
    dispatch(setVisitedUserProfileDetailsAc(getConvertedData(res)))
  }

  const [channelUsers, setChannelUsers] = useState<{
    members: Array<{ id: string; online: boolean; name: string; image?: string }>
    selectedUserId: string
  }>({ members: [], selectedUserId: '' })

  const selectedUserId = channelUsers.selectedUserId

  useEffect(() => {
    if (!channel) return
    const updateChannelUsers = () => {
      const members = Object.values(channel.state.members)
        .map((user) => ({
          id: user.user_id!,
          online: !!user.user!.online,
          name: user.user!.name!,
          image: user.user!.image,
        }))
        .filter((user) => user.id !== userId)

      const selectedUserId = members.length === 1 ? members[0].id : ''
      setChannelUsers({ members, selectedUserId })
    }

    updateChannelUsers()
  }, [channel, userId])

  useEffect(() => {
    if (!selectedUserId) return
    getUserDetails(selectedUserId)

    return () => {
      dispatch(setVisitedUserProfileDetailsAc(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserId])

  useEffect(() => {
    if (selectedUserId && !visitedUserProfileDetailsR) return

    let isOthersProfile = true
    let profileDetails: IUserInterfaceExtended | null = null
    let isOrganizerProfileView =
      visitedUserProfileDetailsR?.userType === CONST.USE_MODE.ORGANIZER && isOthersProfile

    if (isOthersProfile && visitedUserProfileDetailsR) profileDetails = visitedUserProfileDetailsR

    if (profileDetails)
      dispatch(
        setVisitedUserDetailsAc({
          isMyProfile: false,
          isOrganizerProfileView,
          profileDetails,
        })
      )
    else dispatch(setVisitedUserDetailsAc(null))

    return () => {
      dispatch(setVisitedUserDetailsAc(null))
    }
  }, [dispatch, selectedUserId, visitedUserProfileDetailsR?.id])

  if (channelUsers.members.length <= 1 && !selectedUserId) {
    return null
  }

  return (
    <div className="flex flex-col p-0 w-[220px] lg:w-[320px] overflow-y-scroll overflow-x-hidden relative">
      {channelUsers.members.length > 1 && selectedUserId && (
        <button
          onClick={() => setChannelUsers((d) => ({ ...d, selectedUserId: '' }))}
          className="hidden mini:block absolute top-2 left-2"
        >
          <ArrowBack
            fontSize={'small'}
            style={{
              color: 'grey',
              fontWeight: '400',
            }}
          />
        </button>
      )}
      <button
        onClick={() => setShowProfile(false)}
        className="hidden mini:block absolute top-2 right-2"
      >
        <Clear
          fontSize={'small'}
          style={{
            color: 'grey',
            fontWeight: '400',
          }}
        />
      </button>

      {channelUsers.members.length > 1 && !selectedUserId ? (
        <div className="flex h-full flex-wrap w-full content-start bg-[#F6F7FB]">
          {channelUsers.members.map((user) => {
            return (
              <button
                key={user.id}
                onClick={() => setChannelUsers((d) => ({ ...d, selectedUserId: user.id }))}
                className="w-1/2 h-[120px] flex flex-col items-center justify-center border-2 gap-y-2"
              >
                <ProfileImage
                  src={user.image}
                  lazyLoad={true}
                  className={'h-[40px] w-[40px] object-cover rounded-full'}
                />
                <span className="text-xs w-full px-4 text-center">{user.name}</span>
              </button>
            )
          })}
        </div>
      ) : (
        <CompetitorProfileCard isChatPage isMyProfile={!!visitedUserDetails?.isMyProfile} />
      )}
    </div>
  )
}
