import { cloneDeep } from 'lodash'

import helpers from '../../../../../commonHelpers/helpers'
import { CustomError } from '../../../../../helpers/helpers'
import useToasterHelper from '../../../../../helpers/ToasterHelper'

import { IRegisterTabItem } from '../../../../../models/event-registered-users/event-registered-users.interface'
import { ISaveRegisterTabDataFnArgs } from '../../../event-registration-tabs/hooks/useEventRegistrationTabs.types'
import { IRegistrationByDayInterface } from '../../../../../models/registrations-by-day/registrationByDay.interface'
import { IManageInfo } from '../../../event-registration-tabs/EventRegistrationTabs'

import { useAppSelector } from '../../../../../store/hooks'
import { selectPublishedEventInDb } from '../../../../../store/registration/registrationSlice'

import { MESSAGES_CONST } from '../../../../../const/messages-const'
import { IHorseData } from '../../../../../models/horse/horse.interface'

const FILE_NAME = 'useHorseTab.tsx'

type IOnRegistrationByDayHorseChangeFnArgs = {
  horse: IHorseData | null
  currentRow: IRegistrationByDayInterface
  isRemove: boolean
  removedIndex?: number
  isChildren: boolean
  isManage?: boolean
  noHorse?: boolean
  manageInfo: IManageInfo
  setManageInfo: (value: IManageInfo) => void
}

interface useHorseTabProps {
  saveRegisterTabData: (args: ISaveRegisterTabDataFnArgs) => Promise<ISaveRegisterTabDataFnArgs>
}

const useHorseTab = ({ saveRegisterTabData }: useHorseTabProps) => {
  const toastFunctions = useToasterHelper()

  const publishedEventInDb = useAppSelector(selectPublishedEventInDb)

  const onRegistrationByDayHorseChange = async ({
    currentRow,
    horse,
    isRemove,
    removedIndex,
    isChildren,
    isManage,
    manageInfo,
    setManageInfo,
    noHorse,
  }: IOnRegistrationByDayHorseChangeFnArgs) => {
    if (!publishedEventInDb) return null

    let currentMember = cloneDeep(currentRow)
    const { EventDetails } = publishedEventInDb

    try {
      const { emptyVarName, emptyVarValue } = noHorse
        ? helpers.findEmptyVal({
            EventDetails,
          })
        : helpers.findEmptyVal({
            horse,
            EventDetails,
          })

      if (emptyVarName) {
        throw CustomError.somethingWentWrong({
          fileName: FILE_NAME,
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
          moduleName: 'onRegistrationByDayHorseChange',
          devMessage: `${emptyVarName} is [${emptyVarValue}]`,
        })
      }

      if (noHorse) {
        currentMember = {
          ...currentRow,
          horseId: isRemove ? null : MESSAGES_CONST.NO_HORSE,
          horseName: isRemove ? null : MESSAGES_CONST.NO_HORSE,
          horseIds: [],
          horseProfilePicture: null,
        }
      } else if (isRemove && removedIndex !== undefined) {
        currentMember = {
          ...currentRow,
          horseId: null,
          horseName: null,
          horseIds: [
            ...(currentRow?.horseIds ?? [])?.slice(0, removedIndex),
            ...(currentRow?.horseIds ?? [])?.slice(removedIndex + 1),
          ],
          horseProfilePicture: null,
        }
      } else {
        currentMember = {
          ...currentRow,
          horseId: horse?.id ?? null,
          horseName: horse?.horseName ?? null,
          horseIds: [...(currentRow?.horseIds ?? []), horse?.id ?? ''],
          horseProfilePicture: horse?.horseProfilePicture,
        }
      }

      if (isManage) {
        const registerTabData = cloneDeep(manageInfo.register)

        let rowIndex = -1
        let byDayIndex = -1

        if (isChildren) {
          let childIndex = -1

          registerTabData.forEach((parent, rowIdx) => {
            parent?.children?.forEach((child, childIdx) => {
              child.registrationsByDay.forEach((registrationByDay, registrationByDayIdx) => {
                if (registrationByDay.uniqId === currentMember.uniqId) {
                  rowIndex = rowIdx
                  childIndex = childIdx
                  byDayIndex = registrationByDayIdx
                }
              })
            })
          })
          ;(registerTabData[rowIndex].children as IRegisterTabItem[])[
            childIndex
          ].registrationsByDay[byDayIndex] = currentMember
        } else {
          rowIndex = registerTabData.findIndex((parent) => parent.uuid === currentMember.uuid)

          registerTabData[rowIndex].registrationsByDay.forEach(
            (registrationByDay, registrationByDayIdx) => {
              if (registrationByDay.uniqId === currentMember.uniqId) {
                byDayIndex = registrationByDayIdx
              }
            }
          )
          registerTabData[rowIndex].registrationsByDay[byDayIndex] = currentMember
        }

        setManageInfo({ ...manageInfo, register: registerTabData })
      } else {
        await saveRegisterTabData({
          registrationsByDayToUpdate: [currentMember],
        })
      }
    } catch (error: any) {
      toastFunctions.error({
        message:
          error?.message ??
          `${MESSAGES_CONST.SOMETHING_WENT_WRONG} in onRegistrationByDayHorseChange`,
      })
      helpers.logger({
        message: error,
      })
    }
  }

  return { onRegistrationByDayHorseChange }
}

export default useHorseTab
