import { TableStyles } from 'react-data-table-component'

export const customStyles: TableStyles = {
  rows: {
    style: {
      minHeight: '72px', // override the row height
      '&:not(:last-of-type)': {
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: '#D3DAEE',
      },
    },
  },
  headCells: {
    style: {
      paddingLeft: '0px', // override the cell padding for head cells
      paddingRight: '0px',
      color: '#122B46',
      fontWeight: '600',
      fontSize: '12px',
    },
  },
  headRow: {
    style: {
      minHeight: 'unset',
      borderColor: '#D3DAEE',
    },
  },
  cells: {
    style: {
      paddingLeft: '0px', // override the cell padding for data cells
      paddingRight: '0px',
      Text: 'center',
    },
  },
}

export const customStylesNoBorder: TableStyles = {
  rows: {
    style: {
      minHeight: '72px', // override the row height
      '&:not(:last-of-type)': {
        border: 'none',
      },
    },
  },
  headCells: {
    style: {
      paddingLeft: '0px', // override the cell padding for head cells
      paddingRight: '0px',
      color: '#122B46',
      fontWeight: '600',
      fontSize: '12px',
    },
  },
  headRow: {
    style: {
      minHeight: 'unset',
      borderColor: '#D3DAEE',
    },
  },
  cells: {
    style: {
      paddingLeft: '0px', // override the cell padding for data cells
      paddingRight: '0px',
      Text: 'center',
    },
  },
}
