/* eslint-disable no-loop-func */
import { useContext, useEffect, useState } from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'

import { ProfileCard } from './components/ProfileCard'
import FormHeader from '../../../ui/form/form-header/FormHeader'

import helpers from '../../../../commonHelpers/helpers'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import useHorses, { ITeamMemberHorse } from '../../../../hooks/users/competitor/profile/useHorses'
import { formatConnectedDate } from '../../../../helpers/formatConnectedDate'
import formFunctions from '../../../ui/form/form-functions/formFunctions'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'

import { useAppSelector } from '../../../../store/hooks'
import { selectProfileData, selectUserId } from '../../../../store/user/userSlice'
import { selectMappings } from '../../../../store/horses/horseSlice'

import { IUserHorseMappingInterface } from '../../../../models/user-horse-mapping/userHorseMapping.interface'

import { MODAL_CONSTS } from '../../../../const/modal-const'

// Types
type IOnRemoveHorseClickFn = (props: {
  bypass?: boolean
  mappingDocId: IUserHorseMappingInterface['id']
}) => void

const ProfileRootPageHorseTab = () => {
  const history = useHistory()
  const { loadHorsesFromDb } = useHorses()

  const toastFunction = useToasterHelper()
  const handleModalContext = useContext(HandleModalContext)

  const userId = useAppSelector(selectUserId)
  const mappings = useAppSelector(selectMappings)
  const profileDetails = useAppSelector(selectProfileData)

  const [teamMembersHorses, setTeamMembersHorses] = useState<ITeamMemberHorse[]>([])
  const [loadingHorses, setLoadingHorses] = useState(true)

  const formStyles = formFunctions.getFormStyles()
  const { getTeamMembersHorses, removeHorseFromDb } = useHorses()

  useEffect(() => {
    if (profileDetails.userTeamMembers) {
      getTeamMembersHorses()
        .then((horses) => {
          setLoadingHorses(false)
          setTeamMembersHorses(horses)
        })
        .catch(() => setLoadingHorses(false))
    }
  }, [profileDetails.userTeamMembers])

  useEffect(() => {
    if (userId) loadHorsesFromDb(userId).then()
  }, [userId])

  const onRemoveHorseClick: IOnRemoveHorseClickFn = (args) => {
    let { mappingDocId, bypass } = args
    const horseMapping = mappings.find((currMapping) => currMapping.id === mappingDocId)
    const mappingId = horseMapping?.id

    try {
      if (!mappingId)
        throw Error('Something went wrong while removing horse', {
          cause: `mappingId is ${mappingId}`,
        })

      if (typeof bypass !== 'boolean') bypass = false

      if (bypass) {
        return removeHorseFromDb({
          mappingDocId: mappingId,
          forceRemove: true,
        })
      }

      handleModalContext?.handleModal?.(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
        removeItem: () =>
          onRemoveHorseClick({
            bypass: true,
            mappingDocId: mappingId,
          }),
        alertText: (
          <div>
            <h5 className="font-semibold text-xl">Are you sure you want to remove this horse?</h5>
            <p className="text-sm mt-2">
              When you click ‘remove’, you are removing this horse from yourself and your team.
            </p>
          </div>
        ),
      })
    } catch (error: any) {
      toastFunction.error({
        message: error?.message,
      })

      return helpers.logger({
        message: error?.cause ?? error,
      })
    }
  }
  return (
    <>
      <FormHeader title="Horse settings" description="Add or remove horses from your account" />
      <div className={clsx('flex-1 flex flex-col', formStyles.className)} style={formStyles.styles}>
        <div className={'flex flex-1 flex-col lg:flex-row gap-6 mt-4 lg:mt-0'}>
          <ProfileCard
            teamMembers={mappings.map((horse) => ({
              id: horse.id || '',
              horseId: horse.horseId || '',
              name: horse.horseName || '',
              profilePicture: horse.horseProfilePicture || '',
              userDesc:
                userId !== horse.horseOwnerId
                  ? 'This is not your horse. See profile for more details.'
                  : `${horse?.teamMembers?.length || 0} team member `,
              role: '',
              ownerId: horse.horseOwnerId,
            }))}
            loading={loadingHorses}
            removeHandler={({ memberId }) => onRemoveHorseClick({ mappingDocId: memberId })}
            title={'Your horses'}
            subTitle={'Horses'}
            desc={'Add, edit or remove horses'}
            addTeamMemberHandler={() => history.push('/add-horse')}
            isHorseCard
          />
          <ProfileCard
            teamMembers={teamMembersHorses.map((horse) => ({
              id: horse.id || '',
              horseId: horse.horseId || '',
              name: horse.horseName || '',
              ownerId: horse.horseOwnerId,
              profilePicture: horse.horseProfilePicture || '',
              userDesc: `${horse.user?.memberName} • last updated ${formatConnectedDate(new Date(horse.modified || ''))}`,
              role: '',
            }))}
            loading={loadingHorses}
            removeHandler={() => null}
            title={'Team horses'}
            subTitle={'Team Horses'}
            desc={'These horses belong to your team members'}
            addTeamMemberHandler={() => null}
            isHorseCard
            isConnections
          />
        </div>
      </div>
    </>
  )
}

export default ProfileRootPageHorseTab
