import { useState } from 'react'

import clsx from 'clsx'

import ImageGallery from 'react-image-gallery'

import { Modal } from './Modal'

import './PhotoModal.css'

interface Props {
  open: boolean
  imageURL: []
  onClose: () => void | Promise<void>
}
export const PhotoModal = ({ open, imageURL, onClose }: Props) => {
  const [show, setShow] = useState(false)

  const images = imageURL.map((url) => ({ original: url }))
  return (
    <Modal open={open} onClose={onClose}>
      <div className="flex justify-center transition duration-500 items-center">
        <ImageGallery
          onImageLoad={() => setShow(true)}
          items={images}
          showBullets={true}
          showPlayButton={false}
          showNav={true}
          additionalClass={clsx('flex-1', !show && 'hidden')}
        />
        <button className="absolute right-0 top-0 p-[10px]" onClick={onClose}></button>
      </div>
    </Modal>
  )
}
