import { where } from 'firebase/firestore'

import { getConvertedData } from '../../../models/interface.helper'
import FirestoreService from '../../../services/firestoreService'
import helpers from '../../../commonHelpers/helpers'

import {
  IGetMyEventsFilteredDataItem,
  TEventRegisteredUsers,
} from '../../../models/event-registered-users/event-registered-users.interface'
import { IEventFees } from '../../../models/event-fees/event-fees.interface'
import { IEventInterface } from '../../../models/events/event.interface'
import { IEventDetails } from '../../../models/event-details/event-details.interface'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'
import { IUserInterface } from '../../../models/users/user.interface'

import { EventRegisteredUsersModel } from '../../../models/event-registered-users/event-registered-users.model'
import { EventBookmarkModel } from '../../../models/event-bookmark/event-bookmark.model'

import { CONST } from '../../../const/const'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

export const getUserEvents = async ({
  userId,
  eventFees,
  eventDetails,
  events,
  riders,
  users,
  type,
}: {
  userId: string
  eventFees: IEventFees[]
  eventDetails: IEventDetails[]
  events: IEventInterface[]
  riders: IRegistrationByDayInterface[]
  users: IUserInterface[]
  type: 'registered' | 'draft' | 'bookmarked'
}) => {
  let eventIds: string[] = []
  let registrations: TEventRegisteredUsers[] = []
  let itemsToFilter: IGetMyEventsFilteredDataItem[] = []

  try {
    if (type === 'bookmarked') {
      const bookmarkedSnaps = await FirestoreService.filterItems(COLLECTIONS.EVENT_BOOKMARKS.NAME, [
        where(COLLECTIONS.EVENT_BOOKMARKS.FIELDS.USER_ID.KEY, '==', userId),
      ])

      bookmarkedSnaps.docs.forEach((currDoc) => {
        const current = getConvertedData(EventBookmarkModel.fromFirestoreDoc(currDoc).toObject())
        if (current.eventId) eventIds.push(current.eventId)
      })
    } else {
      const queries = [where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.USER_ID.KEY, '==', userId)]

      if (type === 'draft') {
        queries.push(
          where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.KEY, '==', null)
        )
      }

      if (type === 'registered') {
        queries.push(where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.IS_REGISTERED.KEY, '==', true))
      }

      const registeredUsersSnaps = await FirestoreService.filterItems(
        COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
        [...queries]
      )
      registeredUsersSnaps.docs.forEach((currDoc) => {
        const current = getConvertedData(
          EventRegisteredUsersModel.fromFirestoreDoc(currDoc).toObject()
        )
        if (current.eventId) {
          eventIds.push(current.eventId)
          registrations.push(current)
        }
      })
    }

    eventIds.forEach((eventId) => {
      const currentEventDetails = eventDetails.find((event) => event.id === eventId)
      const currentEventFees = eventFees.find((event) => event.id === eventId)
      const event = events.find((event) => event.id === eventId)
      const currentRiders = riders.filter((rider) => rider.eventId === eventId)
      const currentRegistration = registrations.find(
        (registration) => registration.eventId === eventId
      )
      const currentOwner = users.find((user) => user.id === event?.owner)
      if (currentEventDetails?.id && currentEventFees?.id && event?.id) {
        itemsToFilter.push({
          registration: currentRegistration ?? null,
          EventDetails: currentEventDetails,
          EventFees: currentEventFees,
          event: event,
          riders: currentRiders ?? [],
          owner: currentOwner ?? null,
        })
      }
    })

    return itemsToFilter
  } catch (error) {
    console.log(error, 'error')
    helpers.logger({
      isError: true,
      message: error,
    })
    return []
  }
}
