import React, { useEffect, useRef, useState } from 'react'

// Third party
import { yupResolver } from '@hookform/resolvers/yup'
import { Autorenew, AutorenewRounded, CameraAlt } from '@mui/icons-material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import Select from 'react-select'

// Models
import { UserModel } from '../../../../models/users/user.model'

// Services
import FirestoreService from '../../../../services/firestoreService'
import FirebaseStorageService from '../../../../services/storageService'
import UserService from '../../../../services/userService'

// Constants
import { CONST } from '../../../../const/const'
import { MESSAGES_CONST } from '../../../../const/messages-const'

// Styles
import { customStyles } from '../../../customStyles/ReactSelectCustomStyle'
import './styles/ProfileRootPageProfileTab.css'

// Redux
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { selectUserId, setCompetitionProfileImageUrl } from '../../../../store/user/userSlice'

// Schemas
import { OrganizerProfileDetailSchema } from '../../../../validations'

// Helpers
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { getConvertedData, getReactPickerDate } from '../../../../models/interface.helper'

// Dummy Data
import { zoneList } from '../../../../fakeData/ZoneList'
import { CountryList } from '../../../../fakeData/countryList'
import { disciplineData } from '../../../../fakeData/disciplineList'

// Redux
import { RootState } from '../../../../store/store'
import { setProfileDetails } from '../../../../store/user/userSlice'

// Custom hooks
import useProfileHook from '../../../../hooks/users/competitor/profile/useProfileHook'

// Utils
import MessageHelperComp from '../../../../helpers/MessageHelper'

// Models
import { CompetitorProfileModel } from '../../../../models/competitor-profile/competitor-profile.model'

// Components
import helpers from '../../../../commonHelpers/helpers'
import { IUserInterface } from '../../../../models/users/user.interface'
import { ICountryList } from '../../../../types/competitor_types'
import CustomDatePicker from '../../../common/inputs/CustomDatePicker'
import PhoneInput from '../../../common/inputs/PhoneInput'
import GlobalInputComponent from '../../../events/forms/detail/Components/GlobalInputComponent'
import formFunctions from '../../../ui/form/form-functions/formFunctions'
import FormHeader from '../../../ui/form/form-header/FormHeader'
import { getUserFullName } from '../../../../helpers/helpers'
import FirebaseApp from '../../../../services/firebaseApp'
import { updateProfile } from 'firebase/auth'
import { OrganizerNumberItem } from './components/OrganizerNumberItem'

// Constants
const CURRENT_STATUS = [
  { label: 'Current', value: '1' },
  { label: 'Expired', value: '0' },
]

// Types
type IOnChangeArgs = {
  length: number
  isValid: boolean
  phoneNumber: string
  countryCode: string
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
const OrganizerProfileRootPageProfileTab = () => {
  // Hooks and vars

  const userReducer = useAppSelector((state: RootState) => state.user)

  const dispatch = useAppDispatch()
  const fileInputRef = useRef() as any
  const toastFunctions = useToasterHelper()
  const userId = useAppSelector(selectUserId)
  const errorRef = useRef({ isValid: true, message: '' })
  const phoneInputRef = useRef<HTMLDivElement | null>(null)
  const { updateUserDetails, getModelledUserDetails } = useProfileHook({})

  const [saved, isSaved] = useState(true)

  const [loading, setLoading] = useState(false)
  const [imgLoading, setImgLoading] = useState(false)
  const [userData, setUserData] = useState<any>(null)
  const [updateProfileImage, setUpdateProfileImage] = useState('')

  const { profileDetails, competitorProfileData } = userReducer
  const formStyles = formFunctions.getFormStyles({ noPaddingRt: true })

  const {
    reset,
    watch,
    control,
    setError,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<IUserInterface>({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(OrganizerProfileDetailSchema),
  })

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'userExtraDocument',
  })

  useEffect(() => {
    if (!userData) {
      const getUserData = async () => {
        let user_exists = false
        let user_doc = null

        while (!user_exists) {
          user_doc = await UserService.getUserInfoById(userId)
          user_exists = user_doc.exists()
        }
        setUserData(UserModel.fromFirestoreDoc(user_doc).toObject())
      }
      getUserData().then()
    }
  }, [userData, userId])

  useEffect(() => {
    if (userData && profileDetails) {
      try {
        reset({
          ...profileDetails,
          ...competitorProfileData,
          userFirstName: getUserFullName(profileDetails),
        })
      } catch (error) {
        helpers.logger({
          isError: true,
          message: error,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, profileDetails])

  // Functions
  const phoneOnChange = (args: IOnChangeArgs) => {
    let { phoneNumber, countryCode } = args

    setValue('userPhoneCode', countryCode)
    setValue('userPhoneNumber', phoneNumber)
  }

  const onSubmit = async (data: any) => {
    let userFirstName
    let userLastName = ''

    const withLastName = /\s/g.test(data?.userFirstName ?? '')

    if (withLastName) {
      userLastName = data?.userFirstName?.split(' ').slice(-1).join(' ') ?? ''
      userFirstName = data?.userFirstName?.split(' ').slice(0, -1).join(' ') ?? ''
    } else userFirstName = data?.userFirstName

    if (!errorRef.current.isValid) {
      setError('userPhoneNumber', {
        message: errorRef.current.message,
      })

      phoneInputRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })

      return 0
    }

    setLoading(true)

    try {
      // Updating profile details
      let profileDetails = getConvertedData(
        new UserModel({
          ...data,
          userFirstName,
          userLastName,
        }).toObject()
      )

      profileDetails = getConvertedData(profileDetails)
      const dataToThrow = getModelledUserDetails({ ...userReducer, ...profileDetails })

      if (FirebaseApp.auth.currentUser) {
        await updateProfile(FirebaseApp.auth.currentUser, {
          displayName: getUserFullName(profileDetails),
          photoURL: profileDetails.userProfilePicture ?? null,
        })
      }

      await updateUserDetails(dataToThrow)
      dispatch(setProfileDetails(profileDetails))

      toastFunctions.success({ message: MESSAGES_CONST.PROFILE_UPDATED })
    } catch (error) {
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      setLoading(false)
      isSaved(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const onError = () => {
    toastFunctions.error({
      message: 'Please fill all the required fields',
    })
  }

  async function fileChangedHandler(event: any, type: string) {
    const files = event.target.files
    const file = files[0]

    if (!file) return toastFunctions.success({ message: MESSAGES_CONST.NO_FILE_SELECTED })

    setImgLoading(true)

    const PATH =
      type === 'profile'
        ? CONST.DATA.STORAGE.USERS.COMPETITOR_PROFILE_IMAGE_URL.PREFIX
        : CONST.DATA.STORAGE.USERS.ENDORSEMENT_URL.PREFIX

    try {
      const downloadUrl = await FirebaseStorageService.uploadFile(
        file,
        `${PATH}/${userId}`,
        (percent: number) => {
          if (0) console.log(percent)
        }
      )

      const newUserData = new UserModel(userData)
      if (type === 'profile') {
        newUserData.userProfilePicture = (downloadUrl ?? '') as string

        setUpdateProfileImage((downloadUrl ?? '') as string)
        dispatch(setCompetitionProfileImageUrl(downloadUrl))

        await FirestoreService.updateItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
          newUserData.id,
          newUserData.toFirestore()
        )
        // setUserData(newUserData);

        toastFunctions.success({ message: MESSAGES_CONST.PIC_UPDATED })
      } else {
        const newProfileData = new CompetitorProfileModel(competitorProfileData)
        await FirestoreService.updateItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.COMPETITOR_PROFILE.NAME,
          newUserData.id,
          newProfileData.toFirestore()
        )

        toastFunctions.success({ message: MESSAGES_CONST.ENDORSEMENT_LETTER_UPLOAD })
      }
    } catch (error) {
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      setImgLoading(false)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @param place Details got from React auto complete library
   * @info Sets from values according to the details got from third party lib
   */
  const handleLocation = (place: any | React.ChangeEvent<HTMLInputElement>) => {
    try {
      let location: any = {}

      if (place?.hasOwnProperty('key') && place.key === 'Enter') place.preventDefault()

      if (place && typeof ((place ?? {}) as any)?.target?.value === 'string') {
        reset({
          ...getValues(),
          userAddress: (place as any)?.target?.value ?? '',
        })
        setValue('userAddress', (place as any)?.target?.value)
        return
      }

      if (typeof place === 'object') {
        let data = place as any

        location = data?.address_components?.reduce((accumulator: any, currAddComponent: any) => {
          switch (true) {
            case currAddComponent?.types.includes('country'):
              accumulator.userNationality = currAddComponent?.long_name
              break
            case currAddComponent?.types.includes('administrative_area_level_1'):
              accumulator.userState = currAddComponent?.long_name
              break
          }
          return accumulator
        }, {})

        if (place.hasOwnProperty('formatted_address'))
          location.formatted_address = place.formatted_address

        if (location) {
          location.userLong = data?.geometry?.location?.lng()
          location.userLat = data?.geometry?.location?.lat()
        }

        const getCountryCode = CountryList.filter((currentCountry: ICountryList) => {
          return currentCountry.label.toLowerCase() === location?.userNationality.toLowerCase()
        })

        reset({
          ...getValues(),
          userNationality: getCountryCode.length ? getCountryCode[0].value : '',
          userAddress:
            location?.formatted_address ?? location?.userNationality ?? location?.userState,
          userState: location?.userState ?? '',
          userLong: location?.userLong ?? '',
          userLat: location?.userLat ?? '',
        })
      }
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
    }
  }

  return (
    <>
      <FormHeader
        title="Profile settings"
        description="Update your profile details"
        headerButtonsContainer={
          <div className="flex items-center gap-2">
            {saved ? (
              <button
                type="button"
                onClick={() => isSaved(false)}
                className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn"
              >
                Edit
              </button>
            ) : (
              <button
                type="button"
                disabled={loading}
                onClick={handleSubmit(onSubmit, onError)}
                className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn"
              >
                {loading ? (
                  <AutorenewRounded fontSize="small" className="animate-spin" />
                ) : (
                  'Save changes'
                )}
              </button>
            )}
          </div>
        }
      />

      <div className={formStyles.className} style={formStyles.styles}>
        <div className="relative w-full pb-12 text-SeabiscuitDark200ThemeColor lg:!text-nr 2xl:!text-base profile_tab">
          <div className="md:grid gap-3">
            {/* Profile Picture */}
            <div className="flex flex-col md:flex-row flex-wrap md:items-center">
              <label className="mr-0 md:w-1/4 flex">
                <img
                  src="/assets/cp_icons/Image-1.svg"
                  alt="imgIcon"
                  className=" mb-2 md:mb-0 mr-2"
                />
                Company Logo
              </label>

              <div className="relative p-1 bg-[#d3daee54] rounded-full cursor-pointer flex flex-col md:flex-row  md:items-center justify-center w-[90px] h-[90px]">
                <input
                  type="file"
                  disabled={imgLoading || saved}
                  accept=".jpeg, .png, .jpg"
                  ref={fileInputRef}
                  onChange={(e: any) => fileChangedHandler(e, 'profile')}
                  className="UserProfilePicture absolute h-full w-full opacity-0 left-0 right-0 top-0 bottom-0 z-40 cursor-pointer"
                />
                {imgLoading ? (
                  <Autorenew className="animate-spin" />
                ) : (
                  <img
                    src={
                      updateProfileImage
                        ? updateProfileImage
                        : userReducer.profileDetails.userProfilePicture === ''
                          ? '/assets/og_icons/Farm-5.png'
                          : userReducer.profileDetails.userProfilePicture
                    }
                    className="object-cover h-full w-full rounded-full"
                    alt="profile_picture"
                  />
                )}

                {!saved && (
                  <div className="bg-[#8C95AC] p-1.5 rounded-full absolute right-0 bottom-0">
                    <CameraAlt className="text-white text-xs" />
                  </div>
                )}
              </div>
            </div>

            <hr className="my-4"></hr>

            <div className="flex flex-wrap items-center">
              <label className="w-full md:w-1/4 flex items-center md:mr-2 my-3 md:my-0">
                <img src="/assets/cp_icons/Paste-1.svg" className="mr-2" alt="safe" />
                Safe sport
              </label>
              <div className="flex flex-col ">
                {1 ? (
                  <div className="flex mb-2 flex-col md:flex-row w-full md:max-w-[520px] md:min-w-[520px] mt-3 gap-3 md:mt-0 md:gap-0">
                    <div className="relative flex flex-col md:flex-row items-center mr-2 w-full md:w-[50%]">
                      <Controller
                        control={control}
                        name="userSafeSupportStatus"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Select
                            onChange={onChange}
                            onBlur={onBlur}
                            isDisabled={saved}
                            value={CURRENT_STATUS.find(
                              (c) => c.value === ((value as any)?.value ?? value)
                            )}
                            options={CURRENT_STATUS}
                            placeholder="Current?"
                            className={`border-[#D3DAEE] border rounded-xl  w-[100%]  selectWithSearch  focus:ring-[#D3DAEE] focus:border-SeabiscuitMainThemeColor ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                            styles={customStyles as any}
                            isClearable={false}
                            isMulti={false}
                            isSearchable={true}
                          />
                        )}
                      />
                      {errors.userSafeSupportStatus && (
                        <p className="text-SeabiscuitMainThemeColor">
                          {errors.userSafeSupportStatus.message}
                        </p>
                      )}
                    </div>

                    <div
                      className={`relative flex items-center border border-solid rounded-xl w-full md:w-[50%] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                    >
                      <Controller
                        name="userSafeSupportExpiry"
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <>
                            <div className="pl-4 text-[14px]">Expires:</div>
                            <CustomDatePicker
                              onChange={onChange}
                              onBlur={onBlur}
                              disabled={saved}
                              selected={getReactPickerDate(value)}
                              placeholderText="Select Date"
                              className={`rounded-xl  selectWithSearch w-[100%] border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 pr-10 pl-2 !border-white h-[51px] `}
                            />
                          </>
                        )}
                      />
                      {!saved && (
                        <KeyboardArrowDownIcon
                          fontSize="medium"
                          className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                        />
                      )}
                      {errors.userSafeSupportExpiry && (
                        <p className="text-SeabiscuitMainThemeColor">
                          {errors.userSafeSupportExpiry.message}
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="flex">
                    <div className="bg-[#EB57571A] w-80 flex items-center border-0  text-[#EB5757] rounded-xl p-3.5 pl-12 mr-2 relative">
                      <img
                        src="/assets/og_icons/Synchronize-2.svg"
                        className="mr-2 absolute left-2"
                        alt="circle"
                      />
                      SafeSport Training Overdue - allowed on competition grounds
                    </div>
                    <div className="border border-[#EB5757] rounded-xl text-[#EB5757] w-56 flex items-center relative justify-center p px-12">
                      <img
                        src="/assets/og_icons/Synchronize-2.svg"
                        className="mr-2 absolute left-2"
                        alt="circle"
                      />
                      Renew Training
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Background check */}
            <div className="flex flex-wrap items-center">
              <label className="w-full md:w-1/4 flex items-center md:mr-2 my-3 md:my-0">
                <img src="/assets/cp_icons/Paste-1.svg" className="mr-2" alt="safe" />
                Background check
              </label>

              <div className="flex flex-col">
                {1 ? (
                  <div className="flex mb-2 flex-col md:flex-row w-full md:max-w-[520px] md:min-w-[520px] mt-3 gap-3 md:mt-0 md:gap-0">
                    <div className="relative flex items-center md:mr-2 w-full md:w-[50%]">
                      <Controller
                        control={control}
                        name="userBackgroundCheckStatus"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Select
                            onChange={onChange}
                            onBlur={onBlur}
                            value={CURRENT_STATUS.find(
                              (c) => c.value === ((value as any)?.value ?? value)
                            )}
                            options={CURRENT_STATUS}
                            isDisabled={saved}
                            placeholder="Current?"
                            className={`border-[#D3DAEE] border rounded-xl  w-[100%] selectWithSearch  focus:ring-[#D3DAEE] focus:border-[#D3DAEE] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                            styles={customStyles as any}
                            isClearable={false}
                            isMulti={false}
                            isSearchable={true}
                          />
                        )}
                      />
                      {errors.userBackgroundCheckStatus && (
                        <p className="text-SeabiscuitMainThemeColor">
                          {errors.userBackgroundCheckStatus.message}
                        </p>
                      )}
                    </div>

                    <div
                      className={`relative flex items-center w-full md:w-[50%] border border-solid ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'} rounded-xl`}
                    >
                      <Controller
                        name="userBackgroundCheckExpiry"
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <>
                            <div className="pl-4 text-[14px]">Expires:</div>
                            <CustomDatePicker
                              onChange={onChange}
                              onBlur={onBlur}
                              disabled={saved}
                              selected={getReactPickerDate(value)}
                              placeholderText="Select Date"
                              className={`rounded-xl w-[100%] selectWithSearch placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 pr-10 pl-2 h-[49px] !border-white`}
                            />
                          </>
                        )}
                      />
                      {!saved && (
                        <KeyboardArrowDownIcon
                          fontSize="medium"
                          className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                        />
                      )}
                      {errors.userBackgroundCheckExpiry && (
                        <p className="text-SeabiscuitMainThemeColor">
                          {errors.userBackgroundCheckExpiry.message}
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="flex">
                    <div className="bg-[#EB57571A] w-80 flex items-center border-0  text-[#EB5757] rounded-xl p-3.5 pl-12 mr-2 relative">
                      <img
                        src="/assets/og_icons/Synchronize-2.svg"
                        className="mr-2 absolute left-2"
                        alt="circle"
                      />
                      SafeSport Training Overdue - allowed on competition grounds
                    </div>
                    <div className="border border-[#EB5757] rounded-xl text-[#EB5757] w-56 flex items-center relative justify-center p px-12">
                      <img
                        src="/assets/og_icons/Synchronize-2.svg"
                        className="mr-2 absolute left-2"
                        alt="circle"
                      />
                      Renew Training
                    </div>
                  </div>
                )}
              </div>
            </div>

            <hr className="my-4"></hr>
            {/* User Personal Details */}

            <div className="flex flex-wrap items-center">
              <label className="w-full md:w-1/4 flex items-center md:mr-2 my-3 md:my-0">
                <img src="/assets/cp_icons/Farm-1.svg" alt="userIcons" className="mr-2" />
                Organization name
              </label>
              <div className="w-full md:w-2/5 md:min-w-[520px] md:max-w-[520px] mt-3 md:mt-0">
                <input
                  type="text"
                  {...register('userFirstName')}
                  disabled={saved}
                  placeholder="Enter name of organization..."
                  className={` w-full border rounded-xl !ring-0 p-2.5 mr-2 h-[51px] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                />
                {errors?.userName?.message && (
                  <MessageHelperComp isError={true} message={errors.userName.message} />
                )}
              </div>
            </div>

            <div className="flex flex-wrap items-start">
              <label className="w-full md:w-1/4 flex md:items-center mr-2 pt-3.5">
                <img src="/assets/cp_icons/Farm-1.svg" alt="userIcons" className="mr-2" />
                Organization summary
              </label>
              <div className="w-full md:w-2/5 md:min-w-[520px] md:max-w-[520px]">
                <textarea
                  key={'123'}
                  rows={4}
                  {...register('userSummary', {})}
                  disabled={saved}
                  placeholder="Add an 'About' for this organization..."
                  className={`w-full my-3 md:my-0 border !border-[#D3DAEE] placeholder:text-nr h-auto placeholder:text-[#122B4680] rounded-xl focus:ring-[#D3DAEE] p-3.5 md:min-h-[200px] lg:text-nr !ring-0 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                />
                {errors?.userSummary?.message && (
                  <MessageHelperComp isError={true} message={errors.userSummary.message} />
                )}
              </div>
            </div>

            <div className="flex flex-wrap items-center">
              <label className="w-full md:w-1/4 flex items-center md:mr-2 my-3 md:my-0">
                <img src="/assets/og_icons/Location-1.svg" className="w-6 h-6 mr-2" alt="address" />
                Address
              </label>
              <div className="w-full md:w-2/4 md:min-w-[520px] md:max-w-[520px] mt-3 md:mt-0">
                <GlobalInputComponent
                  type="location"
                  control={control}
                  disabled={saved}
                  placeholder="Business address"
                  onChange={handleLocation}
                  value={watch('userAddress')}
                  className={`bg-[#0000] rounded-xl !py-[7px] border pl-0 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  inputName="userAddress"
                  register={register}
                />

                {errors?.userAddress?.message && (
                  <MessageHelperComp isError={true} message={errors.userAddress.message} />
                )}
              </div>
            </div>

            <div className="flex flex-wrap items-center ">
              <label className="w-full md:w-1/4 flex items-center md:mr-2 my-3 md:my-0">
                <img src="/assets/og_icons/Globe-1.svg" className="w-6 h-6 mr-2" alt="country" />
                Country
              </label>

              <Controller
                control={control}
                name="userNationality"
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <Select
                      onChange={onChange}
                      onBlur={onBlur}
                      value={CountryList.find((c) => c.value === ((value as any)?.value ?? value))}
                      options={CountryList}
                      isDisabled={saved}
                      placeholder="Select your nationality..."
                      className={` border border-solid w-[520px] selectWithSearch rounded-xl focus:ring-[#D3DAEE] focus:border-[#D3DAEE] h-[51px] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                      styles={customStyles as any}
                      isClearable={false}
                      isMulti={false}
                      isSearchable={true}
                    />
                  )
                }}
              />
              {(errors as any)?.userNationality?.message &&
                typeof (errors as any)?.userNationality?.message === 'string' && (
                  <MessageHelperComp
                    isError={true}
                    message={(errors.userNationality as any).message}
                  />
                )}
            </div>

            <div className="flex flex-wrap items-center">
              <label className="w-full md:w-1/4 flex md:items-center md:mr-2 my-3 md:my-0">
                <img src="/assets/cp_icons/Phone.svg" className="w-7 h-7 mr-2" alt="phone" />
                Phone number
              </label>
              <div className="w-full md:w-2/4  md:min-w-[520px] md:max-w-[520px]">
                <PhoneInput
                  ref={phoneInputRef}
                  name="userPhoneNumber"
                  saved={saved}
                  onChange={phoneOnChange}
                  countryCode={watch('userPhoneCode') ?? ''}
                  phoneNumber={watch('userPhoneNumber') ?? ''}
                />

                {errors?.userPhoneNumber?.message && (
                  <MessageHelperComp isError={true} message={errors?.userPhoneNumber?.message} />
                )}
              </div>
            </div>

            <hr className="my-4"></hr>

            {/* Zone Details */}

            <div className="flex flex-wrap items-center ">
              <label className="w-full md:w-1/4 flex items-center md:mr-2">
                {' '}
                <img className="mr-2" src="/assets/og_icons/YearofHorse-1.svg" alt="horse" />
                Discipline
              </label>

              <Controller
                control={control}
                name="userDiscipline"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    onChange={onChange}
                    value={disciplineData.find((c) => c.value === ((value as any)?.value ?? value))}
                    options={disciplineData}
                    onBlur={onBlur}
                    isDisabled={saved}
                    placeholder="Select your discipline"
                    className={`my-3 md:my-0 border border-solid w-[520px] selectWithSearch rounded-xl h-[51px] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                    styles={customStyles as any}
                    isClearable={false}
                    isMulti={false}
                    isSearchable={true}
                  />
                )}
              />
              {(errors as any)?.userDiscipline?.message &&
                typeof (errors as any)?.userNationality?.message === 'string' && (
                  <MessageHelperComp
                    isError={true}
                    message={(errors.userDiscipline as any).message}
                  />
                )}
            </div>

            <div className="flex flex-wrap items-center">
              <label className="w-full md:w-1/4 flex items-center md:mr-2 my-3 md:my-0">
                <img className="mr-2" src="/assets/og_icons/Mind Map-1.svg" alt="mind_map" />
                Zone
              </label>
              <Controller
                control={control}
                name="userZone"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    onChange={onChange}
                    value={zoneList.find((c) => c.value === ((value as any)?.value ?? value))}
                    options={zoneList}
                    onBlur={onBlur}
                    ref={ref}
                    isDisabled={saved}
                    placeholder="Select your zone"
                    className={`border-[#D3DAEE] border border-solid w-[520px] selectWithSearch rounded-xl focus:ring-[#D3DAEE] focus:border-[#D3DAEE] h-[51px] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                    styles={customStyles as any}
                    isClearable={false}
                    isMulti={false}
                    isSearchable={true}
                  />
                )}
              />
              {(errors as any)?.userZone?.message &&
                typeof (errors as any)?.userNationality?.message === 'string' && (
                  <MessageHelperComp isError={true} message={(errors.userZone as any).message} />
                )}
            </div>

            <hr className="my-4"></hr>

            <OrganizerNumberItem
              icon="/assets/cp_icons/Parliament-1.svg"
              label="USEF number"
              filedKey="userUSEF"
              saved={saved}
              register={register}
              control={control}
            />

            <OrganizerNumberItem
              icon="/assets/cp_icons/Parliament-1.svg"
              label="FEI number"
              filedKey="userFEI"
              saved={saved}
              register={register}
              control={control}
            />

            <OrganizerNumberItem
              label="USDF number"
              filedKey="userUSDF"
              saved={saved}
              register={register}
              control={control}
            />

            <OrganizerNumberItem
              label="USEA number"
              filedKey="userUSEA"
              saved={saved}
              register={register}
              control={control}
            />

            <OrganizerNumberItem
              label="USHJA number"
              filedKey="userUSHJA"
              saved={saved}
              register={register}
              control={control}
            />

            <OrganizerNumberItem
              label="AHHS number"
              filedKey="userAHHS"
              saved={saved}
              register={register}
              control={control}
            />

            <OrganizerNumberItem
              label="AMHA number"
              filedKey="userAMHA"
              saved={saved}
              register={register}
              control={control}
            />

            <OrganizerNumberItem
              label="ARHPA number"
              filedKey="userARHPA"
              saved={saved}
              register={register}
              control={control}
            />

            <OrganizerNumberItem
              label="ASHA number"
              filedKey="userASHA"
              saved={saved}
              register={register}
              control={control}
            />

            <OrganizerNumberItem
              label="UPHA number"
              filedKey="userUPHA"
              saved={saved}
              register={register}
              control={control}
            />

            <OrganizerNumberItem
              label="WDAA number"
              filedKey="userWDAA"
              saved={saved}
              register={register}
              control={control}
            />

            {fields.map((field, index) => (
              <OrganizerNumberItem
                key={field.id}
                filedKey={`userExtraDocument.${index}`}
                saved={saved}
                register={register}
                control={control}
                onRemove={() => remove(index)}
              />
            ))}

            {!saved && (
              <button
                type="button"
                className="flex items-center justify-start mt-4 cursor-pointer hover:opacity-70 transition-all"
                onClick={() => {
                  append({
                    membershipStatus: '',
                    startDate: null,
                    endDate: null,
                    validTill: null,
                    documentFullName: '',
                    documentNumber: '',
                    image: '',
                  })
                }}
              >
                <img src="/assets/cp_icons/Cancel-2.svg" className="w-4 rotate-45" alt="" />
                <p className="ml-2 text-sm text-SeabiscuitMainThemeColor">Add number</p>
              </button>
            )}

            <hr className="my-4"></hr>

            {/* Website */}
            <div className="flex flex-wrap items-center gap-3 md:gap-0 mb-3 md:mb-0">
              <label className="w-full md:w-1/4 flex items-center md:mr-2">
                <img src="/assets/cp_icons/Globe-1.svg" alt="userIcons" className="mr-2" />
                Website
              </label>
              <div className="w-full md:w-2/4 md:min-w-[520px] md:max-w-[520px] mt-3 md:mt-0">
                <input
                  type="text"
                  {...register('userWebsite')}
                  disabled={saved}
                  placeholder="https://www...."
                  className={`w-full border !border-[#D3DAEE] placeholder:text-SeabiscuitDark200ThemeColor rounded-xl !ring-0 p-2.5 mr-2 h-[51px] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}}`}
                />
                {errors?.userWebsite?.message && (
                  <MessageHelperComp isError={true} message={errors.userWebsite.message} />
                )}
              </div>
            </div>

            {/* Facebook */}
            <div className="flex flex-wrap items-center gap-3 md:gap-0 mb-3 md:mb-0">
              <label className="w-full md:w-1/4 flex items-center md:mr-2">
                <img src="/assets/img/Facebook.png" alt="userIcons" className="mr-2 max-w-[24px]" />
                Facebook
              </label>
              <div className="w-full md:w-2/4  md:min-w-[520px] md:max-w-[520px]">
                <input
                  type="url"
                  {...register('userFacebook')}
                  disabled={saved}
                  placeholder="https://www.facebook.com/..."
                  className={`w-full border !border-[#D3DAEE] rounded-xl placeholder:text-SeabiscuitDark200ThemeColor !ring-0 p-2.5 mr-2 h-[51px] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}}`}
                />
                {errors?.userFacebook?.message && (
                  <MessageHelperComp isError={true} message={errors.userFacebook.message} />
                )}
              </div>
            </div>

            {/* Instagram */}
            <div className="flex flex-wrap items-center gap-3 md:gap-0 mb-3 md:mb-0">
              <label className="w-full md:w-1/4 flex items-center md:mr-2">
                <img
                  src="/assets/img/Instagram.png"
                  alt="userIcons"
                  className="mr-2 max-w-[24px]"
                />
                Instagram
              </label>
              <div className="w-full md:w-2/4  md:min-w-[520px] md:max-w-[520px]">
                <input
                  type="text"
                  {...register('userInstagram')}
                  disabled={saved}
                  placeholder="https://www.instagram.com/..."
                  className={`w-full border !border-[#D3DAEE] rounded-xl placeholder:text-SeabiscuitDark200ThemeColor !ring-0 p-2.5 mr-2 h-[51px] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}}`}
                />
                {errors?.userInstagram?.message && (
                  <MessageHelperComp isError={true} message={errors.userInstagram.message} />
                )}
              </div>
            </div>

            {/* Twitter */}
            <div className="flex flex-wrap items-center gap-3 md:gap-0 mb-3 md:mb-0">
              <label className="w-full md:w-1/4 flex items-center md:mr-2">
                <img src="/assets/img/x.svg" alt="userIcons" className="mr-2 max-w-[24px]" />
                Twitter
              </label>
              <div className="w-full md:w-2/4 md:min-w-[520px] md:max-w-[520px]">
                <input
                  type="text"
                  {...register('userTwitter')}
                  disabled={saved}
                  placeholder="https://www.twitter.com/..."
                  className={`w-full border !border-[#D3DAEE] rounded-xl placeholder:text-SeabiscuitDark200ThemeColor !ring-0 p-2.5 mr-2 h-[51px] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}}`}
                />
                {errors?.userTwitter?.message && (
                  <MessageHelperComp isError={true} message={errors.userTwitter.message} />
                )}
              </div>
            </div>

            {/* LinkedIn */}
            <div className="flex flex-wrap items-center gap-3 md:gap-0 mb-3 md:mb-0">
              <label className="w-full md:w-1/4 flex items-center md:mr-2">
                <img
                  src="/assets/og_icons/LinkedIn-1.svg"
                  alt="userIcons"
                  className="mr-2 max-w-[24px]"
                />
                LinkedIn
              </label>
              <div className="w-full md:w-2/40  md:min-w-[520px] md:max-w-[520px]">
                <input
                  type="text"
                  {...register('userLinkedin')}
                  disabled={saved}
                  placeholder="https://www.linkedin.com/..."
                  className={`w-full border !border-[#D3DAEE] rounded-xl placeholder:text-SeabiscuitDark200ThemeColor !ring-0 p-2.5 mr-2 h-[51px] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}}`}
                />
                {errors?.userLinkedin?.message && (
                  <MessageHelperComp isError={true} message={errors.userLinkedin.message} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrganizerProfileRootPageProfileTab
